.post {
	&.post-hit {
		.post-title {
			font-size: $post-title_font-size;
			font-weight: $post-title_font-weight;
			@include media("screen", ">=phone") {
				display: inline;
			}
		}

		.post-body {
			padding-bottom: 0 !important;
		}

		.post-link {
			@if $theme-kiehl {
				&:visited {
					.post-body {
						.post-title {
							color: #628278;
						}
					}
				}
			} @else {
				&:visited {
					.post-body {
						.post-title {
							color: #627b8b;
						}
					}
				}
			}
			&:hover,
			&:focus {
				.post-body {
					color: $black;

					.post-title {
						color: $post-title_color_hover;
					}
				}
			}
		}

		.post-doi {
			font-size: rem-calc(15);
			font-weight: 300;
			margin-top: 0.25rem;
			margin-bottom: 1rem;
		}
	}

	&-type {
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 0.5rem;

		&--badge {
			background-color: $post-hit_type_bg;
			border-radius: $post-hit_type_border-radius;
			border: $post-hit_type_border;
			color: $post-hit_type_color;
			font-size: $post-hit_type_font-size;
			font-weight: $post-hit_type_font-weight;
			line-height: 1;
			margin-right: 0.25rem;
			padding: 0.175rem 0.5rem 0.125rem;
			text-transform: $post-hit_type_text-transform;
		}

		& + .post-toolbar {
			margin-top: -2rem;
			margin-bottom: 1rem;

			& + .post-title {
				@include media("screen", "<phone") {
					clear: both;
				}
			}
		}
	}

	&-subtitle {
		color: $gray-700;
		font-weight: 600;
		@include media("screen", "<phone") {
			font-size: rem-calc(14);
		}
	}

	&-description {
		font-style: italic;
		margin-top: rem-calc(10);
		margin-bottom: rem-calc(10);
	}

	&-rel {
		color: $gray-600;
		font-size: rem-calc(12);
	}

	&-toolbar {
		z-index: 5;
		float: right;
		margin-left: 1rem;
	}

	&-tools {
		line-height: 1;
		margin: 0 rem-calc(-2);
		position: relative;
		z-index: 10;

		&--link {
			color: $post-hit-link_color;
			font-size: rem-calc(10);
			font-weight: 600;
			margin: rem-calc(2);
			text-transform: uppercase;
			@include media("screen", ">=phone") {
				font-size: rem-calc(11);
			}
			&:not(:last-child) {
				&::after {
					content: "|";
					padding-left: rem-calc(4);
				}
			}
			@media (hover: hover) {
				&:hover,
				&:focus {
					text-decoration: underline;
				}
			}
		}
	}
}

// .post-hit-dms {
// 	color: $post-hit-dms_color;
// 	font-size: $post-hit-dms_font-size;
// 	letter-spacing: rem-calc(0.25);
// 	margin: {
// 		top: 1rem;
// 		left: 1rem;
// 	}
// 	text-transform: $post-hit-dms_text-transform;
// }

// .post-hit-dms {
// 	background-color: $post-hit-dms_color;
// 	border-radius: $border-radius;
// 	color: $white;
// 	display: inline-block;
// 	font-size: $post-hit-dms_font-size;
// 	letter-spacing: rem-calc(0.25);
// 	margin: {
// 		top: 1rem;
// 		left: 1rem;
// 	}
// 	padding: 0.125rem 0.5rem;
// 	text-transform: $post-hit-dms_text-transform;
// }
