a,
.btn:not(.btn-search) {
	transition: $transition-base;
}

// [class*="btn-"]:not(.btn-toggle):not(.sidebar-btn--title):not([class*="-outline-"]) {
// 	text-transform: $btn-text-transform;
// }

button {
	&.close:focus {
		outline: 0;
	}
}

.tab-pane--list-item {
	position: relative;
	transition: $transition-base;
	&::after {
		content: "";
		// border-bottom: rem-calc(1) solid $text-link-hover;
		border-bottom: rem-calc(1) solid $link-hover-color;
		left: 0;
		position: absolute;
		transition: $transition-base;
		width: 0;
	}
	&:hover,
	&:focus {
		&::after {
			width: 100%;
		}
	}
}

.btn {
	// box-shadow: $btn-box-shadow;
	white-space: nowrap;
	&:focus {
		// box-shadow: none;
		outline: none;
	}

	&[class*="-outline-"] {
		box-shadow: none;
	}

	&-outline-primary {
		background-color: $blue-lightest;
		&:focus,
		&:hover {
			background-color: $primary;
			color: $white;
		}
	}

	&-outline-secondary {
		background-color: $blue-lightest;
		color: $blue;
		&:focus,
		&:hover {
			background-color: $blue-lighter;
			// color: $white;
		}
	}

	&-primary {
		box-shadow: $btn-box-shadow;
		text-transform: $btn-text-transform;
		// --bs-btn-color: var(--bs-white);
		// --bs-btn-hover-color: var(--bs-white);
		// --bs-btn-active-color: var(--bs-white);
		--bs-btn-hover-bg: #{shade-color($primary, 20%)};
		--bs-btn-hover-border-color: transparent;
		// --bs-btn-active-bg: #{shade-color($primary, 20%)};
		--bs-btn-active-border-color: transparent;
		// &:focus,
		&:hover {
			background-color: $blue-lightest;
			color: $primary;
		}
		&:focus {
			box-shadow: none;
		}
	}
}

.text-primary {
	&:hover,
	&:focus {
		color: #001d3d !important;
	}
}

// Button PWA
$btn-app_size: 3.375rem !default;

.btn-app {
	background-color: $gray-100;
	border: 0.0625rem solid $gray-400;
	border-radius: 50%;
	box-shadow: $box-shadow;
	bottom: 0;
	color: $primary;
	display: flex;
	align-items: center;
	justify-content: center;
	height: $btn-app_size;
	position: absolute;
	width: $btn-app_size;

	&-group {
		display: flex;
		bottom: 1rem;
		left: auto;
		right: auto;
		position: fixed;
		z-index: 10;
		width: calc(100% - 1.875rem);
		@include media("screen", ">=desktop") {
			width: 930px;
		}
		@include media("screen", ">=widescreen") {
			width: 1110px;
		}
		@include media("screen", ">=1330px") {
			width: 1198px;
		}
	}

	// &.btn-back {
	// 	margin-left: -0.5rem;
	// 	@include media("screen", ">=desktop") {
	// 		margin-left: -2rem;
	// 	}
	// 	@include media("screen", ">=1366px") {
	// 		margin-left: -4.5rem;
	// 	}
	// 	@include media("screen", ">=1440px") {
	// 		margin-left: -6rem;
	// 	}
	// }

	&.btn-totop {
		background-color: $btn-toTop_bg;
		border-color: $btn-toTop_border-color;
		color: $btn-toTop_color;
		margin-left: auto;
		margin-right: -0.5rem;
		right: 0;
		@include media("screen", ">=desktop") {
			margin-right: -2rem;
		}
		@include media("screen", ">=1366px") {
			margin-right: -4.5rem;
		}
		@include media("screen", ">=1440px") {
			margin-right: -6rem;
		}
	}
}
