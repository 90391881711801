$teaser-bg: $blue-lightest !default;
// $teaser-bg: $gray-100 !default;
$teaser-border: 0.0625rem solid $blue-lightest !default;
$teaser-border-radius: 0.5rem !default;

$teaser-icon_color: $secondary !default;
$teaser-icon_size: null !default;

$teaser-title_color: $primary !default;
$teaser-title_font-size: 1.375rem !default;
$teaser-title_text-transform: uppercase !default;

$teaser_padding: 3rem !default;
$teaser_min-width: 20rem !default;
