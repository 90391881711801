.close {
	// &:[data-bs-dismiss="modal"] {
	// }
	background: $btn-close-bg;
	border: 0;
	font-size: $btn-close-height;
	// height: $btn-close-height;
	line-height: 1;
	opacity: $btn-close-opacity;
	padding: $btn-close-padding-y $btn-close-padding-x;
	// width: $btn-close-width;
	&:hover {
		opacity: $btn-close-hover-opacity;
	}
	&:focus {
		opacity: $btn-close-focus-opacity;
	}

	&:not([data-bs-dismiss="alert"]) {
		margin: -1rem -1rem -1rem auto;
	}
}

.alert-dismissible .close {
	position: absolute;
	top: 0;
	right: 0;
	padding: 0.75rem 1.25rem;
	color: inherit;
}
