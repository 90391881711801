.text-link {
	// color: $text-link;
	@if $theme-nwb {
		color: $cyan-600;
	} @else {
		color: $link-color;
	}
	font-size: rem-calc(14);
	font-weight: 600;
	position: relative;
	text-transform: uppercase;
	transition: $transition-base;
	&:hover,
	&:focus {
		// color: $text-link-hover;
		color: $link-hover-color;
		[class*="icon-"] {
			margin-left: rem-calc(5);
		}
	}

	[class*="icon-"] {
		font-size: rem-calc(12);
		transition: margin-left 0.3s ease-in-out;
	}
}

.link-back,
.link {
	// color: $text-link;
	color: $link-color;
	&:hover,
	&:focus {
		// color: $text-link-hover;
		color: $link-hover-color;
	}
}

.link-back {
	font-weight: 600;
	margin-bottom: 0.625rem !important;
	padding-left: 0 !important;
}
