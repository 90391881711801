.teaser-scout {
	display: block;
	height: 100%;
	overflow: hidden;
	&:hover,
	&:focus {
		opacity: 0.85;
	}

	&-header {
		height: 11rem;
		margin-bottom: rem-calc(20);
		padding: rem-calc(30);
		position: relative;
		text-align: center;
		width: 100%;
		@include media("screen", ">=widescreen") {
			height: 14rem;
		}

		&[class*="scout-bg-"] {
			&::after {
				content: "";
				border-top-style: solid;
				border-top-width: rem-calc(20);
				border-right: 100vw solid transparent;
				height: 0;
				left: 0;
				position: absolute;
				top: 100%;
				width: 0;
				@include media("screen", ">=tablet") {
					border-right-width: 50vw;
				}
				@include media("screen", ">=desktop") {
					border-right-width: 30vw;
				}
			}
		}

		& > img {
			height: rem-calc(48);
			width: auto;
			@include media("screen", ">=widescreen") {
				height: rem-calc(54);
			}
		}
	}

	&-content {
		padding: rem-calc(20);

		&--title {
			border-top: 0.0625rem solid rgba($white, 0.5);
			color: $white;
			font-size: rem-calc(20);
			padding-top: 1rem;
			@include media("screen", ">=widescreen") {
				margin-top: 1rem;
				padding-top: 2rem;
			}
		}

		// p {
		// 	color: $gray-700;
		// }
	}
}

.scout-bg {
	&-est {
		background-color: $est;
		&::after {
			border-top-color: $est;
		}
	}

	&-lug {
		background-color: $lug;
		&::after {
			border-top-color: $lug;
		}
	}

	&-ust {
		background-color: $ust;
		&::after {
			border-top-color: $ust;
		}
	}

	&-fibu {
		background-color: $fibu;
		&::after {
			border-top-color: $fibu;
		}
	}

	&-gewst {
		background-color: $gewst;
		&::after {
			border-top-color: $gewst;
		}
	}

	&-kst {
		background-color: $kst;
		&::after {
			border-top-color: $kst;
		}
	}
}
