.qa {
	margin-bottom: 1rem;

	&-form {
		display: inline-block;
	}

	&-select {
		// appearance: none;
		background-color: transparent;
		border: {
			left: 0;
			right: 0;
			top: 0;
			bottom: rem-calc(1) dashed $black;
		}
		cursor: pointer;
		margin-right: 0.5rem;
		outline: none;
		padding: {
			left: 0.25rem;
			right: 0.25rem;
		}
		width: fit-content;

		option {
			text-align: left;
		}
	}

	&-row-highlight {
		font-weight: 600;
	}
}

.qa {
	&-bg {
		background-color: $white;
	}

	&-bg-secondary {
		background-color: $secondary;

		th {
			box-shadow: none;
			@if $theme-sis {
				color: $white;
			}
		}
	}

	&-form-control {
		@extend .form-control;
	}

	&-inline-block {
		display: inline-block;
	}

	&-ml-2 {
		margin-left: 0.5rem;
	}

	&-mt-2 {
		margin-top: 0.5rem;
	}

	&-mt-3 {
		margin-top: 1rem;
	}

	&-mx {
		// margin: {
		// 	left: 0.5rem;
		// 	right: 0.5rem;
		// }
		@extend .mx-2;
	}

	&-my {
		margin: {
			top: 1.5rem;
			bottom: 1.5rem;
		}
	}

	&-my-1 {
		margin: {
			top: 0.25rem;
			bottom: 0.25rem;
		}
	}

	&-p-2 {
		padding: 0.5rem;
	}

	&-p-3 {
		padding: 1rem;
	}

	&-rounded {
		border-radius: 0.25rem;
	}

	&-table {
		@extend .table;
		width: 100%;

		tr:not(:last-child) {
			border-bottom: 0.0625rem solid $gray-100;
		}
	}

	&-table-responsive {
		// @extend .table-responsive;
		@extend .overflow-auto;
	}

	&-table-striped {
		tbody {
			tr:nth-of-type(2n) {
				background-color: $table-accent-bg;
			}
		}
		// @extend .table-striped;
	}

	&-text-nowrap {
		white-space: nowrap;
	}

	&-text-center {
		text-align: center;
	}

	&-text-left {
		text-align: left;
	}

	&-text-right {
		text-align: right;
	}

	&-text-white {
		@if $theme-linde {
			color: $black;
		} @else {
			color: $white;
		}
	}

	&-calendar {
		cursor: default;

		tbody {
			tr:not(:last-child) {
				border-bottom: 0.0625rem solid $gray-100;
			}
		}

		th {
			width: rem-calc(80);
		}

		td {
			// background-color: $white;
			// box-shadow: none; // BT 5 fix
			line-height: rem-calc(50);
			padding: {
				left: 0.25rem !important;
				right: 0.25rem !important;
			}
		}

		.circle {
			border-radius: 50%;
			display: block;
			font-weight: 600;
			height: rem-calc(50);
			margin: auto;
			width: rem-calc(50);
		}

		.due-date {
			@if $theme-linde {
				background-color: $blue-lighter;
				color: $black;
			} @else if $theme-sis {
				background-color: $cyan;
				color: $white;
			} @else {
				background-color: rgba($cyan, 0.2);
				color: $black;
			}
		}

		.grace-period {
			background-color: $brand-color;
			@if $theme-sis {
				color: $white;
			}
		}

		.weekend {
			color: $gray-500;
			font-weight: 300;
		}

		.commonday {
			position: relative;
			transform: rotate(45deg);
			z-index: 1;
			&::before,
			&::after {
				position: absolute;
				height: 100%;
				width: 50%;
				z-index: -1;
			}
			&::before {
				content: "";
				border-radius: rem-calc(25) 0 0 rem-calc(25);
				left: 0;
				@if $theme-linde {
					background-color: $blue-lighter;
				} @else if $theme-sis {
					background-color: $cyan;
				} @else {
					background-color: rgba($cyan, 0.2);
				}
			}
			&::after {
				content: "";
				background-color: $brand-color;
				border-radius: 0 rem-calc(25) rem-calc(25) 0;
				right: 0;
			}

			&-num {
				display: inline-block;
				transform: rotate(-45deg);
				@if $theme-sis {
					color: $white;
				}
			}
		}
	}

	&-legend {
		display: flex;
		flex-wrap: wrap;
		font-size: 0.75rem;

		&-due-date {
			@if $theme-linde {
				background-color: $blue-lighter;
			} @else if $theme-sis {
				background-color: $cyan;
			} @else {
				background-color: rgba($cyan, 0.2);
			}
		}

		&-grace-period {
			background-color: $brand-color;
		}

		&-commonday {
			@if $theme-linde {
				background-color: $blue-lighter;
			} @else if $theme-sis {
				background-color: $cyan;
			} @else {
				background-color: rgba($cyan, 0.2);
			}
			position: relative;
			transform: rotate(45deg);
			white-space: nowrap;
			z-index: 1;
			&::after {
				content: "";
				background-color: $brand-color;
				border-radius: 0 rem-calc(25) rem-calc(25) 0;
				position: absolute;
				right: 0;
				height: 100%;
				width: 50%;
				z-index: -1;
			}
		}

		dd {
			display: inline-block;
			margin: 0 2rem 0.5rem 0.5rem;
			padding-bottom: 0;
			vertical-align: middle;
		}

		dt {
			@if not($theme-sis) {
				border: 0.0625rem solid $gray-400;
			}
			border-radius: 50%;
			display: inline-block;
			width: rem-calc(18);
			height: rem-calc(18);
			vertical-align: middle;
		}
	}
}
