.block {
	border-top: 0.0625rem solid $gray-400;
	border-bottom: 0.0625rem solid $gray-400;
	margin-top: rem-calc(-1);
	padding: rem-calc(20) 0;
}

.info-block {
	// margin-left: auto;
	// margin-right: auto;
	@include media("screen", ">=desktop") {
		width: 85%;
	}
}
