.badge-hit {
	background-color: $badge-hit_bg;
	border-radius: $badge-hit_border-radius;
	color: $badge-hit_color;
	display: inline-block;
	font-size: $badge-hit_font-size;
	font-weight: $badge-hit_font-weight;
	hyphens: auto;
	line-height: rem-calc(24);
	padding: 0 rem-calc(10);
	margin-right: rem-calc(5);
	margin-bottom: rem-calc(10);
	white-space: nowrap;
	@if $theme-sis {
		&[class*="search-bg"] {
			color: $primary !important;
		}
	}

	a {
		color: $badge-hit_link-color !important;
		white-space: nowrap;
		&:hover,
		&:focus {
			color: $badge-hit_link-color-hover !important;
		}
	}

	& > i {
		margin-left: 0.5rem;
	}
}

.badge-filter {
	font-size: $badge-filter_font-size;
	font-weight: 400;

	a {
		color: $badge-filter_color;
	}

	&--icon {
		background-color: rgba($badge-filter-icon_bg, 0.75);
		border-radius: 50%;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		transition: background-color 0.3s ease-in-out;
		height: rem-calc(16);
		width: rem-calc(16);
	}
}

.badge {
	&.badge-filter {
		background-color: $badge-filter_bg;
		color: $badge-filter_color;
		&:hover,
		&:focus {
			color: $badge-filter_color;
			.badge-filter--icon {
				background-color: $badge-filter-icon_bg;
			}
		}
	}
}

.hits-reset {
	@if not($theme-sis) {
		color: $body-color;
	}
}

.hits-toolbar {
	padding: {
		top: 1rem;
	}
}

.highlight {
	padding-left: 0.375rem;
	padding-right: 0.375rem;
}

.search-bg {
	&0 {
		background-color: rgba($yellow, 0.5) !important;
	}
	&1 {
		background-color: rgba($cyan, 0.2) !important;
	}
	&2 {
		background-color: rgba($purple, 0.2) !important;
	}
	&3 {
		background-color: rgba($blue, 0.2) !important;
	}
	&4 {
		background-color: rgba($pink, 0.2) !important;
	}
	&5 {
		background-color: rgba($orange, 0.4) !important;
	}
	&6 {
		background-color: rgba($green, 0.15) !important;
	}
	&7 {
		background-color: rgba($teal, 0.3) !important;
	}
	&8 {
		background-color: rgba($red, 0.15) !important;
	}
	&9 {
		background-color: rgba($indigo, 0.2) !important;
	}

	&Markiert {
		padding-left: 0.25rem;
		padding-right: 0.25rem;
		border: 0.125rem dashed $black;
	}
}

.filter {
	&.hits-toolbar {
		background-color: $gray-100;
		margin-top: 1rem;
		padding: 1rem 1rem 0.5rem;
	}
}
