.teaser-category {
	color: $teaser-category_color;
	display: flex;
	align-items: center;
	&:hover,
	&:focus {
		color: $teaser-category_color-hover;

		.icon {
			background-color: $teaser-category-icon_bg-hover;
		}
	}
}

[class*="node-"] {
	[class*="icon"] {
		background-color: $teaser-category-icon_bg;
		border-radius: 50%;
		color: $white;
		display: inherit;
		align-items: center;
		justify-content: center;
		font-family: "iconfont";
		font-size: rem-calc(24);
		font-style: normal;
		font-weight: 400;
		font-feature-settings: normal;
		font-variant: normal;
		text-transform: none;
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
		height: rem-calc(48);
		line-height: 1;
		margin: rem-calc(5) rem-calc(15) rem-calc(5) 0;
		transition: $transition-base;
		min-width: rem-calc(48);
		width: rem-calc(48);
	}
}

.node {
	// Arbeitshilfen ID
	&-bctga0dvtqeb2u3ir98x {
		.icon {
			@extend .icon-tools;
		}
	}
	&-5g7efsslh6qaa9zbz2xa {
		.icon {
			@extend .icon-calculator;
		}
	}
	&-iq3g8ggf4urqou98cotv {
		.icon {
			@extend .icon-contract;
		}
	}
	&-cjvny32jggz3a1m1le6t {
		.icon {
			@extend .icon-checklist;
		}
	}
	&-u5ltg8doq4bk618hyql0 {
		.icon {
			@extend .icon-people;
		}
	}
	&-i23g8ggf5urfou18cgtv {
		.icon {
			@extend .icon-appeal;
		}
	}
	&-gfyr6eppjnrdd9s73qi6 {
		.icon {
			@extend .icon-form;
		}
	}
	&-o1hlrk7yq38jcmagj52d {
		.icon {
			@extend .icon-table;
		}
	}
	&-p4hlm47yqn8jbmsmgekq {
		.icon {
			@extend .icon-presentation;
		}
	}
	&-ju7ucgp6364atxld8oe1 {
		.icon {
			@extend .icon-database-help;
		}
	}
	&-wm87abqpibhw307fypv3 {
		.icon {
			@extend .icon-seminar-alt;
		}
	}
	&-sd8ec6rcnlfawndc2hlm {
		.icon {
			@extend .icon-balance-scale;
		}
	}
	&-svcxro2i4cr8sod0vyna {
		.icon {
			@extend .icon-compass;
		}
	}

	// Schaubilder
	&-e7pql71pqn5oigwdnwbv {
		.icon {
			@extend .icon-image;
		}
	}
	&-dnwwf1q673lcw6bhbhyi {
		.icon {
			@extend .icon-online-training;
		}
	}
	&-ys1ucf6o6wdranvbev33 {
		.icon {
			@extend .icon-compass;
		}
	}

	// Seminare
	&-G7Y4YUZUARWHZGUF2GWU {
		.icon {
			@extend .icon-paragraph-normal;
		}
	}
	&-SA4HQA7FQZVZQYZV3AVC {
		.icon {
			@extend .icon-people;
		}
	}
	&-GR2TQ5LYPFWYVYGFESSX {
		.icon {
			@extend .icon-checklist;
		}
	}
	&-UVEK443CS6LZVUPAGL4T {
		.icon {
			// @extend .icon-balance-scale;
			@extend .icon-calculator;
		}
	}
	&-YK3GE4FYSVSPU5SVLTXT {
		.icon {
			@extend .icon-at-symbol;
		}
	}
	&-YDU4S7GXCZYXWLRRAQPH {
		.icon {
			@extend .icon-laptop;
		}
	}
	&-WKV3VUYPD76CEGDUE66Q {
		.icon {
			@extend .icon-family;
		}
	}
	&-KSZZF5E6P6XAWFF4FTFU {
		.icon {
			@extend .icon-laws;
		}
	}
	&-ZEPLWXZV6G3PPX37XQ63 {
		.icon {
			@extend .icon-book-comments;
		}
	}
	&-WR7VRHFKTUKPU5HVZHH3 {
		.icon {
			@extend .icon-at-symbol;
		}
	}
}
