$post-special_bg: $white !default;

.post {
	// background-color: $white;
	// position: relative;
	// transition: $transition-base;
	@include media("screen", ">=phone") {
		margin: 0 rem-calc(15);
	}

	.send-holder {
		@media (hover: hover) {
			cursor: pointer;
			opacity: 0;
		}
	}

	&-link {
		background-color: $white;
		border-top: 0 solid transparent;
		@if $theme-sis {
			border-bottom: rem-calc(1) solid $brand-color-lightest;
		} @else {
			border-bottom: rem-calc(1) solid $gray-400;
		}
		display: block;
		height: 100%;
		position: relative;
		transition: $transition-base;
		z-index: 3;
		// &:visited {
		// 	.post-body {
		// 		.post-title {
		// 			color: $red;
		// 		}
		// 	}
		// }
		&:hover,
		&:focus {
			// Don't delete !!!
			// box-shadow: $box-shadow-lg, 0 0 rem-calc(1) $white inset;
			z-index: 4;

			// & ~ .post-body {
			.post-body {
				color: $black;

				// Don't delete !!!
				// .post-img > img {
				//   transform: scale(1.1) rotate(-1deg);
				// }

				.post-title {
					color: $post-title_color_hover;
				}

				.send-holder {
					opacity: 1;
				}

				.text-link {
					color: $primary;

					[class*="icon-"] {
						margin-left: rem-calc(5);
					}
				}
			}
		}
	}

	&-img {
		// height: 9.75rem;
		margin-bottom: rem-calc(15);
		// 8775
		// overflow: hidden;
		// @include media("screen", ">=375px") {
		// 	height: 11.75rem;
		// }
		// @include media("screen", ">=480px") {
		// 	height: 14rem;
		// }
		// 8775 : END

		// 8958 Kiehl
		@if $theme-kiehl {
			// height: 9.75rem;
			max-height: 9.75rem;
			overflow: hidden;
			@include media("screen", ">=375px") {
				// height: 11.75rem;
				max-height: 14rem;
			}
			@include media("screen", ">=480px") {
				// height: 14rem;
				max-height: 18rem;
			}
		}
		// 8958 : END

		img {
			display: inherit;
			height: auto;
			max-width: 100%;
			width: 100%;
		}
	}

	&-body {
		color: $body-color;
		display: flex;
		flex-direction: column;
		height: 100%;
		// @include media("screen", "<phone") {
		padding: rem-calc(15) rem-calc(15) rem-calc(30);
		// }
		// @include media("screen", ">=phone") {
		//   padding: rem-calc(15) 0 rem-calc(30);
		// }
	}

	&-content {
		color: $body-color;
		display: flex;
		flex-direction: column;
		@if $theme-kiehl {
			height: auto;
		} @else {
			@include media("screen", ">=phone") {
				height: 100%;
				padding-bottom: 0.75rem;
			}
		}
	}

	&-head {
		color: $post-head_color;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		font-size: rem-calc(11);
		// font-weight: 600;
		margin: 0 0 rem-calc(15) 0;
		text-transform: uppercase;
	}

	// Unread status enabler
	// @if $post-unread {
	//   &.unread {
	//     .post-head {
	//       &::before {
	//         content: "";
	//         background-color: $post-head-unread_bg-color;
	//         border-radius: 50%;
	//         height: rem-calc(10);
	//         margin-right: 0.5rem;
	//         width: rem-calc(10);
	//       }
	//     }
	//   }
	// }
	// Unread status enabler : END

	&-title {
		color: $post-title_color;
		font-family: $font-family-sans-serif;
		font-weight: $post-title_font-weight;
		font-size: $post-title_font-size;
		// max-height: rem-calc(72);
		margin-bottom: rem-calc(10);
		// overflow: hidden;
		transition: color 0.3s ease-in-out;
		hyphens: auto;
		word-break: break-word;
		@if $theme-kiehl {
		} @else {
			@include media("screen", "<desktop") {
				line-height: 1.5;
			}
		}
		// @if $post-title_font-size_desktop {
		// 	@include media("screen", ">=desktop") {
		// 		font-size: $post-title_font-size_desktop;
		// 	}
		// }
	}

	&-subtitle {
		font-size: 1rem;
		transition: $transition-base;
	}

	&-text {
		//   margin-bottom: 2rem;
		transition: $transition-base;
	}

	&-links {
		// bottom: rem-calc(10);
		bottom: rem-calc(2);
		// height: rem-calc(25);
		position: absolute;
		// right: 0;
		text-align: right;
		z-index: 10;
		@include media("screen", "<phone") {
			// bottom: rem-calc(-15);
			right: rem-calc(15);
		}
		@include media("screen", ">=phone") {
			// bottom: rem-calc(10);
			right: 0;
		}
	}

	.post-links {
		@media (hover: hover) {
			&:hover {
				.send-holder {
					opacity: 1;
				}
			}
		}
	}

	&-action {
		margin-left: auto;
		text-align: right;
		transition: $transition-base;
		z-index: 10;
		&:not(.send-holder) {
			min-width: rem-calc(144);
		}

		[class*="icon-"] {
			@if $theme-linde {
				color: $gray-400;
			} @else {
				color: $gray-500;
			}
			font-size: rem-calc(20);
			transition: $transition-base;
		}

		a,
		span {
			border-radius: 50%;
			cursor: pointer;
			display: inline-block;
			&:hover,
			&:focus {
				outline: none;

				[class*="icon-"] {
					color: $primary;
				}
			}
		}

		.action {
			display: inline-block;
			line-height: rem-calc(20);
			padding: 0.5rem;
		}

		@media (hover: hover) {
			button {
				cursor: pointer;
			}
		}
	}

	&-footer {
		color: $gray-600;
		font-size: $font-size-sm;
		z-index: 5;
		@include media("screen", "<phone") {
			padding: 0 rem-calc(15) rem-calc(15) rem-calc(15);
		}
		@include media("screen", ">=phone") {
			padding-bottom: rem-calc(15);
		}
		@include media("screen", "<tablet") {
			display: flex;
			align-items: center;
		}
		@include media("screen", ">=tablet") {
			height: 100%;
			padding-top: rem-calc(15);
			text-align: right;
			min-width: rem-calc(170);
		}
	}

	&.is-lock {
		.abo {
			@if $theme-default {
				background-color: $gray-800;
			} @else if $theme-linde {
				// background-color: $secondary;
				background-color: $blue-light;
			} @else if $theme-sis {
				background-color: $brand-color-light;
			} @else {
				background-color: $secondary;
			}
			// @if $theme-linde {
			// 	color: $blue;
			// } @else {
			// }
			border-radius: rem-calc(4);
			color: $white;
			display: inline-block;
			// align-items: center;
			// justify-content: center;
			font-size: rem-calc(11);
			height: rem-calc(20);
			line-height: 1.375rem;
			width: rem-calc(32);
			margin-right: 0.375rem;
			margin-bottom: 0.125rem;
			text-align: center;
			text-transform: uppercase;
			vertical-align: text-bottom;
		}
	}

	// Post with an Image
	&.post-pic {
		.post-link {
			background-color: $teaser-bg;
			border: $teaser-border;
			overflow: hidden;
			@include media("screen", ">=phone") {
				border-radius: $teaser-border-radius;
			}
			@include media("screen", ">=tablet") {
				max-height: rem-calc(240);
			}
			@include media("screen", ">=desktop") {
				max-height: 19rem;
			}
			@include media("screen", ">=widescreen") {
				max-height: rem-calc(240);
			}
		}

		.post-body {
			@include media("screen", ">=tablet") {
				flex-direction: row;
				padding-bottom: 0.9375rem;
			}
		}

		.post-img {
			// display: block;
			margin: -0.9375rem -0.9375rem 1rem;
			@include media("screen", ">=tablet") {
				display: flex;
				justify-content: center;
				max-height: rem-calc(240);
				margin-right: 1.5rem; // 24px
				margin-bottom: -0.9375rem;
				overflow: hidden;
				width: rem-calc(240);

				img {
					height: calc(100% + 1rem);
					width: auto;
					max-width: none;
				}
			}
			@include media("screen", ">=desktop") {
				max-height: 18.5rem;
				width: calc(50% - 1.5rem);
			}
			@include media("screen", ">=widescreen") {
				max-height: rem-calc(240);
				width: rem-calc(240);
			}
		}

		.post-content {
			padding-bottom: 0;
			@include media("screen", ">=tablet") {
				width: calc(100% - 248px);
			}
			@include media("screen", ">=desktop") {
				width: 50%;
			}
			@include media("screen", ">=widescreen") {
				width: calc(100% - 248px);
			}
		}

		.post-head {
			color: $gray-900;
		}

		.post-text {
			margin-bottom: 0;
		}
	}
	// Post with an Image : END
}

// Grid view
.posts {
	&-header {
		@include media("screen", "<411px") {
			.headline + *:not(.post-view) {
				justify-content: flex-end;
				margin-top: 0;
				position: relative;

				// &:before {
				// 	content: "";
				// 	background-color: #ccc;
				// 	height: 0.0625rem;
				// 	left: 0;
				// 	position: absolute;
				// 	top: 50%;
				// 	width: calc(100% - 7rem);
				// 	z-index: -1;
				// }

				// .group {
				// 	.btn {
				// 		padding-left: 0.625rem !important;
				// 		padding-right: 0.625rem !important;
				// 	}
				// }

				// .btn-group,
				// .dropdown {
				// background-color: $white;
				// border: 0.5rem solid $white;
				// margin-right: -0.5rem;
				// }
			}
		}
		// @include media("screen", ">=411px") {
		// 	display: flex;
		// 	// align-items: center;
		// 	justify-content: space-between;
		// }
		.headline + .group {
			white-space: nowrap;
		}
		@include media("screen", "<phone") {
			.headline + .group {
				// justify-content: flex-end;
				margin-top: 0;
				// margin-right: -0.5rem;
				position: relative;
				width: 100%;
				// &:before {
				// 	content: "";
				// 	background-color: #ccc;
				// 	height: 0.0625rem;
				// 	left: 0;
				// 	position: absolute;
				// 	// top: 50%;
				// 	top: rem-calc(27);
				// 	width: calc(100% - 7rem);
				// 	z-index: -1;
				// }

				// .dropdown,
				// .btn-group {
				// 	background-color: $white;
				// 	border: 0.5rem solid $white;
				// 	border-right: 0;
				// }

				// .dropdown {
				// 	margin-left: 0.5rem;
				// }

				.btn {
					padding-left: 0.625rem;
					padding-right: 0.625rem;
				}
			}

			&.posts-wrap {
				align-items: flex-start;
				flex-wrap: wrap;
			}

			.search-notification {
				background-color: $white;
				border: 0.5rem solid $white;
				border-right: 0;
			}
		}

		@include media("screen", ">=tablet") {
			display: flex;
			justify-content: space-between;
			align-items: center;
			// white-space: nowrap;
		}
		// @include media("screen", ">=tablet", "<desktop") {
		// 	flex-wrap: wrap;
		// }

		&:not(.posts-wrap) {
			// @include media("screen", ">=phone") {
			@include media("screen", ">=tablet") {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.search-notification {
			.btn {
				display: inline-flex;
				align-items: center;
				@if $theme-linde {
					height: rem-calc(29);
				} @else {
					height: rem-calc(31);
				}
			}
		}
	}

	&.grid {
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
		width: calc(100% + 1.875rem);

		@include media("screen", "<phone") {
			&::before {
				content: "";
				background-color: $white;
				box-shadow: 0 rem-calc(10) rem-calc(12) rgba($black, 0.1);
				display: block;
				height: 1.5rem;
				margin: -0.875rem 0 0;
				width: 100%;
			}
		}
		@include media("screen", ">=phone") {
			display: flex;
			flex-flow: row wrap;
		}

		.post {
			// .post-link {
			border-bottom: 0 solid transparent;
			@include media("screen", "<phone") {
				margin: rem-calc(10) 0;
				box-shadow: 0 rem-calc(10) rem-calc(20) rgba($black, 0.1);
				width: 100%;

				.post-link {
					border-top: 0 solid transparent;
				}

				.post-body {
					padding-bottom: rem-calc(60);
				}

				&.border {
					border: 0;
				}

				&.rounded {
					border-radius: 0;
				}
			}
			@include media("screen", ">=phone") {
				// margin: -0.0625rem rem-calc(15) rem-calc(30);
				margin: 0 rem-calc(15) rem-calc(15);
				// padding-bottom: 4rem;
				width: calc(50% - 1.875rem);
				&:not(:first-child) {
					.post-link {
						border-top: rem-calc(1) solid $gray-400;
						border-bottom: 0 solid transparent;
					}
				}

				&:nth-child(2) {
					.post-link {
						border-top: 0 solid transparent;
					}

					.post-body {
						padding-top: rem-calc(15);
					}
				}

				.post-body {
					@if $enable-fader-teaser {
						padding: rem-calc(60) 0 rem-calc(30);
					} @else {
						padding: rem-calc(30) 0 rem-calc(15);
					}
				}

				&.border {
					.post-body {
						padding-top: 0 !important;
					}
				}
			}
			@include media("screen", ">=tablet") {
				width: calc(33.333% - 1.875rem);
				&:nth-child(3) {
					.post-link {
						border-top: 0 solid transparent;
					}

					.post-body {
						padding-top: rem-calc(15);
					}
				}
			}
			@include media("screen", ">=desktop", "<widescreen") {
				@if $theme-kiehl {
					&:nth-child(3) {
						.post-link {
							border-top: 0.0625rem solid $gray-400;
						}
					}
				}
			}

			// @if $theme-nwb {
			// 	// 9 & 10th items
			// 	@include media("screen", ">=phone", "<tablet") {

			// 		&:nth-child(8n + 1),
			// 		&:nth-child(8n + 2) {
			// 			.post-link {
			// 				border-top-width: rem-calc(6);
			// 				border-top-style: dotted;
			// 			}
			// 		}
			// 	}
			// 	// 9 & 10th items : END

			// 	// 10, 11, 12th items
			// 	@include media("screen", ">=tablet") {
			// 		&:nth-child(9n + 1),
			// 		&:nth-child(9n + 2),
			// 		&:nth-child(9n + 3) {
			// 			.post-link {
			// 				border-top-width: rem-calc(6);
			// 				border-top-style: dotted;
			// 			}
			// 		}
			// 	}
			// 	// 10, 11, 12th items : END
			// }

			&:first-child {
				.post-body {
					padding-top: rem-calc(15);
				}
			}

			&.border {
				.post-link {
					border-top: 0 solid transparent !important;
				}

				.post-body {
					padding-bottom: 0;
					&::after {
						content: none;
					}
				}
			}

			.post-link {
				border-bottom: 0 solid transparent;
			}

			@if $enable-fader-teaser {
				&-body {
					// max-height: rem-calc(436);
					max-height: rem-calc(472);
					overflow: hidden;
					@include media("screen", ">=420px") {
						max-height: rem-calc(480);
					}
					@include media("screen", ">=480px") {
						// max-height: rem-calc(526);
						max-height: rem-calc(580);
					}
					@include media("screen", ">=phone") {
						max-height: rem-calc(508);
					}
					@include media("screen", ">=tablet") {
						// max-height: rem-calc(476);
						max-height: rem-calc(500);
					}
					@include media("screen", ">=desktop") {
						max-height: rem-calc(496);
					}
					@include media("screen", ">=widescreen") {
						max-height: rem-calc(516);
					}
					@include media("screen", ">=x-widescreen") {
						max-height: rem-calc(530);
					}
					&::after {
						content: "";
						background: $white;
						background: linear-gradient(
							180deg,
							rgba($white, 0) 0%,
							$white 36%
						);
						bottom: 0;
						height: 5rem;
						left: 0;
						position: absolute;
						width: 100%;
						@include media("screen", ">=phone") {
							background: linear-gradient(
								180deg,
								rgba($white, 0) 0%,
								$white 60%
							);
						}
					}
				}

				&:not(.featured) {
					&.is-lock {
						.post-text,
						.post-img + .post-content .post-text {
							&::before {
								background-color: $white;
								color: $gray-400;
							}
						}
					}
				}

				&.featured {
					.post-subtitle {
						color: $gray-700;
					}

					// 17817 NWB
					// &:nth-child(8n-1),
					// &:nth-child(8n + 8) {
					// .post-author {
					// 	@include media("screen", ">=tablet") {
					// 		min-height: 5rem;
					// 	}
					// 	@include media("screen", ">=desktop") {
					// 		min-height: 6rem;
					// 	}
					// 	@include media("screen", ">=widescreen") {
					// 		min-height: 7.5rem;
					// 	}
					// }

					// .post-author--img {
					// 	@include media("screen", ">=tablet") {
					// 		height: 5rem;
					// 		width: 5rem;

					// 		& + .post-author--name {
					// 			width: calc(100% - 5.9375rem);
					// 		}
					// 	}
					// 	@include media("screen", ">=desktop") {
					// 		height: 6rem;
					// 		width: 6rem;

					// 		& + .post-author--name {
					// 			width: calc(100% - 6.9375rem);
					// 		}
					// 	}
					// 	@include media("screen", ">=widescreen") {
					// 		height: 7.5rem;
					// 		width: 7.5rem;

					// 		& + .post-author--name {
					// 			width: calc(100% - 8.4375rem);
					// 		}
					// 	}
					// }
					// }
					// 17817 : END
				}
				// }
			}

			.post-img {
				// 8958 Kiehl
				@if $theme-kiehl {
					@include media("screen", ">=phone") {
						max-height: 9.5rem;
					}
					@include media("screen", ">=tablet") {
						max-height: 8rem;
					}
				} @else {
					@include media("screen", ">=desktop") {
						overflow: hidden;
					}
				}
				// 8958 : END

				@include media("screen", ">=desktop") {
					height: 10.5rem;
				}
				@include media("screen", ">=widescreen") {
					height: 13rem;
				}
				@if $theme-kiehl {
					@include media("screen", ">=x-widescreen") {
						height: 15rem;
					}
				} @else {
					@include media("screen", ">=1330px") {
						height: 14rem;
					}
				}

				& + .post-content {
					@if $theme-kiehl {
					} @else {
						@include media("screen", ">=desktop") {
							height: calc(100% - 10.5rem);
						}
						@include media("screen", ">=widescreen") {
							height: calc(100% - 13rem);
						}
						@include media("screen", ">=1330px") {
							height: calc(100% - 14rem);
						}
					}
				}
			}
		}

		.post-content {
			@if $enable-fader-teaser {
				@include media("screen", ">=phone") {
					overflow: hidden;
				}
			}

			.post-links {
				@include media("screen", ">=phone") {
					bottom: rem-calc(-8);
				}
			}
		}

		// 17817 NWB
		// @if $theme-kiehl {
		// } @else {
		// 7th item
		// .post:nth-child(7n) {
		// 	@include media("screen", ">=phone", "<tablet") {
		// width: 100%;

		// @if $enable-fader-teaser {
		// 	.post-body {
		// 		@include media("screen", ">=phone", "<tablet") {
		// 			max-height: rem-calc(640);
		// 		}
		// 	}
		// }

		// .post-img {
		// 	height: auto;
		// 	overflow: visible;
		// }

		// .post-text {
		// 	min-height: 4.5rem;
		// }
		// 		}
		// 	}
		// }
		// 17817 : END

		// 17817 NWB
		// 7th & 8th items
		// .post:nth-child(8n-1),
		// .post:nth-child(8n + 8) {
		// @include media("screen", ">=tablet") {
		// 	background-color: $post-special_bg;
		// 	width: calc(50% - 1.875rem);
		// }

		// @if $enable-fader-teaser {
		// 	.post-body {
		// 		@include media("screen", ">=tablet") {
		// 			max-height: rem-calc(564);
		// 		}
		// 		@include media("screen", ">=desktop") {
		// 			max-height: rem-calc(572);
		// 		}
		// 		@include media("screen", ">=x-widescreen") {
		// 			max-height: rem-calc(600);
		// 		}
		// 		@include media("screen", ">=1330px") {
		// 			max-height: rem-calc(640);
		// 		}
		// 	}
		// }
		// 17817 : END

		// 17817 NWB
		// &:not(.featured) {
		// 8958 Kiehl
		// @if $theme-kiehl {
		// 	@include media("screen", ">=tablet") {
		// 		.post-img {
		// 			height: 12rem;
		// 		}

		// 		.post-content {
		// 			height: calc(100% - 12rem);
		// 		}
		// 	}
		// }
		// 8958 : END

		// @include media("screen", ">=desktop") {
		// 	.post-img {
		// 		height: 16.5rem;
		// 	}

		// 	.post-content {
		// 		height: calc(100% - 16.5rem);
		// 	}
		// }
		// @include media("screen", ">=widescreen") {
		// 	.post-img {
		// 		height: 19.5rem;
		// 	}

		// 	.post-content {
		// 		height: calc(100% - 19.5rem);
		// 	}
		// }
		// @if $theme-kiehl {
		// 	@include media("screen", ">=x-widescreen") {
		// 		.post-img {
		// 			height: 21.125rem;
		// 		}

		// 		.post-content {
		// 			height: calc(100% - 21.125rem);
		// 		}
		// 	}
		// } @else {
		// 	@include media("screen", ">=1330px") {
		// 		.post-img {
		// 			height: 21rem;
		// 		}

		// 		.post-content {
		// 			height: calc(100% - 21rem);
		// 		}
		// 	}
		// }

		// }

		// &.featured .post-content {
		// 	@include media("screen", ">=tablet") {
		// 		height: 100%;
		// 	}
		// }
		// 	}
		// 17817 : END
	}
}

// List view
.posts,
.posts-list {
	margin-top: rem-calc(15);
	margin-bottom: 0;
	// margin-left: rem-calc(-15);
	// margin-right: rem-calc(-15);
	// width: calc(100% + 1.875rem);
}

.posts.list:not(.hit-list):not(.favorites):not(.recommend),
.posts-list {
	margin-top: rem-calc(15);

	&:not(.posts-featured) {
		.post-body {
			@include media("screen", ">=phone") {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	// &:not(.no-teaser) .post {
	&:not(.no-teaser) .post-link {
		@if $enable-fader-teaser {
			// max-height: rem-calc(336);
			// overflow: hidden;
			@include media("screen", "<phone") {
				.send-holder {
					padding-top: 0.25rem;
				}
			}
			@include media("screen", ">=phone") {
				max-height: rem-calc(246);
				overflow: hidden;
				&::after {
					content: "";
					background: $white;
					background: linear-gradient(
						180deg,
						rgba($white, 0) 0%,
						$white 60%
					);
					bottom: 0;
					height: 6.5rem;
					left: 0;
					position: absolute;
					width: 100%;
					@include media("screen", ">=phone") {
						height: 5rem;
					}
				}
			}
			// @include media("screen", ">=tablet") {
			//   max-height: rem-calc(246);
			// }
			// @include media("screen", ">=desktop") {
			//   // min-height: rem-calc(196);
			//   max-height: rem-calc(226);
			// }
			@include media("screen", ">=widescreen") {
				min-height: rem-calc(156);
				// max-height: rem-calc(226);
			}
			@include media("screen", ">=x-widescreen") {
				// min-height: rem-calc(166);
				max-height: rem-calc(226);
			}
			// &::after {
			// 	content: "";
			// 	background: $white;
			// 	background: linear-gradient(180deg, rgba($white, 0) 0%, $white 60%);
			// 	bottom: 0;
			// 	height: 6.5rem;
			// 	left: 0;
			// 	position: absolute;
			// 	width: 100%;
			// 	@include media("screen", ">=phone") {
			// 		height: 5rem;
			// 	}
			// 	// @include media("screen", ">=widescreen") {
			// 	//   height: 5.5rem;
			// 	// }
			// 	// @include media("screen", ">=phone", "<tablet") {
			// 	//   // background: linear-gradient(180deg, rgba($white, 0) 0%, $white 60%);
			// 	//   height: 4.5rem;
			// 	// }
			// 	// @include media("screen", ">=tablet", "<desktop") {
			// 	//   background: linear-gradient(180deg, rgba($white, 0) 0%, $white 60%);
			// 	//   height: 4.25rem;
			// 	// }
			// 	// @include media("screen", ">=widescreen") {
			// 	//   height: 3rem;
			// 	// }
			// 	// @include media("screen", ">=x-widescreen") {
			// 	//   height: 4rem;
			// 	// }
			// }

			.post-body {
				@include media("screen", ">=phone") {
					padding-bottom: rem-calc(45);
				}
				// @include media("screen", ">=widescreen") {
				//   padding-bottom: rem-calc(30);
				// }
			}
		}

		&:not(.featured):not(.post-hit) {
			.post-text {
				@include media("screen", "<phone") {
					display: none;
				}
			}
		}

		// &.featured {
		//   .post-text {
		//     min-height: 4.5rem;
		//   }
		// }
	}

	// .post.no-teaser {
	&.no-teaser .post {
		.post-body {
			padding-bottom: rem-calc(15);
		}
	}

	.post-img,
	.text-link {
		display: none;
	}
}

.posts.list {
	@include media("screen", "<phone") {
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
		width: calc(100% + 1.875rem);
	}

	&.hit-list,
	&.favorites {
		.post {
			border-bottom: 0.0625rem solid $gray-400;
		}

		.post-link {
			border-bottom: 0;
		}

		.post-body {
			padding-bottom: rem-calc(15);
			@include media("screen", ">=phone") {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&.favorites {
		button {
			background: none;
			border: 0;
			&:focus {
				outline: none;
			}
		}
	}

	&.hit-list {
		.banner-devider {
			background-color: $white;
			margin-top: rem-calc(-1);
			padding: {
				top: 1rem;
				bottom: 1rem;
			}
		}
		// .banner-status-title {
		// & ~ .post {
		// 	&:last-child {
		// 		border-bottom: 0 !important;
		// 	}
		// }
		// }
	}
}

// News
.posts-new {
	.post {
		height: 100%;
		@include media("screen", ">=phone") {
			.post-body {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	.post-link {
		border-bottom: 0;
	}

	.post-img {
		height: auto;
		max-height: 12rem;
		@include media("screen", ">=phone") {
			max-height: 9.5rem;
		}
		@include media("screen", ">=tablet") {
			max-height: rem-calc(182);
		}
		@include media("screen", ">=desktop") {
			max-height: 8rem;
		}
		@include media("screen", ">=widescreen") {
			max-height: 7.25rem;
		}

		// max-height: 12rem;
		// @include media("screen", ">=phone") {
		// 	max-height: 16rem;
		// }
		// @include media("screen", ">=tablet") {
		// 	max-height: rem-calc(182);
		// }
		// @include media("screen", ">=desktop") {
		// 	max-height: 8rem;
		// }
		// @include media("screen", ">=widescreen") {
		// 	max-height: 7.25rem;
		// }

		// & + .post-content {
		// 	height: calc(100% - 12rem);
		// 	@include media("screen", ">=phone") {
		// 		height: calc(100% - 16rem);
		// 	}
		// 	@include media("screen", ">=tablet") {
		// 		height: calc(100% - 11.75rem);
		// 	}
		// 	@include media("screen", ">=desktop") {
		// 		height: calc(100% - 8rem);
		// 	}
		// 	@include media("screen", ">=widescreen") {
		// 		height: calc(100% - 7.25rem);
		// 	}
		// }
	}

	.post-title {
		@include media("screen", ">=widescreen", "<x-widescreen") {
			font-size: 1rem;
		}
	}
}

.home {
	.list.posts {
		&.no-teaser .post {
			.post-body {
				padding-bottom: 2rem !important;
			}

			.post-text {
				display: none;
			}
		}
	}
}

// .library {
// 	.posts-header {
// 		display: flex;
// 		justify-content: space-between;
// 	}

// 	.post-view {
// 		margin-top: 0;
// 	}

// 	.group {
// 		justify-content: flex-end;
// 	}
// }

// Visited links on the Livefeed startpage
.livefeed .post-link {
	&:visited {
		.post-title {
			color: #627b8b;
		}
	}
	&:hover,
	&:focus {
		.post-title {
			color: $post-title_color_hover;
		}
	}
}
