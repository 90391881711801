// list reset
%listreset {
	margin: 0;
	padding: 0;
	list-style: none;
}

body {
	overflow-x: hidden;
	overflow-anchor: auto;
	position: relative;
	// Sticky footer
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	// Sticky footer : END
	// &:not(.home) {
	// 	@if $header-bottom-element {
	// 		padding-top: rem-calc(108);
	// 		@include media("screen", ">=desktop") {
	// 			// padding-top: rem-calc(210);
	// 			padding-top: rem-calc(246);
	// 		}
	// 	} @else {
	// 		padding-top: rem-calc(96);
	// 		@include media("screen", ">=desktop") {
	// 			padding-top: rem-calc(126);
	// 		}
	// 	}
	// }

	// &.home.is-top {
	// 	@if $header-bottom-element {
	// 		// padding-top: rem-calc(528);
	// 		padding-top: rem-calc(474);
	// 		@include media("screen", ">=desktop") {
	// 			padding-top: rem-calc(594);
	// 			// padding-top: rem-calc(630);
	// 		}
	// 	} @else {
	// 		// padding-top: rem-calc(96);
	// 		padding-top: rem-calc(476);
	// 		@include media("screen", ">=desktop") {
	// 			// padding-top: rem-calc(510);
	// 			padding-top: rem-calc(474);
	// 		}
	// 	}
	// }
}

main {
	flex: auto; // Sticky footer
}

// Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5gs
@media screen and (device-aspect-ratio: 40/71) {
	select,
	textarea,
	input {
		appearance: none;
		font-size: 1rem;
		font-size: max(16px);
	}
}
// Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7
@media screen and (device-aspect-ratio: 375/667) {
	select,
	textarea,
	input {
		appearance: none;
		font-size: 1rem;
		font-size: max(16px);
	}
}
// Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max
@media screen and (device-aspect-ratio: 9/16) {
	select,
	textarea,
	input {
		appearance: none;
		font-size: 1rem;
		font-size: max(16px);
	}
}

.no-scroll {
	overflow-y: hidden;
	// @include media("screen", "<desktop") {
	// 	height: 100vh;
	// 	left: 0;
	// 	right: 0;
	// 	position: fixed;
	// }
}

.full-width {
	left: 50%;
	margin-left: -50vw !important;
	overscroll-bahavior: contain;
	overflow-x: hidden;
	position: relative;
	width: 100vw;
}

.bg-light-right {
	&::after {
		@include media("screen", ">=desktop") {
			content: "Anzeige";
			background-color: $gray-100;
			color: $gray-600;
			height: 90%;
			left: 55%;
			padding: rem-calc(15);
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: 45%;
			z-index: -1;
		}
		@include media("screen", ">=widescreen") {
			height: 80%;
		}
	}
}

.hide {
	display: none !important;
}

.responsive-image {
	display: block;
	width: 100%;
	height: auto;
}

.text-underline {
	text-decoration: underline;
}

.is-visible {
	opacity: 1;
	visibility: visible;
}
.is-invisible {
	opacity: 0;
	visibility: hidden;
}
.is-disable {
	cursor: not-allowed;
	opacity: 0.5;
	position: relative;
	z-index: 0;
	&::before {
		content: "";
		height: 100%;
		width: 100%;
		position: absolute;
		z-index: 1;
	}
}

// Scrolling
.scrolling {
	@include scrolling;
}

// Page Preview
.page-preview {
	// border-top: 0.0625rem solid $gray-400;
	// border-radius: $border-radius;
	color: $gray-700;
	// display: inline-block;
	font-size: 1.25rem;
	font-weight: 600;
	margin: 2rem 0;
	// padding: 0.25rem 0.5rem;
	// padding-top: 1rem;
}

// Marginal left
.marginal-left {
	@include media("screen", ">=tablet") {
		margin-left: rem-calc(80) !important;
	}
}

// Overlay offer
.overlay-offer {
	// background-color: $brand-color;
	// background-color: $gray-100;
	border: 0.0625rem solid $gray-500;
	border-radius: 1rem;
	margin-top: 3rem;
	padding: 3rem 0.5rem 0.5rem;
	position: relative;
	@include media("screen", ">=phone") {
		padding: 3rem;
	}

	.badge {
		margin-bottom: 2rem;
	}

	*[class*="badge-outline"] {
		border-width: 0.0625rem;
		border-style: solid;

		&-primary {
			border-color: $primary;
		}
	}
}

// Overlay offer module
.overlay-offer-module {
	@if $theme-kiehl {
		background-color: #f2f5fb;
		border: 0.0625rem solid rgba(0, 65, 148, 0.5);
		border-radius: 0.5rem;
	} @else {
		background-color: $gray-200;
		border: 0.0625rem solid $gray-500;
		border-radius: 1rem;
	}
	margin: 1.5rem auto 2rem auto;
	padding: 3rem 0.9375rem 2rem;
	@include media("screen", ">=phone") {
		margin-top: 3rem;
		padding: 3rem;
		// max-width: 75%;
	}

	.col {
		max-width: rem-calc(202);
	}

	a {
		img {
			transition: transform 0.3s ease-in-out;
		}

		&:hover,
		&:focus {
			img {
				transform: scale(1.05);
			}
		}
	}
}

// Posts marker for posts filter
.posts-marker {
	position: relative;
	// top: rem-calc(-75);
	top: rem-calc(-100);
	@include media("screen", ">=widescreen") {
		top: rem-calc(-45);
	}
}

// DMS Verweis
button[data-target="#dmsbiene"] {
	@include media("screen", ">=1400px") {
		right: rem-calc(-100);
		position: absolute;
	}
}

.fit-content {
	height: fit-content;
}
