.offcanvas {
	@if $theme-sis {
		background: $offcanvas_bg;
		color: $offcanvas_color;
	} @else {
		@include media("screen", "<desktop") {
			// @include animate(all, 0.5s, ease);
			background: $offcanvas_bg;
			color: $offcanvas_color;
			cursor: pointer;
		}

		ul {
			@include media("screen", "<desktop") {
				list-style: none;
				margin: 0;
				padding: 0;
				width: 100%;
			}
		}
	}
}

.nav-salutation {
	font-size: rem-calc(15);

	&--name {
		color: $offcanvas-salutation_color;
		font-weight: 400;
	}

	&:not(.nav-salutation--name) {
		font-weight: 700;
	}
}

.navbar-btn-close {
	background: transparent;
	border: 0;
	color: $offcanvas_close-btn_color;
	height: rem-calc(48);
	position: absolute;
	right: rem-calc(12);
	top: rem-calc(12);
	transition: $transition-base;
	width: rem-calc(48);
	&:hover,
	&:focus {
		color: $offcanvas_close-btn_color-hover;
		outline: none;
	}
}
