.modal-dialog {
	&-search,
	&-branch,
	&-doctype {
		// background-color: $gray-100;
		background-color: rgba($info, 0.15);
		border-radius: 0.25rem;
		font-family: "Courier New", Courier, monospace;
		// font-weight: 300;
		padding: 0 0.25rem;
	}
}

.modal-header {
	background-color: $modal-header-bg;
	@if $theme-sis {
		.modal-title {
			color: $white;
		}

		.close {
			color: $brand-color-lightest;
			transition: color 0.25s ease-in-out;

			&:hover,
			&:focus {
				color: $white;
			}
		}
	}
}

.modal-footer {
	& * {
		margin: $modal-footer-margin-between / 2;
	}
}

.modal-content {
	.form-check {
		padding-left: 0;
	}
}

.modal-xxl {
	@include media("screen", ">=phone") {
		// --bs-modal-width: #{$modal-sm};
		--bs-modal-width: 540px;
	}
	@include media("screen", ">=tablet") {
		// --bs-modal-width: #{$modal-md};
		--bs-modal-width: 720px;
	}
	@include media("screen", ">=desktop") {
		// --bs-modal-width: #{$modal-lg};
		--bs-modal-width: 960px;
	}
	@include media("screen", ">=widescreen") {
		--bs-modal-width: #{$modal-xl};
	}
	@include media("screen", ">=x-widescreen") {
		--bs-modal-width: #{$modal-xxl};
	}
}

.modal-open {
	// // detect of a touch device
	// // @media (hover: hover) a not touch device
	// // @media (hover: none) a touch device
	// @media (hover: hover) {
	// 	.header,
	// 	.footer-top {
	// 		padding-right: rem-calc(15);
	// 	}
	// }
	@include media("screen", ">=desktop") {
		.footer-top {
			padding-right: rem-calc(15);
		}

		.privacy-warning {
			padding-right: 2.1875rem;
		}
	}
}

.favomodal .modal-dialog {
	@include media("screen", ">=phone") {
		min-width: rem-calc(546);
	}
}

.mb-3 {
	.modal-dialog {
		width: 100%;
	}
}

.modal-dialog-scrollable {
	form,
	.favomodalcontent {
		overflow-x: hidden;
		overflow-y: auto;
	}
}

// .loesung-dialog {
// 	.modal-dialog {
// 		@extend .modal-fullscreen;
// 	}
// }
