.teaser-sidebar {
	display: flex;
	align-items: center;

	&-cover {
		background-color: $teaser-sidebar_cover_bg;
		height: rem-calc(64);
		overflow: hidden;
		min-width: rem-calc(48);
		width: rem-calc(48);
	}

	&-txt {
		overflow-wrap: break-word;
		hyphens: auto;

		strong {
			display: block;
			// hyphens: auto;
			// overflow-wrap: break-word;
			// word-break: break-word;
		}
	}
	&:not(:hover):not(:focus):not(:active) {
		.teaser-sidebar-txt {
			color: $body-color;
		}
	}
}
