.aside-link {
	display: block;
	margin-bottom: rem-calc(15);
	padding-left: rem-calc(15);
	position: relative;
	transition: $transition-base;
	&::before {
		content: "";
		position: absolute;
		top: 9px;
		left: 0;
		width: 5px;
		height: 5px;
		background-color: $aside-link_marker_bg;
	}
}
