$footer-bg: $primary !default;
$footer-copyright-color: $white !default;
$footer-copyright-font-weight: null !default;
$footer-link-color: $dark !default;
$footer-link-color-hover: $brand-color !default;
$footer-link-font-size: 0.875rem !default; // 14px
$footer-link-font-weight: 700 !default;

$footer-nav-margin-bottom: 0.625rem !default; //10px
$footer-nav-font-size: 0.9375rem !default; //15px
$footer-nav-font-weight: null !default;
$footer-nav-item-color: $white !default;
$footer-nav-item-color-hover: $brand-color !default;
$footer-nav-text-transform: null !default;
$footer-nav-item-margin-xs: 0.9375rem !default; //15px
$footer-nav-item-margin-sm: 1.25rem !default; //20px
$footer-nav-item-margin-md: 1.875rem !default; //30px
$footer-nav-item-symbol: null !default;
$footer-nav-item-symbol-margin: null !default;

$footer-service-bg: $brand-color !default;
$footer-service-color: $dark !default;
$footer-service-font-size: null !default;
$footer-service-font-weight: null !default;
$footer-service-link-font-size: 1rem !default;
$footer-service-link-font-weight: null !default;
$footer-service-text-transform: null !default;

$footer-service-icon-font-size: 1rem !default;

$footer-divider-border: null !default;
