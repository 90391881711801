.filter-abc {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 3rem -0.5rem;

	&--item {
		@if $theme-linde {
			border: 0.0625rem solid $blue-middle;
			color: $blue-middle;
		} @else if $theme-sis {
			border: 0.0625rem solid $brand-color-light;
			color: $brand-color-light;
		} @else {
			border: 0.0625rem solid $secondary;
			color: $secondary;
		}
		display: inherit;
		align-items: center;
		justify-content: center;
		margin: 0.25rem;
		height: 2.5rem;
		width: 2.5rem;
		&:hover,
		&:focus,
		&.is-active {
			@if $theme-linde {
				background-color: $blue-middle;
				color: $white;
			} @else if $theme-sis {
				background-color: $brand-color-light;
				color: $white;
			} @else {
				background-color: $secondary;
				color: $white;
			}
		}
	}
}
