// OWL Carousel
.owl-carousel {
	.owl-nav {
		display: flex;
		align-items: center;
		top: 42.5%;
		position: absolute;
		width: 100%;
		@include media("screen", ">=tablet") {
			top: 45%;
		}
		@include media("screen", ">=desktop") {
			top: 47%;
		}

		.owl-prev {
			left: 0;
			@include media("screen", "<desktop") {
				border-radius: 0 2rem 2rem 0;
				justify-content: flex-start;
			}
			@include media("screen", ">=desktop") {
				left: rem-calc(-46);
			}
		}
		.owl-next {
			right: 0;
			@include media("screen", "<desktop") {
				border-radius: 1.25rem 0 0 1.25rem;
				justify-content: flex-end;
			}
			@include media("screen", ">=desktop") {
				right: rem-calc(-46);
			}
		}

		button.owl-prev,
		button.owl-next {
			background-color: $white;
			border: rem-calc(1) solid $gray-300;
			box-shadow: $box-shadow;
			color: $gray-800;
			display: flex;
			align-items: center;
			height: rem-calc(40);
			opacity: 1;
			position: absolute;
			transition: $transition-fade;
			width: rem-calc(28);
			@include media("screen", "<desktop") {
				[class*="icon-"] {
					font-size: 1rem;
					margin: 0 0.0625rem;
				}
			}
			@include media("screen", ">=desktop") {
				border-radius: 50%;
				justify-content: center;
				height: rem-calc(54);
				width: rem-calc(54);
			}
			&:focus {
				outline: none;
			}

			&.disabled {
				opacity: 0.35;
			}
		}
	}

	&.promotion {
		max-width: rem-calc(300);
	}

	// &.owl-drag {
	// 	.owl-item {
	// 		touch-action: none;
	// 	}
	// }
}

.owl-dots {
	text-align: center;

	.owl-dot {
		margin-left: 0.25rem;
		margin-right: 0.25rem;
		&:hover,
		&:focus {
			outline: 0;
			box-shadow: none;
		}

		span {
			background-color: $owl-dot-bg;
			border-radius: $owl-dot-border-radius;
			display: inline-block;
			height: $owl-dot-size;
			transition: $transition-base;
			width: $owl-dot-size;
		}

		&.active span {
			background-color: $owl-dot-bg-active;
			transform: scale(1.25);
		}
	}
}

.editorial-carousel {
	.post-head,
	.post-links {
		display: none;
	}
}

.mx-featured {
	.posts-new {
		.owl-stage {
			display: flex;
			flex-flow: row nowrap;
		}

		.owl-dots {
			@include media("screen", ">=tablet") {
				margin-top: 0;
			}
		}
	}
}
