// FOOTER TOP
.footer-top {
	background-color: $footer-service-bg;
	margin-top: -0.0625rem;
	@if $theme-nwb {
		margin-top: 0;
		@include media("screen", "<desktop") {
			.rounded-3 {
				border-radius: 0 !important;
			}

			.list-horizontal {
				margin-left: 18%;
				margin-right: auto;
			}
		}
		@include media("screen", ">=desktop") {
			height: rem-calc(36);
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1020;

			li {
				padding-top: 0;
				padding-bottom: 0;
			}

			.rounded-top-0 {
				border-top-left-radius: 0 !important;
				border-top-right-radius: 0 !important;
			}

			.list-horizontal {
				justify-content: center;
			}
		}

		.container {
			@include media("screen", "<widescreen") {
				width: 100%;
			}
		}
	}

	a {
		color: $footer-link-color;
		// font-size: calc(#{$footer-service-link-font-size} / 1.125);
		font-size: $footer-link-font-size;
		font-weight: $footer-link-font-weight;
		// @include media("screen", ">=tablet") {
		// 	font-size: $footer-service-link-font-size;
		// }
		// @include media("screen", "<widescreen") {
		// 	font-size: calc(#{$footer-service-link-font-size} / 1.125);
		// }
		@if $theme-nwb {
			// @include media("screen", ">=desktop") {
			// color: $white;
			display: block;
			font-weight: 300;
			line-height: 1;
			padding-top: rem-calc(11);
			padding-bottom: rem-calc(11);
			&:hover,
			&:focus {
				color: $footer-link-color-hover;
			}
			// }
		}
	}
}
