.spoiler {
	background-color: $gray-100;
	border-top: 0.0625rem solid $gray-300;
	margin-top: 1rem;
	padding: 1rem 1rem 1.5rem;
	position: relative;
	text-align: center;
	&::after {
		content: "";
		position: absolute;
		left: -0.125rem;
		bottom: rem-calc(-12);
		width: 100%;
		height: rem-calc(24);
		background: radial-gradient(circle, $white rem-calc(6), $white 0, transparent 0.375rem, transparent rem-calc(12), transparent rem-calc(12));
		background-size: rem-calc(24) rem-calc(24);

		// background: radial-gradient(circle at 10px -7px, transparent 8px, currentColor 8px, currentColor 9px, transparent 9px) repeat-x,
		// 	radial-gradient(circle at 10px 27px, transparent 8px, currentColor 8px, currentColor 9px, transparent 9px) repeat-x;
		// background-size: 20px 20px;
		// background-position: -10px calc(100% + 16px), 0 calc(100% - 4px);
	}

	[class*="icon-"] {
		color: $gray-700;
		vertical-align: middle;
	}
}
