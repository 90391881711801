$offcanvas_bg: $white !default;
$offcanvas_color: $gray-900 !default;
$offcanvas-salutation_color: $gray-900 !default;
$offcanvas_close-btn_color: $gray-500 !default;
$offcanvas_close-btn_color-hover: $gray-900 !default;
$offcanvas-link_color: $primary !default;
$offcanvas-desc_color: $blue-middle !default;
$offcanvas-desc_color-hover: $primary !default;

$nav-link_border-bottom: 1px solid $gray-300 !default;
$nav-link_color: $primary !default;
$nav-link_color-hover: $primary !default;
$nav-link-desc_color: $blue-middle !default;
$nav-link-desc_color-hover: $primary !default;

$nav-link-label_font-size: 1rem !default;
$nav-link-label_font-weight: 700 !default;
$nav-link-label_text-transform: uppercase !default;

$nav-link-icon_bg: $blue-lightest !default;
$nav-link-icon_bg-hover: $white !default;
$nav-link-icon_color: $primary !default;
