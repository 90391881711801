.main-focus {
	margin-top: rem-calc(30);
	margin-bottom: rem-calc(30);

	.btn {
		margin-top: rem-calc(4);
		margin-bottom: rem-calc(4);
		margin-right: rem-calc(8);
	}

	& + .mx-featured {
		margin-top: 0;
	}
}

.main-focus--title {
	font-size: rem-calc(14);
	margin-top: rem-calc(4);
	margin-bottom: rem-calc(4);
}
