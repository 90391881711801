.navbar {
	width: 100%;
	font-size: rem-calc(16);
	order: 1;
	@include media("screen", ">=desktop") {
		font-size: rem-calc(12);
		order: 0;
		margin: 0 rem-calc(16);
		width: calc(100% - 32px);
	}

	.nav-link {
		color: $nav-link_color;
		padding: rem-calc(10) 0;
		transition: color 0.15s ease-in-out;
		@include media("screen", ">=375px") {
			padding-top: rem-calc(12);
			padding-bottom: rem-calc(12);
		}
		@include media("screen", "<411px") {
			font-size: rem-calc(14);
		}
		@include media("screen", ">=411px") {
			padding-top: rem-calc(15);
			padding-bottom: rem-calc(15);
		}
		@include media("screen", "<desktop") {
			border-bottom: $nav-link_border-bottom;
		}
		@include media("screen", ">=desktop") {
			padding: rem-calc(3.5) rem-calc(7.5) rem-calc(4.5);
		}
		&:hover,
		&:focus {
			color: $nav-link_color-hover;

			.nav-link--desc {
				@include media("screen", "<desktop") {
					color: $offcanvas-desc_color-hover;
				}
				@include media("screen", ">=desktop") {
					color: $nav-link_color-hover;
				}
			}
		}

		&--label {
			display: inline-block;
			font-size: $nav-link-label_font-size;
			font-weight: $nav-link-label_font-weight;
			margin-bottom: rem-calc(8);
			text-transform: $nav-link-label_text-transform;
		}

		&--desc {
			display: inline-block;
			font-size: rem-calc(13);
			font-weight: 400;
			margin-left: rem-calc(52);
			width: calc(100% - 52px);
			@include media("screen", ">=411px") {
				font-size: rem-calc(14);
				margin-top: rem-calc(5);
			}
			@include media("screen", "<desktop") {
				color: $offcanvas-desc_color;
			}
			@include media("screen", ">=desktop") {
				color: $nav-link-desc_color;
			}
		}

		i {
			background-color: $nav-link-icon_bg;
			border-radius: 50%;
			color: $nav-link-icon_color;
			display: inline-flex;
			font-size: rem-calc(18);
			height: rem-calc(36);
			margin-right: 1rem;
			align-items: center;
			justify-content: center;
			width: rem-calc(36);
		}

		.rounded-pill {
			font-size: 0.625rem;
			font-weight: 700;
			line-height: 1.5;
			margin-left: 0.1875rem;
			text-transform: uppercase;
		}
	}

	.nav-item {
		& + .nav-item {
			@include media("screen", ">=desktop") {
				margin-left: rem-calc(18);
			}
		}

		a {
			@include media("screen", "<desktop") {
				color: $offcanvas-link_color;
			}
			@include media("screen", ">=desktop") {
				color: $nav-link_color;
			}
		}
	}
}
