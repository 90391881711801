$icomoon-font-path: "../fonts/iconfont/fonts" !default;
@import "src/assets/fonts/iconfont/styles";

// Fontawesome
$fa-font-path: "../fonts/fapro/webfonts" !default;
@import "src/assets/fonts/fapro/scss/fontawesome";
@import "src/assets/fonts/fapro/scss/brands";
@import "src/assets/fonts/fapro/scss/light";
@import "src/assets/fonts/fapro/scss/regular";
@import "src/assets/fonts/fapro/scss/solid";
@import "src/assets/fonts/fapro/scss/v4-shims";

.icon-x1 {
	font-size: rem-calc(18) !important;
}
.icon-x2 {
	font-size: rem-calc(24) !important;
}
.icon-x3 {
	font-size: rem-calc(32) !important;
}
.icon-x4 {
	font-size: rem-calc(40) !important;
}
.icon-x5 {
	font-size: rem-calc(48) !important;
}

[class*="icon-circle-"] {
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.icon-circle-1 {
	height: rem-calc(32);
	width: rem-calc(32);
}

.icon-circle-2 {
	height: rem-calc(40);
	min-width: rem-calc(40);
	width: rem-calc(40);

	[class*="icon-"] {
		font-size: rem-calc(20);
	}
}

.icon-circle-3 {
	height: rem-calc(54);
	width: rem-calc(54);

	[class*="icon-"] {
		font-size: rem-calc(24);
	}
}

.icon-circle-4 {
	height: rem-calc(64);
	width: rem-calc(64);

	[class*="icon-"] {
		font-size: rem-calc(32);
	}
}

.icon-circle-5 {
	height: rem-calc(86);
	width: rem-calc(86);

	[class*="icon-"] {
		font-size: rem-calc(48);
	}
}
