.teasers {
	.teaser {
		display: flex;
		flex: 1;
		flex-direction: column;
		justify-content: space-between;
		margin: $card-group-margin;
		text-align: center;
		@include media("screen", "<phone") {
			margin-bottom: 0;
			padding: $teaser_padding / 2;
		}
		@include media("screen", ">=phone") {
			padding: $teaser_padding;
			min-width: $teaser_min-width;
		}

		&-title {
			color: $teaser-title_color;
			font-size: $teaser-title_font-size;
			hyphens: auto;
			line-height: 1.25;
			text-transform: $teaser-title_text-transform;

			[class*="icon-"] {
				color: $teaser-icon_color;
			}
		}

		&-body {
			margin: 2rem 0;
		}
	}
}
