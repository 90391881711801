@import "variables";

@font-face {
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 900;
	font-display: $fa-font-display;
	src: url("#{$fa-font-path}/fa-solid-900.woff2") format("woff2"),
		url("#{$fa-font-path}/fa-solid-900.woff") format("woff"),
		url("#{$fa-font-path}/fa-solid-900.ttf") format("truetype");
}

.fa,
.fas {
	font-family: "Font Awesome 5 Pro";
	font-weight: 900;
}
