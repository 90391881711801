.magazine {
	// INDEX PAGE
	&-article {
		&:not(:last-child) {
			border-bottom: 1px solid $gray-300;
			margin-bottom: rem-calc(24);
			padding-bottom: rem-calc(24);
		}

		&-inner {
			position: relative;
			&:not(:first-child) {
				margin-top: rem-calc(20);
			}
			&:hover,
			&:focus {
				// h3 {
				// 	@if $theme-kiehl {
				// 		color: $secondary;
				// 	} @else {
				// 		color: $link-hover-color;
				// 	}
				// }

				.link-more {
					color: $link-hover-color;
				}
			}

			.post-type {
				margin-top: rem-calc(4);
				margin-bottom: 0;
			}
		}

		h2 {
			color: $body-color;
			font-size: rem-calc(21);
			font-weight: 400;
			@include media("screen", ">=tablet") {
				font-size: rem-calc(24);
			}
		}

		h3 {
			@include animate(color);
			// für neue Eilnachrichten
			// display: flex;
			// justify-content: space-between;
			// für neue Eilnachrichten : END
			font-size: rem-calc(16);
			font-weight: 700;
			line-height: 1.5;
			margin-bottom: 0;
			@include media("screen", ">=tablet") {
				font-size: rem-calc(18);
			}
			&:not(:first-child) {
				margin-top: rem-calc(18);
			}
		}

		&--subtitle {
			color: $black !important;
			font-weight: 300 !important;
			font-size: 1.25rem !important; //1.375rem;
			margin: {
				// top: -0.5rem !important;
				bottom: 1.5rem !important;
			}
		}

		p {
			@if $theme-linde {
				color: $gray-800;
			} @else {
				color: $gray-600;
			}
			margin-bottom: 0;
		}

		// .link-more {
		// 	@include animate(all);
		// 	display: none;
		// 	margin-top: rem-calc(12);
		// }

		// Eilnachrichten toggler
		&--toggler {
			color: $gray-900;
			display: flex;
			align-items: center;
			font-weight: 700;
			// margin-top: rem-calc(-10);

			i {
				@include animate(all 0.3s ease-in-out);
				background-color: $gray-900;
				border-radius: 50%;
				color: $white;
				display: flex;
				align-items: center;
				justify-content: center;
				font-size: rem-calc(12);
				height: rem-calc(22);
				margin-right: rem-calc(7);
				opacity: 0.5;
				padding: rem-calc(5);
				width: rem-calc(22);
			}

			&:hover {
				i {
					opacity: 1;
				}
			}
			&:focus,
			&.is-active {
				i {
					opacity: 1;
					transform: rotate(180deg);
				}
			}
		}

		// .quick-messages--icon {
		// 	background: $gray-400;
		// 	border: 1px solid $gray-400;
		// 	border-radius: 50%;
		// 	color: $white;
		// 	display: flex;
		// 	align-items: center;
		// 	justify-content: center;
		// 	height: rem-calc(40);
		// 	margin-top: rem-calc(-30);
		// 	// margin-right: rem-calc(15);
		// 	width: rem-calc(40);
		// }
	}

	&-sidebar {
		@include media("screen", ">=desktop") {
			width: 12rem;

			&[data-sticky] {
				position: sticky;
				top: 9rem;
			}
		}
		@include media("screen", ">=x-widescreen") {
			width: 14rem;
		}
	}
}

// Magazine Article
// .magazine-category {
// 	font-size: rem-calc(14);
// 	font-weight: 700;
// 	line-height: 1.5;
// 	// margin: rem-calc(0 -15px 20px -15px);
// 	margin-bottom: rem-calc(20);
// 	padding: rem-calc(10) rem-calc(15);
// 	text-transform: uppercase;

// 	.magazine-subcategory {
// 		font-weight: 400;
// 	}
// }

.magazine-import {
	&--image {
		text-align: center;
		max-width: rem-calc(900);
	}

	// ERGOsign overrides
	#dokumentinhalt #dokumentinhaltcontent h2 {
		@include media("screen", "<375px") {
			font-size: rem-calc(21);
			line-height: 1.25;
		}
		@include media("screen", ">=375px", "<480px") {
			font-size: rem-calc(24);
			line-height: 1.5;
		}
	}

	#dokumentinhalt #dokumentinhaltcontent h3 {
		@include media("screen", "<375px") {
			font-size: rem-calc(16);
			line-height: 1.25;
		}
		@include media("screen", ">=375px", "<480px") {
			font-size: rem-calc(18);
			line-height: 1.5;
		}
	}

	#dokumentinhalt #dokumentinhaltcontent .marginal-wrapper {
		@include media("screen", "<widescreen") {
			position: relative;
		}
	}

	#dokumentinhalt #dokumentinhaltcontent .marginal-wrapper .marginal {
		@include media("screen", "<widescreen") {
			margin-left: 0;
			padding-top: rem-calc(15);
			padding-left: 0;
			text-align: left;
		}
	}
}

// .livefeed-app {
// 	.back-bar {
// 		border-bottom: 0.0625rem solid $gray-300;
// 		margin-bottom: rem-calc(30);
// 		@include media("screen", "<tablet") {
// 			height: rem-calc(45);
// 			line-height: rem-calc(45);
// 			padding-top: 0;
// 			padding-bottom: 0;
// 		}
// 		&:not(.is-sidebar) {
// 			@include media("screen", "<desktop") {
// 				margin-left: rem-calc(-15);
// 				margin-right: rem-calc(-15);
// 			}
// 		}
// 	}
// }

// .page-index {
// 	.back-bar {
// 		border-bottom: 0;
// 		@include media("screen", "<desktop") {
// 			// &:not(.is-sidebar) {
// 			//     .btn-holder * {
// 			//         color: $white;
// 			//     }
// 			// }

// 			.magazine-header--back {
// 				border-right: 0;
// 			}
// 		}
// 	}
// }
