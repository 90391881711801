$teaser-small_qty: 6 !default;
$teaser-small_qty_xs: 6 !default;
$teaser-small_qty_sm: 8 !default;
$teaser-small_qty_md: 6 !default;
// $teaser-small_qty_lg: 6 !default;
// $teaser-small_qty_xl: 6 !default;

$teaser-small_bg-color: $blue !default;
// $teaser-small_bg-color: $blue-lighter !default;

// $teaser-small_color: $body-color !default;
// $teaser-small_color_hover: $black !default;
$teaser-small_color: $blue !default;
$teaser-small_color_hover: $blue !default;

$teaser-small_border: null !default;
$teaser-small_border-radius: 50% !default;

$teaser-small_icon_color: $blue-lightest !default;
// $teaser-small_icon_color: $blue !default;
$teaser-small_icon_color_hover: $yellow !default;
// $teaser-small_icon_color_hover: null !default;
$teaser-small_icon-container_size: 4rem !default;
$teaser-small_icon_size: 2rem !default; //1.75rem !default;

$teaser-small_image_size: null !default;
$teaser-small_image_gap: null !default;

$teaser-small_size: 64px !default;
$teaser-small_size_desktop: 84px !default;
$teaser-small_max-size_desktop: null !default;

$teaser-small_mx: 8vw !default;
$teaser-small_mx_sm: 8.25vw !default;
$teaser-small_mx_md: 6vw !default;
$teaser-small_my: 0.625rem !default;

$teaser-small-title_icon_color: null !default;
// $teaser-small_font-size: 0.6875rem !default; // 11px
$teaser-small_font-size: 0.75rem !default; // 12px
$teaser-small_font-weight: null !default;
$teaser-small_letter-spacing: 0.03125rem !default; // 0.5px

// $teasers-small_bg: $brand-color-lightest !default;
$teasers-small_bg: $blue-lightest !default;
// $teasers-small_bg-image_before: url("../images/linde/bg-blur.jpg") no-repeat !default;
$teasers-small_bg-image_before: null !default;
$teasers-small_bg-image: url("../images/linde/linde-circle.svg") no-repeat 0%
	100% !default;
$teasers-small_margin-top: -5.25rem !default;
$teasers-small_padding: 9rem 0 2rem !default;
$teasers-small_desktop_padding-bottom: 3rem !default;
$teasers-small_width: 42rem !default;
