.btn-group {
	.btn {
		&:first-child {
			border-radius: $btn-group_first-child_border-radius;
		}
		&:last-child {
			border-radius: $btn-group_last-child_border-radius;
		}
	}

	.dropdown {
		&:first-child .btn {
			border-radius: $btn-group_first-child_border-radius;
		}
		&:last-child .btn {
			border-radius: $btn-group_last-child_border-radius;
			margin-left: -0.0625rem;
		}
	}
}

.btn-group-label {
	font-size: $btn-group_label_font-size;
	margin-right: $btn-group_label_margin-right;
}
