.audioplayer {
	background-image: linear-gradient(to bottom, $gray-100 0%, $gray-200 80%);
	background-color: $gray-200;
	// border-radius: rem-calc(6);
	border-radius: 1.25rem;
	border: 0.0625rem solid $gray-400;
	box-shadow: $audioplayer_box-shadow;
	// margin-left: auto;
	// margin-right: auto;
	margin-bottom: 2rem;
	padding: rem-calc(15);
	position: relative;
	// max-width: 42rem;
	@include media("screen", ">=phone") {
		padding: {
			left: rem-calc(20);
			right: rem-calc(20);
		}
	}

	&.standard {
		margin-bottom: rem-calc(60);
	}

	.title-time-container {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: stretch;
		align-content: stretch;
		justify-content: space-between;
		width: 100%;
	}

	.title-container {
		flex: 0 1 auto;
		margin-right: rem-calc(15);
	}

	.time-container {
		flex: auto;
		text-align: right;
		white-space: nowrap;

		.current-time,
		.time-divider,
		.duration {
			margin: 0;
			padding: 0;
		}

		.time-divider {
			font-weight: 300;
		}

		.current-time,
		.duration {
			font-weight: 600;
		}

		.current-time {
			color: $audioplayer_time-current;
		}

		.duration {
			color: $audioplayer_time-duration;
		}
	}

	.time-bar {
		background-color: $gray-300;
		border-radius: rem-calc(2);
		box-shadow: $box-shadow-sm inset;
		cursor: pointer;
		// height: rem-calc(6);
		height: rem-calc(4);
		margin: rem-calc(10) 0 rem-calc(20);
		position: relative;
		width: 100%;
	}

	.buffered {
		background: #e5e5e5;
		height: 100%;
		left: 0;
		position: absolute;
		width: 0%;
		z-index: 1;
	}

	.played {
		background-color: $audioplayer_timebar_bg-played;
		border-radius: rem-calc(3);
		height: 100%;
		left: 0;
		// overflow: hidden;
		position: absolute;
		transition: width linear 0.1s;
		width: 0%;
		max-width: 100%;
		z-index: 10;
	}

	.played-dragger {
		background-color: $audioplayer_timebar_bg-played-dragger;
		border-radius: 50%;
		float: right;
		height: rem-calc(16);
		left: 0.5rem;
		position: relative;
		top: rem-calc(-6);
		width: rem-calc(16);
	}

	.btn-download {
		border: rem-calc(1) solid transparent;
		margin-top: rem-calc(10);
		padding: 0;
		position: absolute;
		right: rem-calc(20);
		white-space: normal;
		&:focus {
			box-shadow: none;
		}
	}

	.audio-volume-control {
		background-color: transparent;
		cursor: pointer;
		display: inline-block;
		margin-left: rem-calc(5);
		position: relative;
		vertical-align: top;
		width: 6.75rem;
		@include media("screen", ">=phone") {
			margin-left: rem-calc(15);
			width: 12rem;
		}

		&.sound-muted {
			cursor: not-allowed;

			.selected-volume {
				background-color: transparent;
			}
		}
	}

	.volume-bar {
		background-color: $gray-300;
		border-radius: rem-calc(5);
		box-shadow: $box-shadow-sm inset;
		// display: none;
		height: rem-calc(10);
		margin: rem-calc(16) 0;
		position: relative;
	}

	.selected-volume {
		background-color: $audioplayer_volumebar_bg-selected;
		border-radius: rem-calc(5);
		height: 100%;
		left: 0;
		overflow: hidden;
		position: absolute;
		transition: width linear 0.05s;
		// max-width: 100%;
		width: 100%;
		z-index: 10;
	}

	.toggle-panel--close {
		// color: $gray-600;
		// position: absolute;
		// right: rem-calc(15);
		// top: rem-calc(10);
		background: $gray-400;
		border: rem-calc(1) solid $gray-400;
		border-bottom-left-radius: rem-calc(20);
		border-top-right-radius: rem-calc(6);
		color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: rem-calc(12);
		height: rem-calc(30);
		position: absolute;
		right: rem-calc(-1);
		top: rem-calc(-1);
		width: rem-calc(50);
		&:hover {
			color: $primary;
		}
	}

	// Control buttons
	[class*="btn-audio"] {
		// background-color: $gray-300;
		background-color: $white;
		border: 0.0625rem solid $gray-400;
		border-radius: 50%;
		box-shadow: $box-shadow-sm;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-family: "iconfont";
		height: rem-calc(42);
		margin-right: 0.25rem;
		padding: 0;
		position: relative;
		width: rem-calc(42);
	}

	.btn-audio-play {
		@extend .icon-play;
		&.toggled {
			@extend .icon-pause;
		}
	}

	.btn-audio-back {
		// @extend .icon-backward;
		@extend .icon-chevron-left;
	}

	.btn-audio-forward {
		// @extend .icon-forward;
		@extend .icon-chevron-right;
	}

	.btn-audio-volume {
		margin-left: rem-calc(10);
		@extend .icon-volume-up;

		&.mute {
			@extend .icon-volume-mute;
		}
		&.low-volume {
			@extend .icon-volume-down;
		}
		&.middle-volume {
			@extend .icon-volume-up;
		}
	}
	// Control buttons : END

	// &.playlist {
	.playlist-list {
		// display: table;
		background-color: $audioplayer_playlist_bg;
		border-radius: 0 0 1.25rem 1.25rem;
		border-top: rem-calc(1) dashed $gray-500;
		margin: 1.5rem rem-calc(-15) rem-calc(-15) rem-calc(-15);
		padding: 0.75rem rem-calc(15) rem-calc(15);
		// width: 100%;
		@include media("screen", ">=phone") {
			margin: {
				left: rem-calc(-20);
				right: rem-calc(-20);
			}
		}

		.item-row {
			color: $audioplayer_playlist_item_color;
			display: flex;
			align-items: center;

			&.current {
				color: $audioplayer_playlist_item_color-current;
			}
		}
	}

	.title-container {
		// color: $white;
		// display: table-cell;
		// padding: rem-calc(5) rem-calc(5) rem-calc(5) rem-calc(10);
		padding: {
			top: rem-calc(5);
			bottom: rem-calc(5);
		}
		vertical-align: middle;
		@include media("screen", "<phone") {
			font-size: $font-size-sm;
		}
	}

	.time-container {
		// display: table-cell;
		// padding: rem-calc(5);
		text-align: right;
		vertical-align: middle;
	}

	.download-container {
		// display: table-cell;
		display: inline-block;
		height: rem-calc(36);
		width: rem-calc(36);

		.btn__download {
			// color: $audioplayer_btn-download_color;
			display: block;
			height: inherit;
			width: inherit;
			// &:hover,
			// &:focus {
			// 	color: $audioplayer_btn-download_color-hover;
			// }

			& > div {
				@extend .icon-download;
				display: flex;
				align-items: center;
				justify-content: center;
				font-family: "iconfont";
				height: inherit;
				width: inherit;
			}
		}
	}
}

// #readArticle,
// #audiolist,
// .collapse.show {
// 	& + .heading {
// 		padding-top: 0;
// 		transition: padding-top 0.2s ease-in-out;
// 	}

// 	&.show {
// 		& + .heading {
// 			padding-top: 1rem;
// 		}
// 	}
// }
