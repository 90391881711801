// List view of the Post Helpers
.posts-popular {
	display: flex;
	flex-wrap: wrap;

	.post {
		@if $theme-sis {
			width: 100%;
		} @else {
			width: 100%;
			@include media("screen", ">=desktop") {
				flex: 0 0 50%;
				max-width: calc(50% - 1.875rem);

				&:nth-child(2) {
					.post-link {
						border-top: 0;
					}
				}
			}
		}
	}

	&.list {
		.post {
			margin-top: 0;
		}
	}
}

.popular {
	.post {
		@include media("screen", ">=tablet") {
			flex: 0 0 33.33333%;
			max-width: calc(33.33333% - 1.875rem);

			.post-img {
				max-height: 8.75rem;
			}
		}
		@include media("screen", ">=desktop", "<widescreen") {
			flex: 0 0 50%;
			max-width: calc(50% - 1.875rem);

			.post-img {
				max-height: 9.75rem;
			}
		}
		@include media("screen", ">=widescreen") {
			.post-img {
				max-height: 7.5rem;
			}
		}
	}
}
