.post {
	&-author {
		display: flex;
		align-items: center;
		// min-height: fit-content;
		min-height: rem-calc(64);
		margin-top: 0.5rem;
		margin-bottom: 1rem;
		position: relative;
		@include media("screen", ">=phone") {
			min-height: rem-calc(60);
		}
		@include media("screen", ">=tablet") {
			min-height: rem-calc(50);
		}
		@include media("screen", ">=desktop") {
			min-height: rem-calc(56);
		}
		@include media("screen", ">=widescreen") {
			min-height: rem-calc(64);
		}

		&--img {
			border-radius: 50%;
			display: flex;
			align-items: center;
			height: rem-calc(64);
			margin: 0 rem-calc(15) 0 0;
			overflow: hidden;
			right: 0; // grid
			width: rem-calc(64);
			z-index: 1;
			@include media("screen", ">=phone") {
				height: rem-calc(60);
				width: rem-calc(60);
			}
			@include media("screen", ">=tablet") {
				height: rem-calc(48);
				width: rem-calc(48);
			}
			@include media("screen", ">=desktop") {
				height: rem-calc(56);
				width: rem-calc(56);
			}
			@include media("screen", ">=widescreen") {
				height: rem-calc(64);
				width: rem-calc(64);
			}

			& > img {
				height: auto;
				margin: -0.0625rem;
				min-width: calc(100% + 0.125rem);
				max-width: calc(100% + 0.125rem);
			}
		}

		&--name {
			color: $gray-700;
			font-size: rem-calc(13);
			font-style: italic;
			font-weight: 500;
			overflow: hidden;
			text-overflow: ellipsis;
			// transition: $transition-base;
			width: calc(100% - 4.9375rem);
			z-index: 0;
		}
	}

	&-body {
		&.multi-authors {
			.post-author {
				[data-bs-toggle="tooltip"] {
					cursor: pointer;
					z-index: 5;
				}
			}

			.post-author--img {
				margin-right: 0.25rem;
				@include media(">=411px") {
					margin-right: 0.5rem;
				}
			}
		}
	}
}

.grid {
	.post-author--name {
		height: 2rem; // grid
		line-height: 2rem; // grid
	}
}

.list {
	margin-top: 2rem;
	margin-left: rem-calc(-15);
	margin-right: rem-calc(-15);

	&.posts-featured {
		.post-link {
			border-bottom: 0;
		}

		.post-body {
			@if $enable-fader-teaser {
				max-height: rem-calc(346);
				overflow: hidden;
				@include media("screen", ">=480px") {
					max-height: rem-calc(316);
				}
				@include media("screen", ">=phone") {
					max-height: rem-calc(230);
				}
				@include media("screen", ">=desktop") {
					max-height: rem-calc(266);
				}
				@include media("screen", ">=widescreen") {
					min-height: rem-calc(220);
					max-height: rem-calc(242);
				}
				&::after {
					content: "";
					background: $white;
					background: linear-gradient(
						180deg,
						rgba($white, 0) 0%,
						$white 60%
					);
					bottom: 0;
					height: 6.5rem;
					left: 0;
					position: absolute;
					width: 100%;
					@include media("screen", ">=phone") {
						// background: linear-gradient(180deg, rgba($white, 0) 0%, $white 50%);
						height: 5rem;
					}
				}
			}
		}
	}

	.post {
		&.featured {
			@include media("screen", "<phone") {
				.post-text {
					display: none;
				}
			}

			.post-title {
				transition: $transition-base;
			}

			.post-body {
				@include media("screen", ">=phone") {
					.post-content {
						padding-right: 11rem;
					}
				}
				@include media("screen", ">=desktop") {
					padding-bottom: 0;
				}

				&.multi-authors {
					.post-author--img {
						margin: 0.25rem;
					}

					[data-bs-toggle="tooltip"] {
						.post-author--img {
							margin-right: 0;
						}
					}

					.post-author--name {
						display: none;
					}
				}
			}

			.post-author {
				@include media("screen", ">=phone") {
					flex-direction: column;
					padding-left: 0.75rem;
					padding-right: 0.75rem;
					position: absolute;
					right: 0;
					width: 11rem;
				}

				&--img {
					position: relative;
					z-index: 10;
					@include media("screen", ">=phone") {
						margin-right: 0;
					}
				}

				&--name {
					background-color: transparent;
				}
			}

			.text-link {
				display: none;
			}
		}
	}

	&:not(.posts-featured) {
		.post-author {
			@include media("screen", ">=phone") {
				align-items: flex-end;
				margin-bottom: 0.375rem;
			}

			&--img {
				height: rem-calc(56);
				width: rem-calc(56);
			}

			&--name {
				@include media("screen", "<phone") {
					padding-left: 0;
				}
				@include media("screen", ">=phone") {
					margin: 0.5rem 0 0 0;
					text-align: right;
					width: 100%;
				}
			}
		}

		.post-body.multi-authors {
			@include media("screen", ">=desktop") {
				.post-content {
					padding-right: 17rem;
				}
			}

			.post-author {
				flex-direction: row;
				top: rem-calc(10);
				bottom: auto;
				right: 0;
				@include media("screen", ">=phone") {
					align-items: flex-start;
					justify-content: flex-end;
				}
				@include media("screen", ">=phone", "<desktop") {
					flex-wrap: wrap;
				}
				@include media("screen", ">=desktop") {
					width: 17rem;
				}
			}
		}
	}
}

.posts-featured,
.editorial-carousel {
	.owl-stage-outer {
		@include media("screen", ">=tablet") {
			margin-top: rem-calc(8);
		}
	}

	.owl-stage {
		display: flex;
		flex-flow: row nowrap;
	}

	.post {
		background-color: $white;
		height: 100%;
		@include media("screen", "<phone") {
			&-links {
				position: absolute;
				right: rem-calc(15);
			}
		}
		@include media("screen", ">=phone", "<desktop") {
			.post-author--img {
				height: rem-calc(56);
				width: rem-calc(56);
			}
		}
		@include media("screen", ">=phone", "<tablet") {
			margin-left: rem-calc(10) !important;
			margin-right: rem-calc(10) !important;
		}
		@include media("screen", ">=phone", "<tablet") {
			margin: 0 rem-calc(15);
		}
		@include media("screen", ">=tablet") {
			height: calc(100% - 1.875rem);
		}
	}

	.post-img {
		display: none;
	}

	&:not(.list) {
		.post-link {
			border-bottom: 0;
		}

		.post-body {
			@if $enable-fader-teaser {
				max-height: rem-calc(380);
				overflow: hidden;
				@include media("screen", ">=phone") {
					max-height: rem-calc(436);
				}
				@include media("screen", ">=desktop") {
					max-height: rem-calc(456);
				}
				@include media("screen", ">=widescreen") {
					max-height: rem-calc(426);
				}
				&::after {
					content: "";
					background: $white;
					background: linear-gradient(
						180deg,
						rgba($white, 0) 0%,
						$white 40%
					);
					bottom: 0;
					height: 6rem;
					left: 0;
					position: absolute;
					width: 100%;
				}
			}

			.post-body {
				padding-bottom: rem-calc(45);
			}

			.post-links {
				right: rem-calc(15);
			}
		}

		// @if $enable-fader-teaser {
		// 	.post-content {
		// 		position: relative;
		// 		&::after {
		// 			content: "";
		// 			background: $white;
		// 			background: linear-gradient(90deg, rgba($white, 0) 0%, $white 40%);
		// 			bottom: 0;
		// 			left: 0;
		// 			position: absolute;
		// 			width: 100%;
		// 			@include media("screen", "<tablet") {
		// 				height: 4.25rem;
		// 			}
		// 			@include media("screen", ">=tablet", "<desktop") {
		// 				height: 3.5rem;
		// 			}
		// 			@include media("screen", ">=desktop") {
		// 				height: 2.5rem;
		// 			}
		// 		}
		// 	}

		// 	.post-text {
		// 		min-height: 4.5rem;
		// 		@include multiLineTrancate(1.5rem, 4);
		// 		// margin-bottom: 1rem;
		// 		@include media("screen", ">=tablet") {
		// 			min-height: 6rem;
		// 		}
		// 	}

		// 	.post-links {
		// 		bottom: rem-calc(10);
		// 		@include media("screen", ">=phone") {
		// 			right: 0;
		// 		}
		// 	}
		// }
	}

	&.list {
		.post {
			border: 0.0625rem solid $gray-300;
			height: calc(100% - 1.875rem);
			margin-bottom: -0.0625rem;
			@include media("screen", ">=phone") {
				border-radius: $border-radius;
				margin-bottom: rem-calc(15);
				overflow: hidden;
				&-links {
					bottom: rem-calc(12);
					position: absolute;
					right: rem-calc(25);
				}

				.send-holder {
					text-align: center;
					width: rem-calc(130);
				}
			}

			// .post-text {
			// 	@if $enable-fader-teaser {
			// 		@include multiLineEllipsisEnd(1.5rem, 4);
			// 		@include media("screen", ">=x-widescreen") {
			// 			// min-height: 6rem;
			// 			@include multiLineEllipsisEnd(1.5rem, 4);
			// 		}
			// 	}
			// }
		}

		.post-body {
			&:not(.multi-authors) .post-author {
				@include media("screen", ">=phone") {
					top: 1.5rem;
				}
				@include media("screen", ">=tablet") {
					top: 1.25rem;
				}

				&--img {
					@include media("screen", ">=phone") {
						margin-right: 0;
						height: rem-calc(80);
						width: rem-calc(80);
					}
				}

				&--name {
					padding: 0;
					@include media("screen", ">=phone") {
						margin: 0.625rem 0;
						text-align: center;
					}
				}
			}

			&.multi-authors {
				.post-author {
					@include media("screen", ">=phone") {
						flex-direction: row;
						flex-wrap: wrap;
						justify-content: center;
					}

					&--img {
						margin: 0.25rem;
						height: rem-calc(60);
						width: rem-calc(60);
					}
				}
			}
		}
	}
}

.posts-featured {
	.post {
		@include media("screen", "<phone") {
			margin-top: rem-calc(-1);
		}
	}

	.post-subtitle {
		display: none;
	}

	&.posts-context {
		.post {
			@if $enable-fader-teaser {
				max-height: rem-calc(296);
				overflow: hidden;
				@include media("screen", ">=480px") {
					max-height: rem-calc(256);
				}
				@include media("screen", ">=phone") {
					max-height: rem-calc(306);
				}
				@include media("screen", ">=tablet") {
					max-height: rem-calc(320);
				}
				@include media("screen", ">=desktop") {
					max-height: rem-calc(360);
				}
			}

			@include media("screen", ">=phone") {
				margin: 0 !important;
			}
		}
	}
}

.editorial-carousel {
	.post-content {
		&::after {
			content: none !important;
		}
	}

	.post-text {
		display: none;
	}
}

.home .post-author--name {
	@include media("screen", ">=phone") {
		width: 100%;
	}
}
