// Imported old content from DMS
// *****************************
// Right sidebar
[class*="ala_content--title-"] {
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
	&::before {
		color: $aside_header_line-bg;
		font-family: "iconfont";
		font-size: 1.25rem;
		font-weight: 400;
		margin-left: rem-calc(10);
		margin-right: rem-calc(22);
		position: relative;
		z-index: 1;
		@if $theme-linde {
			top: rem-calc(6);
		} @else {
			top: rem-calc(5);
		}
	}
	&::after {
		content: "";
		background-color: $primary;
		border-radius: 50%;
		top: 0;
		left: 0;
		position: absolute;
		height: 2.5rem;
		min-width: 2.5rem;
		width: 2.5rem;
		// z-index: -1;
		z-index: 0;
	}
}

.ala_content {
	hyphens: auto;
	padding-top: rem-calc(5);
	padding-bottom: rem-calc(30);
	transition: $transition-base;

	&--title-nachricht {
		@extend .icon-message;
	}

	&--title-zeitschrift {
		@extend .icon-magazine-opened;
	}

	&--title-info {
		@extend .icon-info-o;
	}

	&--title-buch {
		@extend .icon-book-comments;
	}

	&--title-norm {
		@extend .icon-paragraph;
	}

	&--title-arbeitshilfe {
		@extend .icon-tools;
	}

	&--title-rechtsprechung {
		@extend .icon-balance-scale;
	}

	&--title-scout {
		@extend .icon-stb-scout;
	}

	&--title-verwaltung {
		@extend .icon-doc-management;
	}

	ul {
		list-style: none;
		margin-bottom: 0;
		padding: 0;
	}

	& > ul ul {
		border-top: rem-calc(1) solid $aside_header_line-color;
		font-size: rem-calc(14);
		line-height: 1.25;
		margin-top: rem-calc(21);
		margin-bottom: rem-calc(40);
		padding-top: rem-calc(30);
		position: relative;
		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			@include triangle(1.5rem, $aside_header_line-color, down);
			top: 0;
			left: rem-calc(50);
		}
		&::after {
			@include triangle(1.5rem, $white, down);
			top: rem-calc(-2);
			left: rem-calc(50);
		}
	}

	& > ul > li {
		color: $primary;
		font-size: 0.875rem;
		font-weight: 600;
		text-transform: uppercase;
		&::before {
			font-family: "iconfont";
		}
	}

	& > ul > li > ul > li {
		font-size: $font-size-base;
		font-weight: normal;
		text-transform: none;
	}

	a:not(.badge) {
		// color: $aside-link_color_hover;
		display: block;
		line-height: 1.5;
		margin-bottom: rem-calc(15);
		padding-left: rem-calc(15);
		position: relative;
		transition: $transition-base;
		&::before {
			content: "";
			position: absolute;
			top: 9px;
			left: 0;
			width: 5px;
			height: 5px;
			background-color: $aside-link_marker_bg;
		}

		// &:hover,
		// &:focus {
		// 	color: $aside-link_color_hover;
		// }
	}
}

.aside {
	flex-grow: 1;
	align-self: flex-start;
	@include media("screen", ">=widescreen") {
		flex-basis: calc(33.33333% - 1.875rem);
	}

	&[data-sticky] {
		@include media("screen", ">=desktop") {
			@include scrolling;
			position: sticky;
			top: 0.5rem;
			// top: rem-calc(-1);

			// & > .aside-section {
			// 	height: 100%;

			// 	& > .also-look-at {
			// 		height: 100%;
			// 	}
			// }
		}

		.aside-content,
		.ala_content {
			padding-bottom: 0;
		}

		.ala_content > ul:last-child ul {
			margin-bottom: 1rem;
		}
	}

	&.start {
		// @include media("screen", ">=desktop") {
		// 	// margin-right: rem-calc(30);
		// 	// max-width: calc(33.33333% - 1.875rem);
		// 	padding-top: rem-calc(9);
		// }
		@include media("screen", ">=widescreen") {
			margin-right: rem-calc(30);
			// max-width: calc(33.33333% - 1.875rem);
		}
	}

	&.end {
		@include media("screen", "<desktop") {
			margin-top: rem-calc(30);
		}
		// @include media("screen", ">=desktop") {
		// 	// margin-left: rem-calc(30);
		// 	// max-width: calc(33.33333% - 1.875rem);
		// 	padding-top: rem-calc(9);
		// }
		@include media("screen", ">=widescreen") {
			margin-left: rem-calc(30);
			// @if not($theme-sis) {
			// 	margin-left: rem-calc(30);
			// }
			// max-width: calc(33.33333% - 1.875rem);
		}

		&.home {
			padding-top: 3rem;
			@include media("screen", "<desktop") {
				margin-bottom: -3rem;
				padding-bottom: 3rem;
			}
			@include media("screen", ">=desktop") {
				margin-top: -3rem;
				overflow: hidden;
				padding-top: 3rem;
				position: relative;
				width: 100%;
				@if $aside-top-element {
					&::before {
						content: "";
						@include triangle(35vw 1.5rem, $white, up-left);
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}

	&-content {
		// padding-top: rem-calc(15);
		padding-top: rem-calc(5);
		padding-bottom: rem-calc(30);
		transition: $transition-base;

		a:not(.teaser-sidebar):not(.text-link):not(.post-link):not(.aside-link):not(.badge) {
			display: block;
			margin-bottom: rem-calc(5);
			padding-left: 1rem;
			position: relative;
			// &::before {
			// 	content: "";
			// 	height: 0;
			// 	width: 0;
			// 	border-bottom: 0.25rem solid transparent;
			// 	border-left: 0.6rem solid $aside-link_marker_bg;
			// 	border-top: 0.25rem solid transparent;
			// 	opacity: 0;
			// 	position: absolute;
			// 	transition: $transition-base;
			// 	top: rem-calc(7);
			// 	left: 0;
			// }
			&.is-active {
				font-weight: 600;
				color: $aside-link_color_hover;
				// &::before {
				// 	opacity: 1;
				// }
			}
		}

		a,
		.selected {
			// word-wrap: break-word;
			word-break: break-word;

			span.text-bg-secondary {
				@if $theme-kiehl {
					color: $white !important;
				}
			}
		}

		a:not(.link):not(.text-link):not(.badge) {
			// color: $gray-900;
			color: $aside-link_color;
			&:hover,
			&:focus {
				color: $aside-link_color_hover;
			}
		}

		.checkbox {
			& > input[checked] {
				& + label {
					& > a {
						color: $aside-link_color_hover !important;
					}
				}
			}
		}
	}

	&-section {
		&:not(:first-child) {
			.aside-header {
				border-top: rem-calc(1) solid transparent;
				// padding-top: rem-calc(10);
			}
		}

		&-card {
			background-color: $aside_header_line-bg;
			border-radius: 0.5rem;
			margin-bottom: 1rem;
			padding: 1rem 1rem 0;
		}

		.aside-header[aria-expanded="false"] {
			border-top: rem-calc(1) solid $aside_header_line-color;
			margin-bottom: -0.0625rem;

			& + .aside-content {
				padding-bottom: 0;
			}
		}

		// Scrolling
		.ala_content {
			// @include media("screen", ">=desktop") {
			// @include scrolling;
			// @if $theme-kiehl {
			// 	// height: calc(100vh - 13.875rem);
			// 	height: calc(100vh - 13.5rem);
			// } @else {
			// 	// height: calc(100vh - 15rem);
			// 	height: calc(100vh - 15.75rem);
			// }
			// }
			// @include media("screen", ">=widescreen") {
			// 	@if $theme-kiehl {
			// 		// height: calc(100vh - 13.875rem);
			// 		height: calc(100vh - 14.125rem);
			// 	} @else {
			// 		// height: calc(100vh - 15.75rem);
			// 		height: calc(100vh - 16.25rem);
			// 	}
			// }

			a {
				font-size: $font-size-sm;
			}
		}
	}

	.table-of-contents {
		.book-title {
			margin-bottom: 2rem;
			padding-bottom: 0.25rem;
			position: relative;
			&::after {
				content: "";
				border-bottom: 0.25rem solid $gray-400;
				bottom: -0.5rem;
				margin-left: 1rem;
				position: absolute;
				width: calc(100% - 1rem);
			}

			a {
				color: $aside-link_color_hover;
				margin-top: -1.25rem;
			}
		}

		.tree {
			li:not(.book-title) > span > a {
				margin-bottom: 0 !important;
				padding-left: 0 !important;
				&::before {
					content: none;
				}
			}
		}
	}
}

// Left sidebar
.table-of-contents {
	hyphens: auto;

	.sticky-header {
		display: none;
	}

	.book-title {
		display: block;
		font-weight: 600;
	}

	ul {
		list-style: none;
		padding-left: 0;
	}

	.tree {
		ul {
			display: none;
			margin-top: rem-calc(4);
			margin-bottom: rem-calc(15);

			ul {
				margin-top: 0;
				margin-left: 1.125rem;
				margin-bottom: 0.5rem;
			}
		}

		li.open {
			& > ul {
				display: block !important;
			}
		}

		& > .open > span,
		& > .toggle-chapter > span {
			font-weight: 600;
			line-height: 1.25;
			transition: transform 0.3s ease-in-out;
		}

		// DON'T Remove !!!
		// & > li.toggle-chapter > span {
		// 	&::before {
		// 		@include triangle(
		// 			0.5rem 0.6rem,
		// 			$aside-link_color_hover,
		// 			right
		// 		);
		// 		content: "";
		// 		top: rem-calc(12);
		// 		left: 0.0625rem;
		// 		position: absolute;
		// 		transition: transform 0.2s ease-in-out;
		// 	}
		// }

		// & > li.toggle-chapter.open > span {
		// 	&::before {
		// 		transform: rotate(90deg);
		// 	}
		// }
		// DON'T Remove !!!

		& > li.toggle-chapter.selected > span {
			&::before {
				border-left-color: $white;
			}
		}

		li {
			li {
				@if $theme-kiehl {
					font-size: rem-calc(16);
				} @else {
					font-size: rem-calc(14);
				}
			}
		}

		& > .toggle-chapter {
			& > ul {
				margin-top: 0;
			}

			& > span > a {
				padding-left: 0;
			}

			& > ul > .toggle-chapter {
				& > span {
					display: block;
					font-weight: 700;
					padding-left: 1.5rem;
				}
			}
		}

		li:not(.book-title) span:not(.new) {
			cursor: pointer;
			display: inline-block;
			padding: 0.25rem 0.25rem 0.25rem 1rem;
			position: relative;
			transition: all 0.15s ease-in-out;
			width: 100%;
		}

		li:not(.selected):not(.book-title) span:not(.new) {
			&:hover,
			&:focus {
				color: $aside-link_color_hover;
			}
		}

		.book-title-oneliner {
			display: block;
			margin-left: 1rem;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			@if $theme-linde {
				& + br + a,
				& + a {
					border-bottom: 0.0625rem solid transparent;
					color: $primary !important;
					margin-left: 1rem;
					padding-left: 0 !important;

					&:hover,
					&:focus {
						border-bottom-color: $primary;
					}
				}
			}
		}

		li.selected {
			& > span {
				background-color: $aside-link_bg;
				color: $white;
				&:hover,
				&:focus {
					color: $white !important;
				}

				.new {
					background-color: $white;
					color: $aside-link_bg;
				}
			}

			& > span > a {
				color: $white !important;
				&:hover,
				&:focus {
					color: inherit;
				}
			}
		}

		.new {
			display: none;
		}
	}
}
// Imported old content from DMS : END

.new {
	background-color: $aside-link_color_hover;
	border-radius: 0.125rem;
	color: $white;
	display: inline-block;
	font-size: rem-calc(10);
	font-weight: 600;
	margin-top: rem-calc(-4);
	margin-right: 0.375rem;
	padding: 0.125rem 0.25rem;
	text-transform: uppercase;
	vertical-align: middle;
}
