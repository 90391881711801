#dokumentinhalt {
	margin-top: 2rem;

	a {
		border-bottom: 0.0625rem solid transparent;
		font-weight: 400;
		color: $primary;
		&:link {
			color: $primary;
		}

		&:visited {
			color: $link-visited !important;
			border-bottom-color: $link-visited !important;
		}

		&:hover,
		&:focus {
			color: $primary;
			border-bottom-color: $primary;
		}
	}

	.author-info > a,
	.einleitung a {
		font-weight: 700;
	}

	.author-info > a {
		&:link,
		&:visited {
			color: $primary;
		}

		&:hover,
		&:focus {
			color: $gray-900;
			border-bottom: 0;
		}
	}

	a.no-link {
		&:link,
		&:visited {
			color: $gray-900;
		}

		&:hover,
		&:focus {
			color: $blue-middle;
			border-bottom: 0;
		}
	}

	// Foot note
	a[data-fnLink],
	a[data-fnlink] {
		font-size: rem-calc(12);
		font-weight: 400;
		margin-left: 0.0625rem;
		vertical-align: top;
		&:hover,
		&:focus {
			color: $primary;
			border-bottom-color: $primary;
		}

		&:visited {
			color: $link-visited !important;
		}
	}
	a[data-fnote] {
		&:visited {
			color: $link-visited !important;
		}
	}
	// Foot note : END

	.choose-variation {
		color: $primary;

		&:visited {
			color: $link-visited !important;

			.arrow {
				color: inherit;
			}
		}

		&:hover,
		&:focus {
			color: $primary;
			border-bottom-color: $primary;
		}

		.arrow {
			color: $primary;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	.h7,
	.h8 {
		[data-search] {
			a {
				border-bottom-width: 0.125rem;
				&:link {
					color: $blue-middle;
					font-weight: 700;
				}
				&:hover,
				&:focus {
					border-bottom-color: $blue-middle;
				}
			}
		}
	}

	// CI font-size ******************************
	.h7,
	.h8 {
		font-size: rem-calc(16);
	}

	.heading {
		color: $blue;
		font-size: 1rem;
		font-weight: 600;

		.head_left {
			color: $gray-700;
		}
	}

	.answer {
		color: $primary !important;
		&:hover,
		&:focus {
			.arrow {
				color: inherit;
			}
		}

		.arrow {
			color: inherit;
		}
	}

	ul:not([type="i"]):not([type="a"]) {
		& > li::before,
		& > li ul > li::before,
		& > li ul > li ul > li::before {
			border-radius: 50%;
			margin: {
				top: 8px;
				left: -26px;
			}
			height: 8px;
			width: 8px;
		}
	}
	// CI font-size : END *************************

	// Marginal
	.marginal-wrapper {
		.marginal {
			color: $gray-700;
			font-size: rem-calc(12);
			// Page
			&[id*="yPage_"] {
				background-color: $blue-lightest;
				color: $blue;
				font-weight: 700;
				display: inline-flex;
				margin-top: -0.175rem;
				padding: rem-calc(4) rem-calc(5);
				position: relative;

				&::before {
					content: "▼";
					margin-right: 0.25rem;
					opacity: 0.5;
				}
			}
			// Page : END

			.visible-mobile {
				display: block;
			}
		}
	}

	& > .stretch {
		.marginal-wrapper {
			.marginal {
				.visible-mobile {
					display: none;
				}

				.visible-stretch {
					display: block;
					white-space: normal;
				}
			}
		}
	}
	// Marginal : END

	// Infobox
	.multimedia,
	.aufgabefall,
	.loesung:not(.beispiel),
	.information,
	.rechtsgrundlage,
	.definition,
	.sonstig,
	.praxis,
	.aussage,
	.beispiel,
	.internet,
	.literatur,
	.rechtsquelle {
		.title {
			&::before,
			&::after {
				content: none !important;
			}
		}
	}
	// Infobox : END

	// Zitierhinweis
	.literature_source input {
		width: 100%;
		&::selection {
			background: $blue-middle;
			color: $white;
		}
	}
	// Zitierhinweis : END

	.literature-link,
	.rechtsquelle-link {
		a {
			color: $primary;
			&:link {
				color: $primary;
			}

			&:visited {
				color: $link-visited !important;
			}

			&:hover,
			&:focus {
				border-bottom-color: $primary;
			}
		}

		& > div {
			background: #eee;
			display: none;
			margin-top: rem-calc(20);
			padding: rem-calc(20);
			text-align: left;

			& > p {
				margin-left: 0;
			}
		}

		& > a {
			border-bottom: 0;
			font-weight: 400;
			cursor: pointer;

			&:hover,
			&:focus {
				color: $blue-middle;
			}

			& > span {
				display: none;
			}

			&::before {
				content: "\25B6";
				display: inline-block;
				margin-right: 0.5rem;
				transform: none;
				transition: transform 0.2s ease-in-out;
			}
		}

		.title {
			display: inline-block;
			*display: inline;
			margin-bottom: rem-calc(20);
			margin-top: 0;
			zoom: 1;
		}

		& > .content-box {
			margin-left: 0 !important;
		}

		& > p {
			padding-bottom: rem-calc(20);
		}

		&.open {
			& > a {
				&::before {
					transform: rotate(90deg);
				}

				& > span {
					display: none;
				}
			}

			& > div {
				display: block;
			}
		}
	}

	// Audioplayer
	.audioplayer {
		.btn__download {
			border-bottom: 0;
		}
	}
	// Audioplayer : END

	// Loesung dialog ****************************************
	.loesung-dialog {
		.table-layout {
			width: 100%;
		}

		.table-layout-col {
			padding: 0;

			.content-box {
				display: block;
				margin-left: 0 !important;
				width: 100%;
			}
		}
	}
	// Loesung dialog : END ****************************************

	// Video privacy notice
	.imgContainerPreview {
		.imgContainerText {
			.btn {
				&.btn-primary {
					background-color: $primary;
					border-color: transparent;
				}
			}
		}
	}
	// Video privacy notice : END

	// Clipboard
	.copy-to-clipboard-field {
		border-color: rgba($primary, 0.25) !important;
		&:focus {
			border-color: $primary !important;
		}
	}

	.btn-copy-to-clipboard {
		background-color: $primary;
		border-color: $primary;
		@extend .btn-primary;

		&:hover,
		&:focus {
			background-color: $primary !important;
		}
	}
	// Clipboard : END

	// Page break
	.icon-page-break {
		color: $blue-middle;
	}
	// Page break : END

	// Pure CSS Tooltip
	span[data-tooltip] {
		&::after {
			background-color: $blue;
			color: $white;
		}
		&::before {
			border-top-color: $blue;
		}

		&:hover {
			.icon-page-break {
				color: $blue;
			}
		}
	}

	tbody > tr:first-child {
		span[data-tooltip] {
			&:hover {
				&::before {
					border-bottom-color: $blue;
				}
			}
		}
	}
	// Pure CSS Tooltip : END
}

.header {
	@include media("screen", ">=desktop") {
		z-index: 1011;
	}
}

body:not(.document) {
	.marginal-left {
		margin-left: 0 !important;
		margin-top: 3rem;
		margin-bottom: 3rem;
		@include media("screen", ">=phone") {
			margin-left: auto !important;
			margin-right: auto !important;
			max-width: 75%;
		}
		@include media("screen", ">=desktop") {
			max-width: rem-calc(680);
		}
	}
}

.mx-featured {
	h2 {
		a {
			border-bottom: 0.125rem solid transparent;
			color: $blue-middle;
			&:focus,
			&:hover {
				border-bottom-color: $primary;
			}
		}
	}
}

// Divider
section {
	hr {
		border-top: 0.25rem solid $primary;
	}
}

// Post
.post-link {
	border-bottom-color: $gray-900;
}

// Sidebar
.aside-content {
	.lead {
		color: $primary;
		font-weight: 300;
	}
}

.aside.home {
	.aside-header {
		border-top: 0;
		&[aria-expanded="false"] {
			border-bottom-color: transparent;
		}
	}
}

// Search
.search .form-control {
	border-color: transparent;
	outline: 0.125rem solid rgba($blue, 0.5);
}

// Redaktionell empfohlen
.book {
	&.comment {
		.book-title {
			h4 {
				font-size: 1rem;
				font-weight: 400;
			}
		}
	}
}

.badge {
	&.text-bg-light {
		&:hover,
		&:focus {
			background-color: $gray-300 !important;
		}
	}
}

.beta-version {
	font-size: 0.625rem !important;
	font-weight: 400 !important;
	opacity: 0.8;
	pointer-events: none;
	text-align: left;
	white-space: normal;

	@include media("screen", ">=phone") {
		line-height: 1.25;
		margin-left: rem-calc(15);
	}
}

.bg-new {
	background-color: $turkis;
}
