.privacy-warning {
	background-color: rgba($black, 0.85);
	color: $white;
	height: auto;
	margin: 0;
	opacity: 1;
	padding: rem-calc(20) rem-calc(20) 0;
	position: fixed;
	left: 0;
	bottom: 0;
	width: 100%;
	text-align: center;
	z-index: 1100;

	h3 {
		color: inherit;
		font-size: rem-calc(18.725);
		font-weight: 600;
		line-height: 1.2;
		margin: 0;
	}

	p {
		font-size: 1rem;
		font-weight: 300;
		line-height: 1.5;
		margin: 0;
	}

	.info {
		margin: 0 auto;
	}

	.submit {
		display: inline-block;
		vertical-align: middle;
		padding: 20px 0;

		a {
			cursor: pointer;
			display: inline-block;
			font-family: inherit;
			text-align: center;
			vertical-align: middle;
			border: 1px solid transparent;
			transition: background-color 0.2s ease, color 0.2s ease, border-color 0.2s ease;
			margin: 0;
			text-decoration: none !important;
			font-size: rem-calc(14);
			font-weight: 700;
			line-height: rem-calc(29);
			color: $black;
			background-color: $brand-color;
			padding: rem-calc(6) rem-calc(16);
			min-width: rem-calc(140);
			height: rem-calc(45);
			border-radius: rem-calc(12);
			@include media("screen", ">=desktop") {
				min-width: rem-calc(180);
			}
		}
	}
}
