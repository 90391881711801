.service {
	clear: both;
	height: 100%;
	&:not(:last-child) {
		border-bottom: 0.0625rem solid $black;
		padding-bottom: 1.5rem;
		@include media("screen", ">=desktop") {
			padding-bottom: 3rem;
		}
	}

	&-cover {
		float: left;
		margin: {
			bottom: rem-calc(10);
			right: rem-calc(20);
		}
		max-width: rem-calc(100);
		@include media("screen", ">=phone") {
			max-width: rem-calc(130);
		}
		@include media("screen", ">=desktop") {
			max-width: rem-calc(160);
		}

		img {
			height: auto;
			width: 100%;
		}
	}

	&-title {
		&:visited h2 {
			color: $link-visited;
		}
		&:hover,
		&:focus {
			// color: $blue;
			text-decoration: underline;
		}

		h2 {
			transition: color 0.3s;
			@include media("screen", "<tablet") {
				font-size: rem-calc(18);
			}
			&:hover,
			&:focus {
				color: $gray-900;
			}
		}
	}

	&-content {
		height: 100%;

		p:last-child {
			margin-bottom: 0;
		}
	}
}
