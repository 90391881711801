.btn-ui {
	animation: shadow-pulse 2s 3;
	background-color: $btn-ui_bg;
	border-radius: $btn-ui_width + 0.75rem;
	display: block;
	height: $btn-ui_width + 0.75rem;
	line-height: $btn-ui_height + 0.875rem;
	overflow: hidden;
	width: $btn-ui_width + 0.75rem;
	&:hover,
	&.is-active {
		box-shadow: $shadow-medium;
		width: $btn-ui_width-hover;

		.btn-ui--icon {
			background-color: $btn-ui_icon_bg-hover;
			color: $btn-ui_icon_color-hover;
		}

		.btn-ui--dialog {
			color: $btn-ui_dialog_color-hover;
			opacity: 1;
			padding-right: rem-calc(54);
			width: 100%;
		}
	}

	&--dialog {
		@include animate(opacity width, 0.3s, ease-in-out);
		border-radius: $btn-ui_border-radius;
		bottom: 0;
		color: $btn-ui_dialog_color;
		font-weight: 600;
		font-size: rem-calc(12);
		height: 100%;
		opacity: 0;
		padding: 0 0 0 rem-calc(20);
		position: absolute;
		text-transform: $btn-ui_dialog_text-transform;
		white-space: nowrap;
		width: 0;
	}

	&--icon {
		@include animate(all, 0.4s, ease-in-out);
		background-color: $brand-color;
		border: 0 solid transparent;
		border-radius: $btn-ui_height + 0.75rem;
		bottom: 0;
		color: $btn-ui_icon_color;
		display: flex;
		align-items: center;
		justify-content: center;
		height: $btn-ui_height + 0.75rem;
		line-height: $btn-ui_height + 0.75rem;
		margin-left: 0;
		position: absolute;
		right: 0;
		width: $btn-ui_height + 0.75rem;
		z-index: 1;

		[class*="icon-"] {
			font-size: rem-calc(20);
		}
	}
}
