.nav-location {
	.btn-toggle {
		--bs-btn-color: var(--bs-primary);
		border: $btn-toggle-border;
		border-color: transparent;
		display: flex;
		align-items: center;
		// font-size: rem-calc(12);
		line-height: 1.25;
		padding: rem-calc(12) rem-calc(10);
		flex-direction: column;
		@include media("screen", ">=desktop") {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
		}

		&:hover,
		&:focus {
			border-color: transparent;
			// color: $blue-middle;
			color: $btn-toggle-color;
		}
		&:focus {
			box-shadow: none;
		}
		&.show,
		&.active,
		&:active {
			// color: $blue-middle;
			color: $btn-toggle-color;
		}

		span {
			display: inherit;
			text-align: left;
			width: 100%;

			&:first-child {
				font-size: rem-calc(14);
				font-weight: 300;
			}

			[class*="icon-chevron-"] {
				font-size: rem-calc(10);
			}
		}

		.fal,
		.far,
		.fas {
			display: block;
			margin: 0;
			@include media("screen", ">=desktop") {
				display: inline-block;
				vertical-align: middle;
			}
		}

		// .fa-database {
		// 	font-size: rem-calc(14);
		// }

		.fa-bars {
			font-size: rem-calc(24);
		}
	}

	.dropdown-menu {
		box-shadow: $box-shadow;
		margin-left: rem-calc(1);
		padding: 0;
		width: rem-calc(280); // 280px
		@include media("screen", ">=desktop") {
			width: rem-calc(760); // 760px
		}
		&::before {
			@include triangle(22px, $gray-300, up);
			content: "";
			position: absolute;
			left: auto;
			right: 0;
			top: rem-calc(-12);
			@include media("screen", ">=desktop") {
				right: rem-calc(39);
			}
		}
		&::after {
			@include triangle(20px, $white, up);
			content: "";
			position: absolute;
			left: auto;
			right: rem-calc(1);
			top: rem-calc(-10);
			@include media("screen", ">=desktop") {
				right: rem-calc(40);
			}
		}
	}
}
