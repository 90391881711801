.toolbar {
	clear: both;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	@include media("screen", "<phone") {
		background-color: $gray-100;
		margin: 0 rem-calc(-15) 0 rem-calc(-15);
		padding: rem-calc(5) rem-calc(15);
	}
	@include media("screen", ">=phone") {
		border-bottom: 0.0625rem solid $gray-400;
		// padding: rem-calc(10) 0;
		padding: rem-calc(5) 0;
		transition: border-bottom 0.15s ease-in-out;
	}

	&-holder {
		display: flex;
		align-items: center;
	}

	&-nav-btn {
		color: $gray-800;
		display: inline-flex;
		align-items: center;
		font-size: rem-calc(14);
		height: rem-calc(36);
		text-transform: uppercase;
		@include media("screen", "<phone") {
			width: rem-calc(36);

			span {
				display: none;
			}

			*[class*="icon-"] {
				font-size: rem-calc(20);
			}
		}
		&:hover,
		&:focus {
			color: $primary;
		}

		&.first {
			@include media("screen", ">=512px") {
				margin-right: 1.5rem;
			}
		}
	}

	&-func-btn {
		border: 0.0625rem solid transparent;
		border-radius: 50%;
		color: $gray-800;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		font-size: rem-calc(20);
		height: rem-calc(36);
		outline: none;
		position: relative;
		width: rem-calc(36);
		&:not(:last-child) {
			margin-right: rem-calc(3);
		}
		// &.is-postreader {
		// 	color: $gray-600;
		// 	&.is-active,
		// 	&:hover,
		// 	&:focus {
		// 		color: $primary;
		// 	}
		// }

		&.is-active,
		&:hover,
		&:focus {
			color: $primary;
		}

		&.is-active:not(.is-favorite),
		&[aria-expanded="true"] {
			border-color: $primary;

			& > span > [class*="icon-"] {
				color: $primary;
			}
		}

		& > span {
			display: inherit;
			align-items: inherit;
			justify-content: inherit;
			height: inherit;
			width: inherit;
		}

		&.lexinform {
			@include media("screen", "<desktop") {
				display: none;
			}
		}
	}

	.dropdown-menu {
		&::before,
		&::after {
			content: none;
		}
	}

	.dropdown-item {
		display: flex;
		line-height: rem-calc(22);
		white-space: nowrap;

		[class*="icon-"] {
			font-size: rem-calc(20);
			margin-right: 0.75rem;
		}
	}
}

.doc-toolbar {
	background-color: $white;
	position: sticky;
	top: rem-calc(-1);
	transition: box-shadow 0.15s ease-in-out;
	width: 100%;
	z-index: 100;
	@include media("screen", ">=desktop") {
		margin-top: rem-calc(9);
	}

	&.is-pinned {
		box-shadow: 0 1rem 1rem -1rem rgba($black, 0.16);

		.toolbar {
			border-bottom: 0.0625rem solid $white;
		}
	}
}

.selection-control {
	position: absolute;
	padding: 0.5rem;
	max-width: 34rem;
	@if $theme-linde {
		background-color: $primary;
		border-color: $primary;
	} @else {
		background-color: $secondary;
		border-color: $secondary;
	}

	.popover-arrow {
		position: absolute;
		&::before,
		&::after {
			@if $theme-linde {
				border-bottom-color: $primary;
			} @else {
				border-bottom-color: $secondary;
			}
		}
		top: -0.5rem;
		left: 50%;
		transform: translateX(-50%);
	}

	// .toolbar-func-btn {
	&--btn {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		color: $white;
		opacity: 0.85;
		padding: 0.5rem;
		&:hover,
		&:focus {
			color: $white;
			opacity: 1;
		}

		[class*="icon-"] {
			font-size: 1.25rem;
		}
	}
}
