.btn-fav {
	align-items: center;

	[class*="icon-"] {
		animation-delay: 0.5s;
		border-radius: 50%;
		height: 1rem;
		width: 1rem;
	}
}

.favorites-list {
	border: 0.0625rem solid $gray-300;
	list-style: none;
	padding: 1rem;

	&--item {
		cursor: pointer;
		margin: rem-calc(4) 0;
		padding: 0.5rem 0.75rem;
		position: relative;
		transition: $transition-base;
		&:hover,
		&:focus {
			background-color: $gray-200;
		}

		&:not(:last-child) {
			&::after {
				content: "";
				background-color: $gray-200;
				bottom: 0;
				display: block;
				height: 0.0625rem;
				left: 0;
				position: absolute;
				width: 100%;
			}
		}

		&.is-select {
			background-color: $gray-200;
			font-weight: bold;
		}
	}
}

.is-favorite {
	&.is-add-fav {
		animation: shadow-pulse-dark 0.8s;
	}
}

// .is-add-print {
// 	animation: shadow-pulse-dark 0.8s;
// }

.fav-image,
.print-image {
	border-radius: 1.25rem;
}
