.tab-pane {
	strong {
		display: block;
		font-size: 1.125rem;
		line-height: 1.25;
		margin-bottom: 0.5rem;
	}

	p {
		font-size: 0.875rem;
		margin-bottom: 0;
	}
}

.tab-pane--img {
	overflow: hidden;
	@include media("screen", ">=desktop") {
		max-height: 24rem;
		margin: 0 rem-calc(15);
		padding: 0;
	}

	img {
		@include media("screen", "<desktop") {
			height: auto;
			width: 100%;
		}
		@include media("screen", ">=desktop") {
			height: 100%;
			width: auto;
		}
		@include media("screen", ">=desktop", "<x-widescreen") {
			transform: translateX(-40%);
		}
		@include media("screen", ">=x-widescreen") {
			position: absolute;
			right: 0;
		}
	}

	&-overlay {
		background-color: $gray-100;
		padding: 1rem 1.25rem;
		@include media("screen", "<desktop") {
			bottom: 0;
			width: 100%;
		}
		@include media("screen", ">=desktop") {
			background-color: rgba($white, 0.9);
			bottom: 1rem;
			margin-left: 1rem;
			padding: 1.5rem 2rem;
			position: absolute;
			right: 1rem;
		}
	}
}

.tab-pane--list {
	padding: 1rem;

	.posts-list .post-title {
		font-size: rem-calc(18);
		margin-bottom: 0;
		padding-right: 0;
	}
}

.nav-pills {
	.nav-link {
		background-color: $nav-pills_bg;
		border: $nav-pills_border;
		margin-left: rem-calc(3);
		margin-right: rem-calc(3);
		margin-bottom: rem-calc(10);
	}
}
