.header-holder {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.header {
	background-color: $header-bg;
	border-bottom: $header-border-bottom;
	display: flex;
	align-items: center;
	height: $header-height_mobile;
	left: 0;
	right: 0;
	position: sticky;
	top: 0;
	z-index: 1015;
	@include media("screen", ">=desktop") {
		height: $header-height_desktop;
		@if $theme-nwb {
			padding-top: $header-margin-top_desktop;
		} @else {
			margin-top: $header-margin-top_desktop;
		}
		top: $header-top_desktop;

		.header-holder {
			height: $header-holder_height;
			position: sticky;
			top: 0;
		}
	}

	&::after {
		@if $theme-nwb {
			background: url("../images/bg-triangles.svg")
				no-repeat
				center -33vh;
		}
		@if $header-bottom-element {
			height: calc(100% + 2rem);
			transition: height 0.25s ease-in-out;
		}
		content: "";
		position: absolute;
		height: 100%;
		top: 0;
		width: 100%;
		z-index: -1;
	}

	&-right {
		display: inherit;
		// @if $theme-linde {
		// 	align-items: flex-start;
		// }
	}

	&-claim {
		font-size: 2rem;
		font-weight: 300;
		@if $theme-nwb {
			margin: 1rem auto 6rem auto;
		} @else {
			margin: 1rem auto 5rem auto;
		}
		text-align: center;
		text-transform: uppercase;
		width: calc(100% - 2rem);
		@include media("screen", ">=desktop") {
			@if $header-claim {
				margin-top: 2rem;
				@if $theme-nwb {
					margin-bottom: 5rem;
				} @else {
					margin-bottom: 4rem;
				}
			} @else {
				margin-top: -1rem;
			}
		}
	}

	@at-root body {
		&.home {
			.header {
				display: block;
				height: auto;
				position: relative;
				top: 0;

				// @if $theme-nwb {
				// 	min-height: rem-calc(420);

				// 	@include media("screen", ">=phone") {
				// 		min-height: rem-calc(586);
				// 	}
				// 	@include media("screen", ">=tablet") {
				// 		min-height: rem-calc(520);
				// 	}
				// 	@include media("screen", ">=desktop") {
				// 		min-height: rem-calc(515);
				// 	}
				// }

				@if $header-bottom-element {
					margin-bottom: 2.5rem;

					&::before {
						border-top: 2rem solid $header-bg-before;
						border-left: 0 solid transparent;
						border-right: 100vw solid transparent;
						height: 0;
						top: 100%;
						transition: border-top 0.25s ease;
						width: 100%;
						z-index: -1;
						@include media("screen", ">=tablet") {
							border-left-width: 23vw;
							border-right-width: 75vw;
							border-radius: 0 0 rem-calc(20) rem-calc(20);
						}
						@include media("screen", ">=desktop", "<widescreen") {
							border-left-width: 21vw;
							border-right-width: 77vw;
						}
						@include media("screen", ">=desktop") {
							border-top-width: 4rem;
						}
					}

					&::after {
						height: calc(100% + 2rem);
						@include media("screen", ">=desktop") {
							height: calc(100% + 4rem);
						}
					}
				}

				@include media("screen", ">=desktop") {
					// height: $header-height_desktop;
					@if $header-bottom-element {
						margin-bottom: 5rem;
					} @else {
						margin-bottom: 2rem;
					}
				}

				&::before {
					content: "";
					position: absolute;
				}
			}

			.header-holder {
				// @if $header-holder_border-bottom {
				// 	margin-bottom: 1rem;
				// }
				height: $header-holder_height_mobile;
				position: relative;
				@include media("screen", ">=desktop") {
					@if $theme-nwb {
						height: $header-holder_height_desktop - 2.25rem;
					} @else {
						height: $header-holder_height_desktop;
					}
				}
			}

			&:not(.loggedout) {
				@if $theme-nwb {
					.header {
						min-height: rem-calc(420);

						@include media("screen", ">=phone") {
							min-height: rem-calc(540);
						}
						@include media("screen", ">=tablet") {
							min-height: rem-calc(445);
						}
						@include media("screen", ">=desktop") {
							min-height: rem-calc(467);
						}
					}
				}
			}
		}
		// &:not(.home) {
		// 	.header-holder {
		// 		border-bottom: $header-holder_border-bottom;
		// 	}
		// }
	}
}

@import "../components/logo";
