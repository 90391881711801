.banner-status {
	background-color: $gray-100;
	border: 0.0625rem solid $gray-300;
	border-radius: 0.25rem;
	margin: 0 auto rem-calc(30) auto;
	width: calc(100% - 1.875rem);
}

.banner-status-img {
	border: 0.125rem solid $cyan;
	border-radius: 50%;
	color: $cyan;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 2rem;
	height: 4rem;
	width: 4rem;
	margin: auto;
}

.banner-status-title {
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 600;
	letter-spacing: 0.0625rem;
	margin: 0 auto;
	position: relative;
	text-transform: uppercase;
	max-width: 24rem;
	&::after {
		content: "";
		background-color: $gray-700;
		height: 0.0625rem;
		position: absolute;
		width: 100%;
		z-index: 0;
	}

	span {
		background-color: $white;
		padding: 1rem;
		max-width: 19rem;
		z-index: 1;
	}
}
