.popover {
	box-shadow: $popover-box-shadow;

	.post-tools--link {
		@if $theme-linde {
			background-color: $blue-lightest;
			color: $primary;
		} @else if $theme-kiehl {
			background-color: $raster4;
			color: $primary;
		} @else {
			background-color: $gray-100;
			color: $primary;
		}
		border-radius: 0 0 $popover-border-radius $popover-border-radius;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		line-height: rem-calc(18);
		margin: 0.625rem 0 0;
		padding: 0.75rem;
		transition: all 0.3s ease-in-out;
		// &:hover,
		// &:focus {
		// 	color: $link-hover-color;
		// 	text-decoration: none;
		// }
		&:hover,
		&:focus {
			// color: $link-hover-color;
			text-decoration: none;
			@if $theme-linde {
				background-color: $blue-middle;
				color: $white;
			} @else if $theme-kiehl {
				background-color: $raster3;
			} @else {
				background-color: $gray-200;
			}
		}
		&:active {
			background-color: $primary;
			color: $white;
			text-decoration: none;
		}

		.icon-copy {
			margin-top: rem-calc(-4);
		}
	}
}
