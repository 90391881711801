.notice {
	// background-color: rgba($cyan, 0.125);
	background-color: rgba($cyan, 0.1);
	border: 0.0625rem solid rgba($cyan, 0.25);
	margin-top: 2rem;
	margin-bottom: 2rem;
	@include media("screen", "<phone") {
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
	}
	@include media("screen", ">=phone") {
		border-radius: $border-radius;
	}
	// padding: rem-calc(30) rem-calc(20);
	padding: rem-calc(24);
	// position: relative;
	// &::before,
	// &::after {
	// 	content: "";
	// 	height: rem-calc(30);
	// 	position: absolute;
	// 	width: 100%;
	// }
	// &::before {
	// 	bottom: 100%;
	// 	background: linear-gradient(
	// 		to left top,
	// 		rgba($cyan, 0.125) 50%,
	// 		transparent 50%
	// 	);
	// 	left: 0;
	// }
	// &::after {
	// 	top: 100%;
	// 	background: linear-gradient(
	// 		to left bottom,
	// 		rgba($cyan, 0.125) 50%,
	// 		transparent 50%
	// 	);
	// 	right: 0;
	// }
	&.light {
		background-color: $gray-100;
		border: 0.0625rem solid $gray-300;
		// &::before {
		// 	background: linear-gradient(
		// 		to right top,
		// 		$gray-100 50%,
		// 		transparent 50%
		// 	);
		// 	right: 0;
		// }
		// &::after {
		// 	background: linear-gradient(
		// 		to right bottom,
		// 		$gray-100 50%,
		// 		transparent 50%
		// 	);
		// 	left: 0;
		// }

		.notice-icon {
			@if $theme-sis {
				color: var(--bs-primary);
			} @else {
				color: $gray-700;
			}

			[class*="icon-"]:not(.icon-x1):not(.icon-x2):not(.icon-x3):not(.icon-x4):not(.icon-x5) {
				font-size: 3rem;
				margin-top: -0.5rem;
			}
		}

		// .notice-title {
		// 	font-size: 1.25rem;
		// 	line-height: 1.25;
		// }

		.notice-txt {
			color: $gray-900;
		}
	}

	&-icon {
		// @if $theme-linde {
		// 	color: $blue;
		// }
		@if $theme-sis {
			color: var(--bs-primary);
		}
		font-size: rem-calc(24);
		margin-right: rem-calc(15);

		// [class^="icon-"],
		// [class*="icon-"] {
		// 	vertical-align: top;
		// }
	}

	&-title {
		@if $theme-kiehl {
			font-size: 1.25rem;
			line-height: 1.25;
		} @else {
			font-size: $h3-font-size;
		}
		@if $theme-sis {
			color: var(--bs-primary);
		}
		font-weight: 600;
		display: flex;
		margin-bottom: rem-calc(8);
		hyphens: auto;
	}

	&-content {
		// max-width: calc(100% - 2.5rem);
		width: calc(100% - 2.5rem);
	}

	&-info {
		@if $theme-linde {
			color: $blue;
		}
		margin-left: auto;
	}
}
