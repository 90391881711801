.logo {
	display: block;
	// @if $enable-theme-options {
	// 	margin-bottom: rem-calc(15); // Muss noch korrigiert werden
	// }
	// position: relative;
	text-decoration: none;
	// transition: margin-top 0.3s;
	@include media("screen", ">=desktop") {
		// margin-top: 1.9375rem;
		@if $theme-linde {
			margin-right: 1rem;
		}
	}
	@include media("screen", ">=widescreen") {
		max-width: 20rem;
	}
	&:hover,
	&:focus {
		text-decoration: inherit;
	}

	img {
		height: $logo-height;
		// transition: height 0.4s ease-in-out;
		@include media("screen", ">=desktop") {
			@if $theme-nwb {
				height: $logo-height + 0.25rem;
			} @else {
				height: $logo-height + 1.25rem;
			}
		}
		@include media("screen", ">=widescreen") {
			height: $logo-height-lg;
		}
	}
}

// .home {
// 	.logo {
// 		// @include media("screen", ">=desktop") {
// 		// 	margin-top: rem-calc(15);
// 		// }
// 		@include media("screen", ">=widescreen") {
// 			margin-top: rem-calc(26);
// 		}
// 	}
// }
