.toast-custom {
	@include animate(all, 1s, ease-in-out);
	background-color: $toast_info_bg;
	border-color: $toast_info_border-color;
	border-radius: $toast_info_border-radius;
	box-shadow: $toast_info_shadow;
	opacity: 0;
	position: fixed;
	width: auto;
	z-index: 1100;

	&.show {
		opacity: 1;
	}

	&.bottom-right {
		bottom: rem-calc(65);
		right: -100%;
		@include media("screen", ">=tablet") {
			bottom: rem-calc(75);
		}
		@include media("screen", ">=desktop") {
			bottom: rem-calc(40);
		}

		&.show {
			right: rem-calc(15);
			@include media("screen", ">=desktop") {
				right: rem-calc(85);
			}
		}
	}

	&.top-center {
		left: 50%;
		top: -100%;
		transform: translateX(-50%);

		&.show {
			top: 7rem;
			@include media("screen", ">=desktop") {
				top: 10rem;
			}
		}
	}

	.toast-body {
		color: $toast_info_color;
		display: flex;
		align-items: center;
		font-weight: 600;
		padding: rem-calc(5) rem-calc(15);
		white-space: nowrap;
		@include media("screen", ">=tablet") {
			padding: rem-calc(10) rem-calc(15);
		}
	}
}

// System messages
#toastContainer {
	max-height: 100vh;
	overflow-y: auto;
	top: 0;
	right: 0;
	/* Hide scrollbar in Firefox */
	scrollbar-width: none;
	/* Render in front of Bootstrap toasts */
	z-index: 1080;

	/* Force toast container to top center on mobile. */
	@include media("screen", "<phone") {
		width: 100%;
		top: 0;
		bottom: auto;
		left: 0;
		transform: none;
	}
	@include media("screen", ">=phone") {
		bottom: 0;
	}

	::-webkit-scrollbar {
		display: none;
	}
}

.toast {
	margin: 0.8rem auto;
	@include media("screen", ">=phone") {
		margin: 1rem 0.75rem;
	}

	.toast-header {
		.close:not([data-bs-dismiss="alert"]) {
			margin: 0;
			padding: 0.5rem;
		}
	}
}

[class*="toast-status"]:not(.toast-status-icon) {
	border-top-width: rem-calc(6);
	border-top-style: solid;
	min-width: rem-calc(350);
}

.toast-status-icon {
	font-size: 1.1rem;
}

// Toast success
.toast-status-success {
	border-top-color: $success;

	.toast-header,
	.toast-progress-bar div {
		background-color: rgba($success, 0.2);
	}

	.toast-title {
		color: darken($success, 3%);
	}

	.toast-body,
	.toast-progress-bar {
		background-color: rgba($success, 0.06);
	}
}

// Toast info
.toast-status-info {
	@if $theme-kiehl {
		border-top-color: $raster1;
	} @else {
		border-top-color: $info;
	}

	.toast-header,
	.toast-progress-bar div {
		@if $theme-kiehl {
			background-color: $raster3;
		} @else {
			background-color: rgba($info, 0.2);
		}
	}

	.toast-title {
		@if $theme-kiehl {
			color: $raster1;
		} @else {
			color: darken($info, 3%);
		}
	}

	.toast-body,
	.toast-progress-bar {
		@if $theme-kiehl {
			background-color: $info;
		} @else {
			background-color: rgba($info, 0.06);
		}
	}

	.toast-status-icon {
		@if $theme-kiehl {
			--bs-info-rgb: #{$raster1};
		}
	}
}

// Toast warning
.toast-status-warning {
	border-top-color: $warning;

	.toast-header,
	.toast-progress-bar div {
		background-color: rgba($warning, 0.2);
	}

	.toast-title {
		color: darken($warning, 3%);
	}

	.toast-body,
	.toast-progress-bar {
		background-color: rgba($warning, 0.06);
	}
}

// Toast error
.toast-status-error {
	border-top-color: $danger;

	.toast-header,
	.toast-progress-bar div {
		background-color: rgba($danger, 0.2);
	}

	.toast-title {
		color: darken($danger, 3%);
	}

	.toast-body,
	.toast-progress-bar {
		background-color: rgba($danger, 0.06);
	}
}

@keyframes toast-progress-bar-keyframes {
	to {
		/* More performant than animating `width` */
		transform: scaleX(0);
	}
}

.toast-progress-bar {
	div {
		height: rem-calc(5);
		animation: toast-progress-bar-keyframes calc(var(--duration) * 1s)
			linear forwards;
		transform-origin: left center;
	}
}
