.header-btn {
	border: 0;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	align-self: center;
	height: auto;
	@if $theme-linde {
		--bs-btn-color: var(--bs-primary);

		@include media("screen", "<phone") {
			padding-left: rem-calc(5);
			padding-right: rem-calc(5);
		}
		@include media("screen", ">=phone") {
			padding-left: rem-calc(8);
			padding-right: rem-calc(8);
		}
	} @else {
		padding-left: rem-calc(10);
		padding-right: rem-calc(10);
	}
	&:hover,
	&:focus {
		box-shadow: $header-btn_box-shadow;
		color: $header-btn_color_hover;
	}
	// &:focus {
	// 	box-shadow: $header-btn_box-shadow;

	// 	@if $theme-linde {
	// 		color: $header-btn_color;
	// 	} @else {
	// 		color: $header-btn_color_hover;
	// 	}
	// }
	// &:active {
	// 	@if $theme-linde {
	// 		color: $blue-middle !important;
	// 	}
	// }

	& > span[data-bs-toggle="tooltip"] {
		display: flex;
		flex-direction: column;
	}

	[class*="icon-"] {
		color: $header-btn_icon_color;
	}

	.far,
	.fal,
	.fas {
		font-size: rem-calc(24);
		// @include media("screen", "<desktop") {
		// 	font-size: rem-calc(24);
		// }
		// @include media("screen", ">=desktop") {
		// 	font-size: rem-calc(28);
		// }
	}
}

.header-btn--desc {
	color: $header-btn_color;
	display: none;
	@if $theme-linde {
		// font-size: rem-calc(10);
		font-size: rem-calc(12);
	} @else {
		font-size: rem-calc(10);
	}
	@if $theme-sis {
		font-weight: 400;
	} @else if $theme-linde {
		font-weight: 500;
		margin-top: rem-calc(5);
	} @else {
		font-weight: 600;
		margin-top: rem-calc(3);
	}
	text-transform: uppercase;
	@include media("screen", ">=desktop") {
		display: inline-block;
	}
}
