// Magazine Sidebar (Toolbar for mobile)
.magazine-toolbar {
	// @include media("screen", "<desktop") {
	// 	background: $gradient-gray-dark-bottom;
	// 	border-top: 0.0625rem solid $gray-300;
	// 	bottom: 0;
	// 	box-shadow: $footer-shadow;
	// 	height: rem-calc(52);
	// 	left: 0;
	// 	position: fixed;
	// 	right: 0;
	// 	width: 100%;
	// 	z-index: 99;
	// }
	// @include media("screen", ">=tablet", "<desktop") {
	// 	background-color: $gray-100;
	// 	bottom: 0;
	// 	height: rem-calc(63);
	// }

	&--number {
		@include media("screen", "<desktop") {
			margin-top: rem-calc(-10);
			margin-bottom: rem-calc(20);
			position: relative;
			text-align: right;
			&::before {
				content: "";
				background-color: $gray-400;
				height: rem-calc(1);
				left: 0;
				position: absolute;
				top: 50%;
				width: calc(100% - 11rem);
				z-index: -1;
			}
		}
		@include media("screen", ">=desktop") {
			margin-top: rem-calc(20);
		}
	}

	// &--list {
	// 	@include media("screen", "<desktop") {
	// 		background: $gradient-gray-dark-bottom;
	// 		border-top: 0.0625rem solid $gray-300;
	// 		bottom: 0;
	// 		box-shadow: $footer-shadow;
	// 		display: flex;
	// 		// height: 100%;
	// 		height: rem-calc(52);
	// 		left: 0;
	// 		margin-bottom: 0;
	// 		position: fixed;
	// 		right: 0;
	// 		width: 100%;
	// 		z-index: 99;
	// 	}
	// 	@include media("screen", ">=tablet", "<desktop") {
	// 		// background-color: $gray-100;
	// 		bottom: 0;
	// 		height: rem-calc(63);
	// 	}
	// }

	&--item {
		position: relative;
		@include media("screen", "<desktop") {
			&:not(.pdf-download) {
				display: none;
			}

			&.pdf-download {
				// bottom: 1rem;
				// position: fixed;
				// right: 0.5rem;
				// z-index: 91;

				a {
					animation: shadow-pulse 2s 3;
					background-color: $magazine-toolbar--item_mobile_bg;
					border-radius: 3.375rem;
					box-shadow: $box-shadow;
					// bottom: 0;
					bottom: 1rem;
					color: $magazine-toolbar--item_mobile_color;
					display: block;
					height: 3.375rem;
					line-height: 3.5rem;
					overflow: hidden;
					// position: absolute;
					position: fixed;
					// right: 3.75rem;
					right: 4.25rem;
					width: 3.375rem;
					z-index: 91;

					& > i {
						border: 0 solid transparent;
						border-radius: 3.375rem;
						display: flex;
						align-items: center;
						justify-content: center;
						float: right;
						height: 3.375rem;
						line-height: 3.5rem;
						margin-left: 0;
						right: 0;
						transition: all 0.4s ease-in-out;
						width: 3.375rem;
						z-index: 1;
					}

					& > span {
						transition: opacity 0.3s ease-in-out,
							width 0.3s ease-in-out;
						font-weight: 600;
						font-size: 0.75rem;
						height: 100%;
						opacity: 0;
						padding: 0 0 0 1.25rem;
						text-transform: uppercase;
						white-space: nowrap;
						width: 0;
					}

					&:hover,
					&.is-active {
						width: 11.5rem;

						& > i {
							background-color: $magazine-toolbar--item_mobile_bg-hover;
							color: $magazine-toolbar--item_mobile_color-hover;
						}

						& > span {
							opacity: 1;
							width: 100%;
						}
					}

					&:active {
						box-shadow: 0 0 0 0.25rem rgba($secondary, 0.5);
					}

					&.is-active {
						right: 0.5rem;
					}
				}
			}
		}
		@include media("screen", ">=desktop") {
			&:first-child {
				margin-top: rem-calc(40);
			}
			@if $magazine-toolbar--item_border {
				margin-top: rem-calc(10);
			} @else {
				margin-top: rem-calc(20);
			}
		}

		a {
			display: flex;
			align-items: center;
			font-size: rem-calc(9);
			line-height: 1.25;
			text-transform: uppercase;
			@include media("screen", ">=phone") {
				font-size: rem-calc(11);
			}
			@include media("screen", ">=desktop") {
				// &:not(:hover):not(:focus) {
				background-color: $magazine-toolbar--item_bg;
				color: $magazine-toolbar--item_color;
				border: $magazine-toolbar--item_border;
				border-radius: $magazine-toolbar--item_border-radius;
				padding: $magazine-toolbar--item_padding;
				// }
				&:hover,
				&:focus {
					background-color: $magazine-toolbar--item_bg-hover;
					color: $magazine-toolbar--item_color-hover;
				}
			}
		}

		// .rounded-pill {
		// 	font-size: rem-calc(10);
		// 	font-weight: 700;
		// 	line-height: 1.5;
		// 	margin-left: rem-calc(3);
		// 	position: absolute;
		// 	right: 50%;
		// 	text-transform: uppercase;
		// 	top: 0;
		// 	transform: translate(-15px, 10px);
		// 	@include media("screen", ">=phone", "<desktop") {
		// 		transform: translate(-18px, 14px);
		// 	}
		// }

		*[class^="icon-"] {
			font-size: rem-calc(16);
			margin-bottom: rem-calc(5);
			@include media("screen", ">=phone", "<desktop") {
				margin-bottom: rem-calc(7);
			}
			@include media("screen", ">=phone") {
				font-size: rem-calc(18);
			}
			@include media("screen", ">=desktop") {
				margin-bottom: 0;
				margin-right: rem-calc(10);
			}
		}
	}

	.dropdown-menu {
		min-width: 6rem;
		width: 6rem;
	}
}
