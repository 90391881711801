.toolbar-tooltip {
	@if $enable-theme-options {
		background-color: $cyan;
	} @else {
		background-color: $primary;
	}
	border-radius: rem-calc(6);
	box-shadow: $box-shadow;
	color: $white;
	font-size: rem-calc(14);
	height: auto;
	top: 105%;
	left: rem-calc(-90);
	padding: rem-calc(10) rem-calc(30) rem-calc(10) rem-calc(50);
	position: absolute;
	text-align: center;
	width: rem-calc(230);
	z-index: 100;
	&::before {
		content: "";
		height: 0;
		width: 0;
		@if $enable-theme-options {
			border-bottom: rem-calc(6) solid $cyan;
		} @else {
			border-bottom: rem-calc(6) solid $primary;
		}
		border-left: rem-calc(6) solid transparent;
		border-right: rem-calc(6) solid transparent;
		position: absolute;
		left: calc(50% - 0.9375rem);
		top: rem-calc(-6);
	}

	&--badge {
		@if $enable-theme-options {
			background-color: $white;
			color: $cyan;
		} @else {
			background-color: $secondary;
			color: $white;
		}
		border-radius: rem-calc(8);
		top: rem-calc(14);
		font-size: rem-calc(9);
		font-weight: 700;
		padding: 0.0625rem rem-calc(6);
		position: absolute;
		left: 0.5rem;
		text-transform: uppercase;
	}

	&--close {
		color: inherit;
		font-size: rem-calc(14);
		line-height: rem-calc(40);
		position: absolute;
		height: rem-calc(40);
		width: rem-calc(40);
		top: 0;
		right: 0;
		&:hover,
		&:focus {
			opacity: 0.75;
		}
	}
}
