.is-pinned {
	&.header {
		@include media("screen", ">=desktop") {
			line-height: 72px;
		}
	}

	.header-claim,
	.teasers-small {
		display: none;
	}

	.search {
		@include media("screen", "<desktop") {
			position: fixed;
			top: -7.25rem;
		}
		@include media("screen", ">=desktop") {
			left: 0;
			position: relative;
			top: 0;
			transform: translateX(0);
		}
	}
}
