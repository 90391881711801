.editors-choice {
	display: flex;
	min-height: rem-calc(100);
	@include media("screen", "<tablet") {
		margin-bottom: 1rem;
	}

	&-img {
		display: inherit;
		align-items: center;
		height: rem-calc(85);
		overflow: hidden;
		min-width: rem-calc(85);
		width: rem-calc(85);

		img {
			height: auto;
			margin: -0.0625rem;
			min-width: calc(100% + 0.125rem);
			max-width: calc(100% + 0.125rem);
		}
	}

	&-txt {
		// font-size: $font-size-sm;

		p {
			hyphens: auto;
		}
	}
}
