.btn-highlight {
	background-color: $brand-color;
	border: 0;
	border-radius: 0.3125rem;
	color: $gray-900;
	align-items: center;
	font-size: rem-calc(13);
	padding: 0.4375rem 2rem 0.4375rem 0.625rem;
	position: relative;
	&:hover,
	&:focus {
		color: inherit;
		box-shadow: $box-shadow;
	}

	&::before {
		content: "";
		border-right: 0.125rem solid $white;
		height: 100%;
		left: 4.0625rem;
		position: absolute;
	}

	&-group {
		display: flex;
		flex-direction: column;
		margin-left: 1.25rem;
		text-align: left;
	}

	[class*="icon-"] {
		background-color: $white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 1.5rem;
		margin-right: 0.625rem;
		position: relative;
		height: 2.75rem;
		width: 2.75rem;
	}
}
