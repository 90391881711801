.filter-btn {
    position: fixed;
    right: rem-calc(10);
    top: rem-calc(110);
    z-index: 80; //10;
}

.nav-filter--btn {
    background-color: $brand-color;
    line-height: rem-calc(36);
    height: rem-calc(36);
    width: rem-calc(36);
    padding: 0;
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba($brand-color,.35)
    }
}