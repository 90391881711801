.post-view {
	background-color: $post-view_bg;
	border: 0.25rem solid $white;
	border-radius: $post-view_border-radius;
	display: inline-flex;
	align-items: center;
	line-height: 1;
	margin: {
		left: auto;
		right: auto;
	}
	// outline: 0.125rem solid $post-view_bg;
	padding: $post-view_padding;
	position: relative;
	// @include media("screen", "<phone") {
	@include media("screen", "<tablet") {
		justify-content: center;
		margin-top: 0.5rem;

		&::after {
			background-color: $post-view_divider_bg;
			content: "";
			height: 0.0625rem;
			position: absolute;
			width: calc(100vw - 2rem);
			z-index: -1;
		}
	}
	@include media("screen", ">=tablet") {
		// justify-content: flex-end;
		margin-right: 0;
	}

	&-title {
		color: $post-view_title_color;
		font-size: rem-calc(11);
		font-weight: $post-view_title_font-weight;
		text-transform: uppercase;
		// @include media("screen", ">=phone", "<tablet") {
		// //	@include media("screen", "<600px") {
		// 	display: none;
		// }
	}

	&-btn {
		cursor: pointer;

		&.is-active {
			[class*="icon-"] {
				color: $post-view_icon_color-active;
			}
		}
	}

	// &:not(.post-view-lib) {
	// 	.post-view-title {
	// 		@include media("screen", "<600px") {
	// 			display: none;
	// 		}
	// 	}
	// }

	[class*="icon-"] {
		color: $post-view_icon_color;
		font-size: $post-view_icon_size;
		transition: $transition-base;
		// @include media("screen", ">=tablet") {
		// 	font-size: $post-view_icon_size + 0.5rem;
		// }
	}
}
