.headline {
	margin-bottom: 2rem;
	@include media("screen", ">=widescreen") {
		margin-bottom: 2.5rem;
	}

	&-top,
	&-featured {
		position: relative;
		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			bottom: rem-calc(-12); //-0.75rem;
			left: rem-calc(50);
		}
		&::after {
			bottom: rem-calc(-10);
			left: rem-calc(50);
		}
	}

	&-top {
		font-size: $h2-font-size;
		border-bottom: rem-calc(1) solid $headline_line-color;
		margin-bottom: rem-calc(30);
		padding-bottom: rem-calc(30);
		&::before {
			@include triangle(1.5rem, $headline_line-color, down);
		}
		&::after {
			@include triangle(1.5rem, $white, down);
		}
	}

	&-featured {
		border-bottom: rem-calc(1) solid $headline-featured_line-color;
		color: $primary;
		display: flex;
		align-items: center;
		margin-bottom: rem-calc(20);
		padding-bottom: rem-calc(10);
		&::before {
			@include triangle(1.5rem, $headline-featured_line-color, down);
		}
		&::after {
			@include triangle(1.5rem, $headline-featured_line-bg, down);
		}

		[class*="icon-"] {
			background-color: $primary;
			border-radius: 50%;
			color: $headline-featured_line-bg;
			display: inherit;
			align-items: inherit;
			justify-content: center;
			font-size: rem-calc(24);
			height: rem-calc(40);
			margin-right: rem-calc(10);
			padding: rem-calc(10);
			width: rem-calc(40);
		}
	}

	.btn-lg {
		color: $primary;
	}

	&-filter {
		display: inline-flex;
		align-items: center;
		justify-content: inherit;
		@include media("screen", "<420px") {
			font-size: $h6-font-size;
		}
		@include media("screen", ">=420px", "<phone") {
			font-size: calc(#{$h6-font-size} + 0.5vw);
		}
		@include media("screen", "<phone") {
			text-align: center;
		}

		.dropdown-menu {
			@include media("screen", "<phone") {
				margin-left: -12rem;
			}
		}
	}
}

.subtitle {
	color: $gray-600;
	display: block;
	font-size: calc(0.475rem + 0.75vw);
	margin-top: rem-calc(-1);
	text-transform: uppercase;
	word-spacing: rem-calc(4);
	@include media("screen", ">=411px") {
		font-size: calc(0.465rem + 0.75vw);
	}
	@include media("screen", ">=562px") {
		font-size: calc(0.45rem + 0.75vw);
	}
	@include media("screen", ">=tablet") {
		font-size: calc(0.425rem + 0.75vw);
	}
	@include media("screen", ">=desktop") {
		font-size: calc(0.395rem + 0.75vw);
	}
	@include media("screen", ">=widescreen") {
		font-size: rem-calc(13.5);
		letter-spacing: rem-calc(1);
	}
}

.bg-light {
	.headline-top {
		border-bottom-color: $headline-featured_line-color;
		&::before {
			border-top-color: $headline-featured_line-color;
		}
		&::after {
			left: rem-calc(52);
		}
	}
}

.bg-gray-light {
	.headline-featured {
		&::after {
			border-top-color: $gray-200;
		}
	}
}
