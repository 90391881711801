.book {
	display: flex;
	position: relative;

	&-link {
		&:hover,
		&:focus {
			.book-cover > img {
				box-shadow: $box-shadow;
			}
		}
	}

	&-cover {
		transition: box-shadow 0.3s ease-in-out;
		margin: {
			left: auto;
			right: auto;
		}
		width: rem-calc(144);
		// overflow: hidden;
		@include media("screen", ">=411px") {
			width: rem-calc(160);
		}

		& > img {
			box-shadow: $box-shadow-sm;
			@if $theme-linde {
				box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
			}
			transition: box-shadow 0.3s ease-in-out;
			height: 100%;
			width: 100%;
		}
	}

	&-content {
		overflow-wrap: break-word;
		hyphens: auto;

		em {
			display: block;
		}
	}

	&-title:not(.no-childs) {
		// color: $teaser-book_color;

		* {
			transition: $transition-base;
			// word-break: break-word;
			// @include media("screen", "<phone") {
			// 	font-size: 1.25rem;
			// }
			@include media("screen", ">=widescreen") {
				font-size: 1.125rem;
			}
		}

		&:hover *,
		&:focus * {
			color: $teaser-book_color-hover;
		}
	}

	// &-title {
	// 	word-break: break-word;
	// }

	&-edition {
		color: $gray-700;
		margin: rem-calc(10) 0;

		&-followup {
			background-color: $gray-100;
			border: 0.0625rem solid $gray-300;
			border-radius: $border-radius-lg;
			display: inline-flex;
			align-items: center;
			margin-top: 0.5rem;
			padding: 0.5rem 0.75rem 0.5rem;

			a {
				font-weight: 600;
			}

			& + .book-series-followup {
				padding-left: 1rem;
			}

			@if $theme-kiehl {
				.icon-book-comments {
					@extend .icon-book-k;
				}
			}
		}
	}

	&-series {
		&-followup {
			background-color: $white;
			display: flex;
			align-items: center;
			margin-top: 0.5rem;
			padding: 0.25rem 0;

			@if $theme-kiehl {
				.icon-book {
					@extend .icon-book-k;
				}
			}
		}
	}

	.link {
		z-index: 3;
		@media (hover: hover) {
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.dropdown-menu {
		overflow-x: hidden;
		overflow-y: auto;
		max-height: 19rem;
		min-width: 7rem;
	}

	&.not-owned {
		.book-cover {
			opacity: 0.3;
		}

		.book-content {
			*:not(.btn) {
				opacity: 0.75;
			}
		}

		.btn {
			[class*="icon-"] {
				font-size: rem-calc(14);
			}
		}
	}

	&.comment {
		flex-direction: column;
		text-align: center; // 4 Cards

		.book-cover {
			$book-cover-height: rem-calc(180);
			height: calc(#{$book-cover-height} + 10vw);
			overflow: hidden;
			width: 100%;
			@include media("screen", ">=411px") {
				// $book-cover-height: rem-calc(210);
				height: calc(#{$book-cover-height} + 17vw);
			}
			@include media("screen", ">=480px") {
				// $book-cover-height: rem-calc(200);
				height: calc(#{$book-cover-height} + 25vw);
			}
			// 6 Cards
			// @include media("screen", ">=phone") {
			// 	// $book-cover-height: rem-calc(180);
			// 	height: calc(#{$book-cover-height} + 9vw);
			// }
			// @include media("screen", ">=640px") {
			// 	// $book-cover-height: rem-calc(180);
			// 	height: calc(#{$book-cover-height} + 12vw);
			// }
			// @include media("screen", ">=tablet") {
			// 	// $book-cover-height: rem-calc(180);
			// 	height: calc(#{$book-cover-height} + 18vw);
			// }
			// @include media("screen", ">=desktop") {
			// 	height: rem-calc(184);
			// }
			// @include media("screen", ">=widescreen") {
			// 	height: rem-calc(227);
			// }
			// @include media("screen", ">=x-widescreen") {
			// 	height: rem-calc(248);
			// }

			// 4 Cards
			// @include media("screen", ">=phone") {
			// 	$book-cover-height: rem-calc(111);
			// 	height: calc(#{$book-cover-height} + 9vw);
			// 	width: auto;
			// }
			// @include media("screen", ">=640px") {
			// 	height: calc(#{$book-cover-height} + 11vw);
			// }
			// @include media("screen", ">=tablet") {
			// 	height: calc(#{$book-cover-height} + 18vw);
			// }
			@include media("screen", ">=phone") {
				height: rem-calc(160);
				margin: 0 auto;
				width: rem-calc(112);
			}
			@include media("screen", ">=640px") {
				height: rem-calc(181);
				width: rem-calc(128);
			}
			@include media("screen", ">=tablet") {
				height: rem-calc(227);
				width: rem-calc(160);
			}
			// @include media("screen", ">=desktop") {
			// 	height: rem-calc(227);
			// 	width: rem-calc(160);
			// }
			@include media("screen", ">=widescreen") {
				height: rem-calc(284);
				width: rem-calc(200);
			}
			// @include media("screen", ">=x-widescreen") {
			// 	height: rem-calc(248);
			// }

			img {
				height: auto;
			}
		}
	}
}

body:not(.document) {
	.book-edition-followup,
	.book-series-followup {
		// margin-bottom: rem-calc(24);
		@include media("screen", "<411px") {
			margin-left: -10rem;
			width: calc(100% + 10rem);
		}
		@include media("screen", ">=411px", "<576px") {
			margin-left: -11rem;
			width: calc(100% + 11rem);
		}
	}
}
