.error-page {
    padding-top: 0 !important;
}

.error-page-block {
    @include media("screen", ">=phone") {
        max-width: 75%;
        margin-left: auto;
        margin-right: auto;
    }
}

.error-page-icon {
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: rem-calc(92);
    margin: 1.5rem auto;
    padding: 1rem;
    width: rem-calc(92);
    @include media("screen", ">=phone") {
        margin: 3rem auto;
    }
}
