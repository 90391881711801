.introduction {
	&-author {
		color: $gray-700;
		font-weight: 600;
		font-style: italic;
	}

	&-title {
		color: $gray-700;
		display: block;
	}

	img {
		@include media("screen", ">=phone") {
			margin-left: rem-calc(20);
			max-width: rem-calc(200);
		}
		@include media("screen", ">=x-widescreen") {
			margin-left: rem-calc(30);
			max-width: rem-calc(320);
		}
	}

	.rounded-circle {
		display: flex;
		align-items: center;
		height: rem-calc(200);
		min-width: rem-calc(200);
		width: rem-calc(200);
		overflow: hidden;
		@include media("screen", "<phone") {
			margin: 0 auto;
		}
		@include media("screen", ">=phone") {
			margin-left: rem-calc(30);
		}
		@include media("screen", ">=phone", "<tablet") {
			height: rem-calc(160);
			min-width: rem-calc(160);
			width: rem-calc(160);
		}

		& > img {
			margin-left: 0;
			width: 100%;
		}
	}
}
