.nav-filter {
    overflow-y: auto;
    @include media("screen", "<375px") {
		padding: 0.5rem 1rem;
	}
	@include media("screen", "<desktop") {
		@include animate(all, 0.5s, ease);
		background: $gray-900;
		color: $white;
		cursor: pointer;
		height: 100%;
		opacity: 0;
		position: fixed;
		top: 0;
		z-index: 101;
	}
	@include media("screen", ">=375px", "<desktop") {
		padding: 1.5rem 1.25rem 0.5rem 1.25rem;
	}
	@include media("screen", "<phone") {
		left: -100%;
		width: 100%;
	}
	@include media("screen", ">=phone", "<desktop") {
		left: -25rem;
		width: 25rem;
	}

	&.expanded {
		opacity: 1;
		left: 0;
	}

	ul {
		@include media("screen", "<desktop") {
			list-style: none;
			margin: 0;
			padding: 0;
			width: 100%;
		}
	}

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: rem-calc(38);                
    }

    &--btn-close {
        opacity: 0.75;
        position: absolute;
        right: rem-calc(20);
        top: rem-calc(15);
    }

    .nav-filter-item {
        padding: rem-calc(5) 0;
        &:not(:last-child) {
            border-bottom: rem-calc(1) solid $gray-800;
        }
        
        a:not(.nav-link) {
            color: $white;
            text-transform: uppercase;
        }
        
        .nav-link {
            @include media("screen", "<desktop") {
                border-bottom: 1px dotted rgba($gray-700, .5);
                color: $gray-300;
                display: flex;
                align-items: baseline;
                font-weight: 300;
                padding-top: 0.5rem;
                padding-left: 0;
                padding-bottom: 0.5rem;
                &:hover,
                &:focus {
                    color: $white;
                }

                [class*="icon-"] {
                    color: $gray-700;
                    font-size: rem-calc(8);
                    margin-top: rem-calc(-3);
                    margin-right: rem-calc(10);
                }
            }
        }

        ul {
            .nav-item {
                &:last-child {
                    .nav-link {
                        border-bottom: 0;
                    }
                }
            }
        }
    }

    .btn {
        box-shadow: none;
        color: $gray-500;
        padding-right: 0;
        [class*="icon-"] {
            display: inline-block;
            transition: $transition-base;
        }

        &[aria-expanded="true"] {            
            [class*="icon-"] {
                color: $white;
                transform: rotate(90deg);
            }
        }
    }
}