$icomoon-font-family: "iconfont" !default;
$icomoon-font-path: "fonts" !default;

$icon-academy-alt: unquote('"\\e98f"');
$icon-admin: unquote('"\\e974"');
$icon-admin-alt: unquote('"\\e975"');
$icon-ai: unquote('"\\ea2d"');
$icon-ai-nwb: unquote('"\\ea2f"');
$icon-ai-search: unquote('"\\ea2e"');
$icon-ai-search-nwb: unquote('"\\ea30"');
$icon-alarm-clock: unquote('"\\e973"');
$icon-appeal: unquote('"\\e9ad"');
$icon-arrow-big-down: unquote('"\\e94f"');
$icon-arrow-big-left: unquote('"\\e950"');
$icon-arrow-big-right: unquote('"\\e94d"');
$icon-arrow-big-up: unquote('"\\e951"');
$icon-arrow-down: unquote('"\\e955"');
$icon-arrow-left: unquote('"\\e957"');
$icon-arrow-right: unquote('"\\e94e"');
$icon-arrow-switch: unquote('"\\e9c8"');
$icon-arrow-up: unquote('"\\e958"');
$icon-at-symbol: unquote('"\\ea00"');
$icon-at-symbol-bold: unquote('"\\ea08"');
$icon-audio-video: unquote('"\\e9a0"');
$icon-audio-video-alt: unquote('"\\e9a1"');
$icon-backward: unquote('"\\ea20"');
$icon-balance-scale: unquote('"\\e944"');
$icon-beaker: unquote('"\\e930"');
$icon-bell: unquote('"\\e98a"');
$icon-bell-slash: unquote('"\\e926"');
$icon-bicycle: unquote('"\\e9e8"');
$icon-book: unquote('"\\e931"');
$icon-book-comments: unquote('"\\e99e"');
$icon-book-cover: unquote('"\\ea01"');
$icon-book-ivz: unquote('"\\e993"');
$icon-book-k: unquote('"\\e9c0"');
$icon-book-opened: unquote('"\\e932"');
$icon-book-opened-alt: unquote('"\\e94b"');
$icon-bookmark: unquote('"\\e9df"');
$icon-bookmark-add: unquote('"\\e9e0"');
$icon-bookmark-alt: unquote('"\\e9e1"');
$icon-briefcase: unquote('"\\e990"');
$icon-bus: unquote('"\\e9e9"');
$icon-calculator: unquote('"\\e9af"');
$icon-calendar: unquote('"\\e900"');
$icon-calendar-alt: unquote('"\\e9c9"');
$icon-calendar-empty: unquote('"\\e979"');
$icon-card-bp: unquote('"\\e99c"');
$icon-caret-down: unquote('"\\e916"');
$icon-caret-down-o: unquote('"\\e917"');
$icon-caret-left: unquote('"\\e918"');
$icon-caret-left-o: unquote('"\\e919"');
$icon-caret-right: unquote('"\\e91a"');
$icon-caret-right-o: unquote('"\\e91b"');
$icon-caret-up: unquote('"\\e91c"');
$icon-caret-up-o: unquote('"\\e91d"');
$icon-cart: unquote('"\\e95b"');
$icon-chart: unquote('"\\e9ea"');
$icon-chat: unquote('"\\ea37"');
$icon-chat-add: unquote('"\\ea38"');
$icon-check: unquote('"\\e928"');
$icon-check-o: unquote('"\\e9e4"');
$icon-check-s: unquote('"\\e9e5"');
$icon-check-solid-o: unquote('"\\ea1b"');
$icon-checklist: unquote('"\\e9b2"');
$icon-checklist-conventional: unquote('"\\e95e"');
$icon-checklist-server: unquote('"\\e9b5"');
$icon-chevron-down: unquote('"\\e910"');
$icon-chevron-left: unquote('"\\e911"');
$icon-chevron-right: unquote('"\\e912"');
$icon-chevron-up: unquote('"\\e90f"');
$icon-climate-neutral: unquote('"\\e964"');
$icon-clip: unquote('"\\e9b3"');
$icon-clock: unquote('"\\e972"');
$icon-close: unquote('"\\e91e"');
$icon-comment: unquote('"\\e995"');
$icon-comment-alt: unquote('"\\e996"');
$icon-comments: unquote('"\\e962"');
$icon-comments-m: unquote('"\\e9a3"');
$icon-comments-o: unquote('"\\e963"');
$icon-community: unquote('"\\e92f"');
$icon-compare-vz: unquote('"\\e9a5"');
$icon-compass: unquote('"\\ea26"');
$icon-contract: unquote('"\\e9b0"');
$icon-copy: unquote('"\\e976"');
$icon-dashboard: unquote('"\\e901"');
$icon-database: unquote('"\\e948"');
$icon-database-alt: unquote('"\\e96a"');
$icon-database-help: unquote('"\\e9ae"');
$icon-desktop: unquote('"\\e902"');
$icon-diamond: unquote('"\\e9b6"');
$icon-diary: unquote('"\\e9b7"');
$icon-doc-empty: unquote('"\\ea02"');
$icon-doc-management: unquote('"\\e992"');
$icon-doc-move-alt: unquote('"\\e989"');
$icon-doc-search: unquote('"\\e94c"');
$icon-doc-search-alt: unquote('"\\e988"');
$icon-doc-types: unquote('"\\e9ca"');
$icon-download: unquote('"\\e94a"');
$icon-ebicycle: unquote('"\\e9eb"');
$icon-ebicycle-alt: unquote('"\\e9ec"');
$icon-ebicycle-o: unquote('"\\e9ed"');
$icon-ecar: unquote('"\\e9ee"');
$icon-ecar-alt: unquote('"\\e9ef"');
$icon-ecar-o: unquote('"\\e9f0"');
$icon-editions-distribution: unquote('"\\e9b8"');
$icon-education: unquote('"\\e98e"');
$icon-ellipsis-h: unquote('"\\e9bb"');
$icon-ellipsis-v: unquote('"\\e9bc"');
$icon-excel: unquote('"\\ea13"');
$icon-exe: unquote('"\\ea22"');
$icon-eye: unquote('"\\e947"');
$icon-face-id: unquote('"\\e9d6"');
$icon-facebook: unquote('"\\ea0d"');
$icon-facebook-o: unquote('"\\ea14"');
$icon-family: unquote('"\\e9f1"');
$icon-family-alt: unquote('"\\e9f2"');
$icon-favs: unquote('"\\e9c1"');
$icon-feather: unquote('"\\e949"');
$icon-file-excel: unquote('"\\ea15"');
$icon-file-exe-o: unquote('"\\ea23"');
$icon-file-html: unquote('"\\e904"');
$icon-file-pdf: unquote('"\\e9cb"');
$icon-file-powerpoint: unquote('"\\ea16"');
$icon-file-rss: unquote('"\\e90a"');
$icon-file-word: unquote('"\\e9cc"');
$icon-file-zip-o: unquote('"\\ea24"');
$icon-fill: unquote('"\\e929"');
$icon-filter: unquote('"\\e933"');
$icon-folder: unquote('"\\e97e"');
$icon-folder-add: unquote('"\\e981"');
$icon-folder-open: unquote('"\\e97f"');
$icon-folder-user: unquote('"\\e9cd"');
$icon-folder-user-o: unquote('"\\e9ce"');
$icon-form: unquote('"\\e9b1"');
$icon-forward: unquote('"\\ea21"');
$icon-gavel: unquote('"\\e9d7"');
$icon-grid-lg-o: unquote('"\\e9de"');
$icon-grid-o: unquote('"\\e95d"');
$icon-guide: unquote('"\\e997"');
$icon-hand: unquote('"\\e903"');
$icon-headset: unquote('"\\e9d8"');
$icon-health: unquote('"\\e9f3"');
$icon-heart: unquote('"\\e9f4"');
$icon-home: unquote('"\\e983"');
$icon-home-alt: unquote('"\\e982"');
$icon-image: unquote('"\\e959"');
$icon-images: unquote('"\\e95a"');
$icon-index: unquote('"\\e952"');
$icon-info: unquote('"\\e954"');
$icon-info-alt: unquote('"\\ea03"');
$icon-info-alt-solid: unquote('"\\ea04"');
$icon-info-o: unquote('"\\e956"');
$icon-info-solid: unquote('"\\ea09"');
$icon-info-solid-o: unquote('"\\ea1c"');
$icon-infocenter: unquote('"\\e9bd"');
$icon-infocenter-o: unquote('"\\e9be"');
$icon-instagram: unquote('"\\ea10"');
$icon-invation: unquote('"\\e905"');
$icon-invoice: unquote('"\\e967"');
$icon-job: unquote('"\\e98c"');
$icon-jobs: unquote('"\\e98d"');
$icon-landmark: unquote('"\\e9c2"');
$icon-laptop: unquote('"\\e9f5"');
$icon-laufzettel: unquote('"\\e9b9"');
$icon-laws: unquote('"\\e9a4"');
$icon-leasing-laptop: unquote('"\\ea31"');
$icon-leasing-smartphone: unquote('"\\ea32"');
$icon-license: unquote('"\\e906"');
$icon-lightbulb: unquote('"\\e99a"');
$icon-lightbulb-glow: unquote('"\\e999"');
$icon-lightning: unquote('"\\e934"');
$icon-link: unquote('"\\e97a"');
$icon-link-add: unquote('"\\e97b"');
$icon-linkedin: unquote('"\\ea11"');
$icon-linkedin-o: unquote('"\\ea17"');
$icon-list: unquote('"\\e935"');
$icon-list-o: unquote('"\\e936"');
$icon-lock: unquote('"\\e99b"');
$icon-locked: unquote('"\\e971"');
$icon-login: unquote('"\\e907"');
$icon-logout: unquote('"\\e92a"');
$icon-magazine: unquote('"\\e96b"');
$icon-magazine-alt: unquote('"\\e96c"');
$icon-magazine-k: unquote('"\\e9c3"');
$icon-magazine-opened: unquote('"\\e953"');
$icon-mail: unquote('"\\e91f"');
$icon-mail-open: unquote('"\\e921"');
$icon-map-pin: unquote('"\\e9f6"');
$icon-marked: unquote('"\\e9a6"');
$icon-marker: unquote('"\\e994"');
$icon-medal: unquote('"\\e9e2"');
$icon-medal-o: unquote('"\\e9e3"');
$icon-megaphone: unquote('"\\e937"');
$icon-menu: unquote('"\\e920"');
$icon-message: unquote('"\\e938"');
$icon-microphone: unquote('"\\e95f"');
$icon-minus: unquote('"\\e922"');
$icon-minus-o: unquote('"\\e96d"');
$icon-module-focus: unquote('"\\e942"');
$icon-money: unquote('"\\e908"');
$icon-moneybox: unquote('"\\e9f7"');
$icon-multimedia: unquote('"\\e9a2"');
$icon-multimedia-o: unquote('"\\ea05"');
$icon-multimedia-solid: unquote('"\\ea06"');
$icon-multiple-filter: unquote('"\\e943"');
$icon-muscle: unquote('"\\e9f8"');
$icon-muscle-alt: unquote('"\\e9f9"');
$icon-news: unquote('"\\e939"');
$icon-online-learning: unquote('"\\e9a9"');
$icon-online-shop: unquote('"\\e9c4"');
$icon-online-training: unquote('"\\e9c5"');
$icon-order-alphabetical: unquote('"\\e913"');
$icon-order-numeric: unquote('"\\e914"');
$icon-order-thematic: unquote('"\\e915"');
$icon-page-break: unquote('"\\e9e6"');
$icon-paper-plane: unquote('"\\e93d"');
$icon-paragraph: unquote('"\\e99d"');
$icon-paragraph-normal: unquote('"\\ea0a"');
$icon-paragraph-solid: unquote('"\\ea07"');
$icon-party: unquote('"\\e9fa"');
$icon-pause: unquote('"\\e9c6"');
$icon-pdf: unquote('"\\ea18"');
$icon-pencil: unquote('"\\e96f"');
$icon-people: unquote('"\\e980"');
$icon-phone: unquote('"\\e961"');
$icon-pin: unquote('"\\e93a"');
$icon-pin-solid: unquote('"\\ea39"');
$icon-play: unquote('"\\e9c7"');
$icon-plus: unquote('"\\e924"');
$icon-plus-o: unquote('"\\e96e"');
$icon-pointer: unquote('"\\ea27"');
$icon-powerpoint: unquote('"\\ea19"');
$icon-presentation: unquote('"\\e9b4"');
$icon-print: unquote('"\\e941"');
$icon-print-add: unquote('"\\ea2a"');
$icon-print-list: unquote('"\\ea2c"');
$icon-print-remove: unquote('"\\ea2b"');
$icon-print-solid: unquote('"\\ea29"');
$icon-puzzle: unquote('"\\e93b"');
$icon-puzzles: unquote('"\\e9e7"');
$icon-quick-start: unquote('"\\e9cf"');
$icon-quick-start-alt: unquote('"\\e9d0"');
$icon-quote-alt: unquote('"\\ea3b"');
$icon-quote-o: unquote('"\\ea3c"');
$icon-radar: unquote('"\\e93c"');
$icon-read-mode: unquote('"\\e991"');
$icon-refresh: unquote('"\\e970"');
$icon-reg: unquote('"\\e909"');
$icon-regulations: unquote('"\\e9d9"');
$icon-regulations-alt: unquote('"\\e9da"');
$icon-remove: unquote('"\\e97d"');
$icon-repley: unquote('"\\ea3a"');
$icon-rocket: unquote('"\\e9ba"');
$icon-rss-o: unquote('"\\e925"');
$icon-save-o: unquote('"\\e98b"');
$icon-search: unquote('"\\e927"');
$icon-seminar: unquote('"\\e968"');
$icon-seminar-alt: unquote('"\\e969"');
$icon-send: unquote('"\\e90b"');
$icon-settings: unquote('"\\e90c"');
$icon-share: unquote('"\\e945"');
$icon-share-alt: unquote('"\\e946"');
$icon-share-o: unquote('"\\e9d3"');
$icon-shopping-cart: unquote('"\\e95c"');
$icon-smartphone: unquote('"\\e9fb"');
$icon-sponsorship: unquote('"\\e9fc"');
$icon-star: unquote('"\\e93e"');
$icon-star-o: unquote('"\\e93f"');
$icon-star-search: unquote('"\\e9fd"');
$icon-star-search-o: unquote('"\\e9fe"');
$icon-stb-scout: unquote('"\\e99f"');
$icon-sustainability: unquote('"\\e9d4"');
$icon-table: unquote('"\\e9ab"');
$icon-table-alt: unquote('"\\e9ac"');
$icon-tax-policies: unquote('"\\e9db"');
$icon-theme: unquote('"\\e940"');
$icon-themes: unquote('"\\e9d1"');
$icon-themes-alt: unquote('"\\e9d2"');
$icon-thumb: unquote('"\\e9bf"');
$icon-times-o: unquote('"\\ea1d"');
$icon-times-solid-o: unquote('"\\ea1e"');
$icon-tools: unquote('"\\e9a7"');
$icon-topics: unquote('"\\e90d"');
$icon-touch-id: unquote('"\\e9dc"');
$icon-touch-id-o: unquote('"\\e9dd"');
$icon-train: unquote('"\\e9ff"');
$icon-trash: unquote('"\\e92c"');
$icon-trash-full: unquote('"\\e92d"');
$icon-twitter: unquote('"\\ea0f"');
$icon-twitter-o: unquote('"\\ea0e"');
$icon-twitter-x: unquote('"\\ea28"');
$icon-type-rss: unquote('"\\e923"');
$icon-unlink: unquote('"\\e97c"');
$icon-unlock: unquote('"\\e9a8"');
$icon-user: unquote('"\\e90e"');
$icon-user-add: unquote('"\\e977"');
$icon-user-alt: unquote('"\\e9d5"');
$icon-user-headset: unquote('"\\ea33"');
$icon-user-m: unquote('"\\e92e"');
$icon-user-remove: unquote('"\\e978"');
$icon-user-w: unquote('"\\e92b"');
$icon-video: unquote('"\\e960"');
$icon-vimeo: unquote('"\\ea34"');
$icon-volume: unquote('"\\e984"');
$icon-volume-down: unquote('"\\e985"');
$icon-volume-mute: unquote('"\\e987"');
$icon-volume-up: unquote('"\\e986"');
$icon-warning-o: unquote('"\\e965"');
$icon-warning-outline: unquote('"\\ea0b"');
$icon-warning-solid: unquote('"\\ea0c"');
$icon-warning-solid-alt: unquote('"\\ea1f"');
$icon-warning-solid-o: unquote('"\\e966"');
$icon-webcam: unquote('"\\e9aa"');
$icon-whatsapp: unquote('"\\ea12"');
$icon-word: unquote('"\\ea1a"');
$icon-work-helpers: unquote('"\\e998"');
$icon-xing: unquote('"\\ea35"');
$icon-youtube: unquote('"\\ea36"');
$icon-zip: unquote('"\\ea25"');

