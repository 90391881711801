// Imported old content from DMS
.ala_heading {
	display: none;
}
// Imported old content from DMS : END

.aside-header {
	border-bottom: rem-calc(1) solid $aside_header_line-color;
	display: flex;
	align-items: center;
	font-size: rem-calc(14);
	line-height: 1.25;
	margin-bottom: rem-calc(20);
	padding-bottom: rem-calc(10);
	position: relative;
	transition: $transition-base;
	&::before,
	&::after {
		content: "";
		position: absolute;
		transition: $transition-base;
	}
	&::before {
		@include triangle(1.5rem, $aside_header_line-color, down);
		bottom: rem-calc(-12);
		left: rem-calc(50);
	}
	&::after {
		@include triangle(1.25rem, $white, down);
		bottom: rem-calc(-10);
		left: rem-calc(52);
	}

	&--head {
		color: $primary;
		font-weight: 600;
		text-transform: uppercase;
	}

	[class*="icon-circle-"] {
		background-color: $primary;
		color: $aside_header_line-bg;
	}

	&[aria-expanded="false"] {
		margin-bottom: 0;
		&::before,
		&::after {
			opacity: 0;
		}

		.headline-featured {
			&::before {
				@include triangle(0, $headline-featured_line-color, down);
				bottom: 0;
			}
			&::after {
				@include triangle(0, $headline-featured_line-bg, down);
				bottom: 0;
			}
		}
	}
	&[aria-expanded="true"] {
		.collapse-status::after {
			transform: rotate(0deg);
			opacity: 0;
		}
	}
}

.collapse-status {
	display: flex;
	align-items: center;
	height: rem-calc(18);
	width: rem-calc(18);
	transition: $transition-base;
	&::before,
	&::after {
		content: "";
		background-color: $primary;
		height: rem-calc(1);
		width: rem-calc(18);
		opacity: 1;
		position: absolute;
		transition: $transition-base;
	}
	&::after {
		transform: rotate(-90deg);
	}
}

.aside-section-card,
.home.bg-light {
	.aside-header {
		&::after {
			border-top-color: $aside_header_line-bg;
		}
	}
}

.home.bg-light {
	.aside-section:not(:first-child) {
		.aside-header {
			padding-top: rem-calc(10);
		}
	}
}
