$badge-hit_bg: $yellow !default;
$badge-hit_color: $primary !default;
$badge-hit_border-radius: 0.3125rem !default; //3px
$badge-hit_font-size: 0.875rem !default; // 14px
$badge-hit_font-weight: 400 !default;
$badge-hit_link-color: $primary !default;
$badge-hit_link-color-hover: $red !default;

// $badge-filter_bg: $yellow !default;
$badge-filter_bg: $blue-lighter !default;
$badge-filter_color: $primary !default;
// $badge-filter_color: $white !default;
$badge-filter_font-size: $font-size-sm !default;
$badge-filter-icon_bg: $white !default;
