// teaser
.teaser-small {
	color: $teaser-small_color;
	display: inline-flex;
	flex-direction: column;
	align-items: center;
	margin: $teaser-small_my $teaser-small_mx;
	position: relative;
	text-align: center;
	// transition: none;
	transition: opacity 0.3s ease-in-out;
	width: $teaser-small_size;
	z-index: 1;
	@if $enable-theme-options {
		@include media("screen", ">=480px") {
			margin: {
				left: calc(#{$teaser-small_mx_sm} - 1.5vw);
				right: calc(#{$teaser-small_mx_sm} - 1.5vw);
			}
		}
		@include media("screen", ">=phone") {
			margin: {
				left: calc(#{$teaser-small_mx_sm} - 1.75vw);
				right: calc(#{$teaser-small_mx_sm} - 1.75vw);
			}
		}
		@include media("screen", ">=tablet") {
			// margin: calc(#{$teaser-small_my} - 1vw) calc(#{$teaser-small_mx} - 3.75vw);
			margin: {
				left: calc(#{$teaser-small_mx_md} - 3.75vw);
				right: calc(#{$teaser-small_mx_md} - 3.75vw);
				// left: calc(#{$teaser-small_mx} - 2.75vw);
				// right: calc(#{$teaser-small_mx} - 2.75vw);
			}
		}
	}
	// @include media("screen", ">=tablet") {
	//     width: $teaser-small_size_desktop;
	//     max-width: $teaser-small_max-size_desktop;
	// }
	@include media("screen", ">=desktop") {
		margin: rem-calc(10) rem-calc(18);
		width: $teaser-small_size_desktop;
		max-width: $teaser-small_max-size_desktop;
		// @if $enable-theme-options {
		//     margin: calc(#{$teaser-small_my} - 1.5vw) calc(#{$teaser-small_mx} - 4.25vw);
		// }
	}

	&-heading {
		// color: $primary;
		// font-family: "FranziskaProBook", serif;
		font-size: 2rem;
		font-weight: 300;
		margin-top: 1.5rem;
		margin-bottom: 0;
		position: relative;
		text-transform: uppercase;
		text-align: center;
		width: 100%;
		z-index: 1;
	}

	// Prepair for animation
	&-item {
		display: none;
		opacity: 0;
		// @include media("screen", "<493px") {
		@include media("screen", "<480px") {
			// Hide all elements within 1,2,3
			&:nth-child(-n + #{$teaser-small_qty}) {
				// 6
				display: flex;
				opacity: 1;
				visibility: visible;
			}
		}
		// @include media("screen", ">=493px", "<phone") {
		@include media("screen", ">=480px", "<phone") {
			// Hide all elements within 1-8
			&:nth-child(-n + #{$teaser-small_qty_xs}) {
				// 8
				display: flex;
				opacity: 1;
				visibility: visible;
			}
		}
		@include media("screen", ">=phone", "<tablet") {
			// Hide all elements within 1-10
			&:nth-child(-n + #{$teaser-small_qty_sm}) {
				// 10
				display: flex;
				opacity: 1;
				visibility: visible;
			}
		}
		@include media("screen", ">=tablet") {
			// Hide all elements within 1-7
			// &:nth-child(-n + 6) {
			&:nth-child(-n + #{$teaser-small_qty_md}) {
				// 7
				display: flex;
				opacity: 1;
				visibility: visible;
			}
		}
		// @include media("screen", ">=desktop") {
		// 	// Hide all elements within 1-7
		// 	// &:nth-child(-n+5) {
		// 	&:nth-child(-n + #{$teaser-small_qty_lg}) {
		// 		// 7
		// 		display: flex;
		// 		opacity: 1;
		// 		visibility: visible;
		// 	}
		// }
		// @include media("screen", ">=widescreen") {
		// Hide all elements within 1,2,3,4,5,6,7
		// &:nth-child(-n + 7) {
		//     display: flex;
		//     opacity: 1;
		//     visibility: visible;
		// }
		// }
		// @include media("screen", ">=x-widescreen") {
		//     // Hide all elements within 1,2,3,4,5,6,7
		//     &:nth-child(-n+7) {
		//         display: flex;
		//         opacity: 1;
		//         visibility: visible;
		//     }
		// }
	}

	&-title {
		display: inline-flex;
		align-items: center;
		@if $theme-kiehl {
			font-size: $teaser-small_font-size;
			@include media("screen", ">=phone") {
				font-size: $teaser-small_font-size + 0.125rem;
			}
			@include media("screen", ">=desktop") {
				font-size: $teaser-small_font-size + 0.25rem;
			}
		} @else {
			font-size: $teaser-small_font-size;
		}
		font-weight: $teaser-small_font-weight;
		letter-spacing: $teaser-small_letter-spacing;
		line-height: 1.25;
		margin-top: rem-calc(10);
		text-transform: uppercase;

		.fal,
		[class*="icon-"] {
			color: $teaser-small-title_icon_color;
			font-size: rem-calc(13);
			margin-left: rem-calc(5);
			margin-bottom: rem-calc(2);
		}
	}

	& > .fal,
	& > [class*="icon-"] {
		color: $teaser-small_icon_color;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: $teaser-small_icon_size;
		height: $teaser-small_icon-container_size;
		width: $teaser-small_icon-container_size;
		position: relative;
		transition: color 0.3s ease-in-out;
		z-index: 1;
		&::after {
			content: "";
			background-color: $teaser-small_bg-color;
			border: $teaser-small_border;
			border-radius: $teaser-small_border-radius;
			height: inherit;
			width: inherit;
			left: 0;
			top: 0;
			position: absolute;
			transition: transform 0.3s ease-in-out;
			z-index: -1;
		}
	}

	&:hover,
	&:focus {
		color: $teaser-small_color_hover;

		.fal,
		[class*="icon-"] {
			color: $teaser-small_icon_color_hover;
			&::after {
				transform: scale(1.1);
			}
		}
	}

	.icon-img {
		@include media("screen", ">=tablet") {
			margin-bottom: $teaser-small_image_gap;
		}
		@include media("screen", ">=desktop") {
			@if $theme-kiehl {
				@include media("screen", ">=desktop") {
					height: 5.75rem;
					width: 5.75rem;
				}
			} @else {
				height: calc(#{$teaser-small_size} + 1rem);
				width: calc(#{$teaser-small_size} + 1rem);
			}
		}
		& > img {
			height: auto;
			max-width: $teaser-small_image_size;
			@include media("screen", ">=desktop") {
				width: calc(#{$teaser-small_image_size} + 1rem);
			}
		}
	}
}

// teasers container
.teasers-small {
	display: flex;
	justify-content: center;
	margin-left: rem-calc(-15);
	flex-wrap: wrap;
	@include media("screen", ">=tablet") {
		width: $teasers-small_width;
	}
	@if $enable-theme-options {
		@include media("screen", ">=desktop") {
			width: calc(#{$teasers-small_width} + 6rem);
		}
	}

	&-wrapper {
		position: relative;
		overflow: hidden;
		@if $teasers-small_bg-image_before {
			&::before {
				content: "";
				background: $teasers-small_bg-image_before;
				background-size: cover;
				height: 100%;
				position: absolute;
				top: 0;
				width: 100%;
				z-index: 0;
			}
		}
		@if $teasers-small_bg-image {
			// background: $teasers-small_bg $teasers-small_bg-image;
			background-color: $teasers-small_bg;

			&::after {
				// content: "";
				// background: $teasers-small_bg-image;
				// background-size: 50rem;
				// bottom: 0;
				// height: 100%;
				// position: absolute;
				// transition: background-position-x 0.8s ease;
				// width: 100%;
				// z-index: 0;
				// @include media("screen", ">=phone") {
				// 	background-size: 42rem;
				// }
				@include media("screen", ">=tablet") {
					content: "";
					background: $teasers-small_bg-image;
					background-size: 21rem;
					background-position-x: calc(100% + 15rem);
					bottom: 0;
					height: 100%;
					position: absolute;
					transition: background-position-x 0.8s ease;
					width: 100%;
					z-index: 0;
				}
				@include media("screen", ">=desktop") {
					background-size: 28rem;
					background-position-x: calc(100% + 19rem);
				}
				@include media("screen", ">=widescreen") {
					// background-position-x: 130%;
					background-position-x: calc(100% + 14rem);
				}
				@include media("screen", ">=x-widescreen") {
					// background-size: 48rem;
					// background-position-x: 120%;
					background-position-x: calc(100% + 10rem);
				}
				@include media("screen", ">=1920px") {
					// background-position-x: 90%;
					background-position-x: calc(100% - 8rem);
				}
				@include media("screen", ">=2048px") {
					background-position-x: calc(100% - 10vw);
				}
				@include media("screen", ">=2560px") {
					background-position-x: calc(100% - 18vw);
				}
				@include media("screen", ">=3840px") {
					background-position-x: calc(100% - 50vw);
				}
				@include media("screen", ">=4096px") {
					background-position-x: calc(100% - 30vw);
				}
			}
		} @else {
			background-color: $teasers-small_bg;
		}
		margin-top: $teasers-small_margin-top;
		padding: $teasers-small_padding;
		@include media("screen", ">=desktop") {
			padding-bottom: $teasers-small_desktop_padding-bottom;
		}

		@if $theme-linde {
			border-top: 0.25rem solid $yellow;
			@at-root body {
				&.home .header {
					border-bottom: 0;
				}
			}
		}
	}
}

.btn-expand {
	color: $gray-900;
	display: flex;
	align-items: center;
	justify-content: center;
	height: rem-calc(24);
	margin: 1rem auto 1rem auto;
	opacity: 1;
	visibility: visible;
	width: 12rem;
	z-index: 1;
	@include media("screen", ">=phone") {
		margin-top: 0;
	}
	&:hover,
	&:focus {
		color: $black;
	}

	i {
		font-size: rem-calc(8);
		margin-left: 0.25rem;
		margin-top: rem-calc(4);
	}
}
