.busy-indicator {
	height: 6em;
	width: 6em;
	text-align: center;

	&-container {
		position: fixed;
		display: none;
		z-index: 1031;
		height: 5em;
		margin: auto;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;

		&::before {
			content: "";
			display: block;
			position: fixed;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}
