.article-link {
	display: block;
	outline: 0;
	z-index: 3;
	&:visited {
		h3 {
			color: #627b8b;
		}
	}
	&:hover,
	&:focus {
		h3 {
			@if $theme-kiehl {
				color: $secondary;
			} @else {
				color: $post-title_color_hover;
			}
		}
	}
}

//
// Leseansicht
//

// #main {
.flex-wrapper {
	overflow: unset;
	flex-basis: 0;
	flex-grow: 999;
	min-width: 40%;
}

// *** 04.05.2022
// .is-widthfull {
// 	max-width: 100%;
// 	flex: 0 0 auto;
// 	@include media("screen", ">=desktop") {
// 		#dokumentinhalt #dokumentinhaltcontent .listPoint {
// 			margin-left: 0;
// 		}
// 	}
// }

.is-widthfull {
	width: 100%;
	max-width: 100%;
	flex: 0 0 auto;
	@include media("screen", ">=desktop") {
		// max-width: 60rem; // 960px
		#dokumentinhalt #dokumentinhaltcontent .listPoint,
		#dokumentinhalt #dokumentinhaltcontent .ad-container {
			margin-left: 0;
		}
	}
	// @include media("screen", ">=widescreen") {
	// 	max-width: 68.75rem; // 1100px
	// }
}
// *** 04.05.2022 : END

#dokumentinhaltcontent {
	// Marginal
	&.stretch {
		// & > *:not(h1) {
		& > * {
			@media (min-width: 768px) {
				margin-left: 220px;
			}
		}

		.marginal-wrapper {
			left: rem-calc(8);
			max-width: rem-calc(194);
		}

		.manuelList {
			// L2
			.listPoint {
				.marginal-wrapper {
					// margin-left: -256px; // Temporäre Lösung 09.11.22
					margin-left: -220px; // Temporäre Lösung 09.11.22
				}

				// L3
				.listPoint {
					.marginal-wrapper {
						// margin-left: -280px; // Temporäre Lösung 09.11.22
						margin-left: -266px; // Temporäre Lösung 09.11.22
					}

					// L4
					.listPoint {
						.marginal-wrapper {
							// margin-left: -316px; // Temporäre Lösung 09.11.22
							margin-left: -302px; // Temporäre Lösung 09.11.22
						}

						// L5
						.listPoint {
							.marginal-wrapper {
								// margin-left: -358px; // Temporäre Lösung 09.11.22
								margin-left: -344px; // Temporäre Lösung 09.11.22
							}
						}
					}
				}
			}
		}
	}
	// Marginal : END
}

// Zukunft steuern
.future-of-taxes {
	.heading {
		margin-top: 2.5rem !important;
		padding-top: rem-calc(42);
		position: relative;
		&::before {
			background-color: $brand-color;
			border-radius: $border-radius;
			content: "Zukunft steuern";
			color: $black;
			display: inline-flex;
			font-size: rem-calc(12);
			font-weight: 600;
			margin-top: 0;
			padding: 0.3125rem 0.625rem;
			position: absolute;
			text-transform: uppercase;
			top: 0;
		}
	}
}
// Zukunft steuern : END

// Tax&Bytes
.tax-bytes {
	img {
		max-width: rem-calc(200);
	}
}
// Tax&Bytes : END
