$fonts_path: "../fonts/" !default;

// Roboto
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-300",
		300,
		normal
	);
	font-display: auto;
}
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-300-italic",
		300,
		italic
	);
	font-display: auto;
}
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-regular",
		400,
		normal
	);
	font-display: auto;
}
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-italic",
		400,
		italic
	);
	font-display: auto;
}
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-500",
		500
	);
	font-display: auto;
}
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-500-italic",
		500,
		italic
	);
	font-display: auto;
}
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-700",
		700
	);
	font-display: auto;
}
@font-face {
	@include fontface(
		"Roboto",
		"#{$fonts_path}roboto/roboto-v29-latin-700-italic",
		700,
		italic
	);
	font-display: auto;
}

// Linde FF Franziska Pro
/* @import must be at top of file, otherwise CSS will not work */
// @import url("//hello.myfonts.net/count/397d7c");
// @import url("//hello.myfonts.net/count/399973");

// @font-face {
// 	@include fontface(
// 		"FranziskaProBook",
// 		"#{$fonts_path}ff_franziska/webfonts/397D7C_0_0",
// 		300,
// 		normal
// 	);
// 	font-display: auto;
// }
@font-face {
	@include fontface(
		"FranziskaProBook",
		"#{$fonts_path}ff_franziska/webfonts/399973_0_0",
		600,
		normal
	);
	font-display: auto;
}
// @font-face {
// 	@include fontface(
// 		"FranziskaProBook",
// 		"#{$fonts_path}ff_franziska/webfonts/397D7C_2_0",
// 		700,
// 		bold
// 	);
// 	font-display: auto;
// }
