.search-result-filter {
	ul {
		list-style: none;
		padding-left: 1.5rem;
	}

	.selected {
		font-weight: 600;
		line-height: 1.25;
		margin-left: 1.375rem;
		margin-bottom: 0.625rem;

		.hits {
			margin-left: 0.5rem;
		}
	}
}

.hits {
	color: $gray-700;
	font-weight: 400;
	margin-left: 0.25rem;
}
