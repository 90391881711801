@import "../components/footer-top";
@import "../components/footer-nav";

.footer {
	background: $footer-bg;
	bottom: 0;
	flex-shrink: 0; // Sticky footer

	&.magazine-footer {
		position: relative;
	}
}

.footer-container {
	@extend .container;
	@extend .d-flex;
	@extend .justify-content-between;
	@include media("screen", "<desktop") {
		flex-direction: column-reverse;
		text-align: center;
		padding-top: 2rem;
		// padding-bottom: 2rem; // .sustainability
		padding-bottom: 5rem; //6rem;
	}
	// @include media("screen", ">=tablet") {
	//     flex-direction: row;
	//     align-items: center;
	// }
	@include media("screen", ">=desktop") {
		flex-direction: row;
		align-items: center;
		padding-top: 1.5rem;
		padding-bottom: 1.5rem;
	}

	a {
		color: $footer-nav-item-color;
		font-weight: $footer-nav-font-weight;
		&:hover {
			color: $footer-nav-item-color-hover;
		}
	}
}

.copyright {
	color: $white;
	font-size: $footer-nav-font-size;
	font-weight: 300;
	// @include media("screen", "<phone") {
	@include media("screen", "<desktop") {
		margin-top: rem-calc(20);
	}
}

// .sustainability {
//     color: $gray-300;
//     // display: flex;
//     // align-items: center;
//     @include media("screen", "<phone") {
//         margin-top: rem-calc(20);
//     }
//     @include media("screen", ">=phone", "<desktop") {
//         margin-top: rem-calc(30);
//     }
//     @include media("screen", "<desktop") {
//         display: block;

//         i {
//             font-size: rem-calc(32);
//         }
//     }
//     @include media("screen", ">=desktop") {
//         padding-left: 1rem;

//         i {
//             font-size: rem-calc(24);
//         }
//     }

//     // span {
//     //     font-size: rem-calc(10);
//     //     padding-right: 0.5rem;
//     //     text-transform: uppercase;
//     // }
// }
