.filter-category {
	background-color: $gray-200;
	border-style: solid;
	border-color: $gray-200;
	border-width: rem-calc(10) 0;
	height: rem-calc(44);
	overflow: hidden;
	padding: 0 rem-calc(44);
	z-index: 0;
	@include media("screen", "<desktop") {
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
	}

	a {
		color: $gray-900;
		margin: 0 0.3125rem;
		&:hover,
		&:focus {
			color: $primary;
		}
	}
}

.filter-carousel {
	position: relative;

	.owl-stage-outer {
		height: rem-calc(24);
		overflow: hidden;
		white-space: nowrap;
	}

	.owl-item {
		display: inline-block;
		&:not(:last-child) {
			a::after {
				content: "|";
				color: $gray-500;
				margin: 0 0 0 0.625rem;
			}
		}
	}

	.owl-prev,
	.owl-next {
		background-color: transparent;
		border: 0;
		color: $gray-500;
		height: rem-calc(44);
		position: absolute;
		text-align: center;
		top: -0.625rem;
		transition: $transition-base;
		width: rem-calc(44);
		z-index: 10;
		&:hover,
		&:focus {
			color: $gray-900;
			outline: 0;
		}
	}

	.owl-prev {
		left: 0;
		&::after {
			content: "";
			background: linear-gradient(
				90deg,
				rgba($gray-200, 1) 0%,
				rgba($gray-200, 0) 100%
			);
			height: rem-calc(44);
			position: absolute;
			right: rem-calc(-10);
			top: 0;
			width: rem-calc(10);
		}
	}

	.owl-next {
		right: 0;
		&::before {
			content: "";
			background: linear-gradient(
				90deg,
				rgba($gray-200, 0) 0%,
				rgba($gray-200, 1) 100%
			);
			height: rem-calc(44);
			left: rem-calc(-10);
			position: absolute;
			top: 0;
			width: rem-calc(10);
		}
	}
}
