.editorial {
	.post {
		margin: 0;

		&-body {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0.25rem;
		}

		// &-title {
		// 	font-size: $h6-font-size;
		// }

		&:last-child {
			.post-link {
				border-bottom: 0;
			}
		}
	}

	.post-head,
	.post-text,
	.post-links {
		display: none;
	}

	// &.scrolling {
	// 	@include media("screen", ">=desktop") {
	// 		height: calc(100vh - 26rem);
	// 	}
	// }
}
