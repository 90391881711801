.library {
	&-small {
		.book-link {
			color: $gray-900;
			width: 100%;
		}

		.book-cover {
			@include media("screen", ">=375px") {
				height: 13rem;
			}
			@include media("screen", ">=411px") {
				height: 14.5rem;
			}
			@include media("screen", ">=phone") {
				height: 14.5rem;
			}
			@include media("screen", ">=desktop") {
				height: 19rem;
				width: auto;
			}
			@include media("screen", ">=widescreen") {
				height: 14.5rem;
			}
			@include media("screen", ">=x-widescreen") {
				height: 15.875rem;
			}
		}

		.book-title {
			hyphens: auto;
			margin-top: 1rem;
			text-align: center;
		}
	}
}
