@import "variables";

@font-face {
	font-family: "Font Awesome 5 Pro";
	font-style: normal;
	font-weight: 400;
	font-display: $fa-font-display;
	src: url("#{$fa-font-path}/fa-regular-400.woff2") format("woff2"),
		url("#{$fa-font-path}/fa-regular-400.woff") format("woff"),
		url("#{$fa-font-path}/fa-regular-400.ttf") format("truetype");
}

.far {
	font-family: "Font Awesome 5 Pro";
	font-weight: 400;
}
