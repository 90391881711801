// Optional indicator pips
.carousel-indicators {
	li {
		background-color: transparent;
		border: 1px solid $gray-500;
		border-radius: 50%;
		opacity: 1;
	}

	.active {
		border-color: $cyan;
	}
}

// Optional captions
.carousel-caption {
	@include media("screen", "<desktop") {
		display: none;
	}
}

.carousel-control-prev,
.carousel-control-next {
	@include media("screen", "<tablet") {
		display: none;
	}
}

.owl-dots {
	position: relative;
}
