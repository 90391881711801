// Header
$header-bg: #f7f8fa !default;
$header-bg-before: null !default;
$header-border-bottom: 0.25rem solid $yellow !default;
$header-margin-top_desktop: null !default;
$header-top_desktop: null !default;

$header-height_mobile: 3.75rem !default; // 60px
$header-height_desktop: 5rem !default; // 80px

// Header holder
// $header-holder_border-bottom: 0.0625rem solid rgba($black, 0.1) !default;
// $header-holder_border-bottom: null !default;
$header-holder_height: 5rem !default; // 80px
$header-holder_height_mobile: 3.75rem !default; // 60px
$header-holder_height_desktop: 5rem !default; // 80px

// Header bottom element
$header-bottom-element: false !default;

// Header button
$header-btn_color: $primary !default;
$header-btn_color_hover: $primary !default;
$header-btn_box-shadow: none !default;
$header-btn_icon_color: $primary !default;

// Search
$search_top: 8rem !default;
$search_top_desktop: 9rem !default;
