.badge-price {
    background-color: $badge-price_bg-color;
    border-radius: 50%;
    color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -1rem;
    top: -12%;
    text-transform: uppercase;
    text-decoration: none;
    transform: rotate(10deg);
    transition: right 0.3s ease-in-out;
    height: $badge-price_size;
    width: $badge-price_size;
    white-space: normal;
    @include media("screen", ">=tablet") {
        height: calc(#{$badge-price_size} + 1rem);
        width: calc(#{$badge-price_size} + 1rem);
    }
    @include media("screen", ">=desktop") {
        right: -3rem;
        top: -12%;
        height: calc(#{$badge-price_size} + 2rem);
        width: calc(#{$badge-price_size} + 2rem);
    }
    @include media("screen", ">=widescreen") {
        top: -18%;
    }
    @include media("screen", ">=x-widescreen") {
        height: calc(#{$badge-price_size} + 3rem);
        width: calc(#{$badge-price_size} + 3rem);
    }
}

.badge-text {
    font-weight: 300;
    font-size: rem-calc(20);
    line-height: rem-calc(24);
    @include media("screen", ">=tablet") {
        font-size: rem-calc(22);
    }
    @include media("screen", ">=desktop") {
        font-size: rem-calc(24);
        line-height: rem-calc(26);
    }
}

.badge-text,
.lead {
    small {
        font-weight: 300;
    }
}

.badge-text--small {
    font-size: rem-calc(13);
    line-height: 1;
    @include media("screen", ">=tablet") {
        font-size: rem-calc(14);
    }
    @include media("screen", ">=desktop") {
        font-size: rem-calc(16);
    }
}

.is-open,
.is-widthfull {
    .badge-price {
        @include media("screen", ">=desktop") {
            right: -1rem;
        }
    }
}
