// DROPDOWN
.dropdown-menu {
	box-shadow: $box-shadow-lg;
	padding-left: rem-calc(10);
	padding-right: rem-calc(10);
	max-width: 34rem;
	&[x-placement="bottom-start"],
	&[x-placement="bottom-end"] {
		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			@include triangle(22px, $dropdown-arrow-border-color, up);
			top: rem-calc(-12);
		}
		&::after {
			@include triangle(20px, $white, up);
			top: rem-calc(-10);
		}
	}
	&[x-placement="bottom-start"],
	&[x-placement="top-start"] {
		&::before {
			left: rem-calc(39);
		}
		&::after {
			left: rem-calc(40);
		}
	}

	&[x-placement="top-start"],
	&[x-placement="top-end"] {
		&::before,
		&::after {
			content: "";
			position: absolute;
		}
		&::before {
			@include triangle(22px, $dropdown-arrow-border-color, down);
			bottom: rem-calc(-12);
		}
		&::after {
			@include triangle(20px, $white, down);
			bottom: rem-calc(-10);
		}
	}

	&[x-placement="bottom-end"],
	&[x-placement="top-end"] {
		&::before {
			right: rem-calc(39);
		}
		&::after {
			right: rem-calc(40);
		}
	}

	// [type="submit"] {
	// 	display: block;
	// 	background-image: linear-gradient(180deg, $gray-400 0%, $gray-500 100%);
	// 	color: $white;
	// 	width: 100%;
	// 	padding: rem-calc(16) rem-calc(14);
	// 	border: none;
	// 	&:hover {
	// 		cursor: pointer;
	// 	}
	// }
}

.dropdown-item {
	@include animate(background color);
	cursor: pointer;
	position: relative;
	vertical-align: middle;
	margin-bottom: 0;
	white-space: normal;
	&:first-child {
		// @if $theme-nwb {
		// 	border-radius: 0.625rem 0.625rem 0 0;
		// } @else {
		margin-top: rem-calc(10);
		// }
	}
	&:last-child {
		// @if $theme-nwb {
		// 	border-radius: 0 0 0.625rem 0.625rem;
		// } @else {
		border-radius: 0 0 $border-radius $border-radius;
		// }
	}

	&.bg-light {
		&:hover,
		&:focus {
			background-color: $light-hover !important;
		}
	}
}

.dropdown-item--icon {
	font-size: 1rem;
	height: rem-calc(16);
	position: absolute;
	top: rem-calc(11);
	width: rem-calc(16);

	& > img {
		display: inline-block;
		margin-top: rem-calc(-7);
	}
}

.dropdown-item--title,
.dropdown-item--subtitle {
	display: block;
	padding-left: rem-calc(26);
}

.dropdown-item--title {
	color: $dropdown-item-title-color;
	font-size: rem-calc(14);
	font-weight: 600;
	text-transform: uppercase;
}

.dropdown-item--subtitle {
	color: $gray-700;
	line-height: 1.5;
	@include media("screen", "<desktop") {
		margin-top: rem-calc(5);
	}
}

// DROPDOWN DEFAULT TOGGLE
.dropdown-default-toggle {
	&::after {
		content: "";
		display: inline-block;
	}

	& + .dropdown-menu {
		background-image: linear-gradient(180deg, $white 0%, $gray-100 100%);
		padding: 0;
		top: 2px !important;

		.dropdown-item {
			border-bottom: 1px solid $gray-200;
			padding: rem-calc(12) rem-calc(17);
			&:hover {
				background-color: $gray-200;
				color: $blue;
			}
		}
	}
}

.dropdown-toggle {
	// display: flex;
	// align-items: center;
	overflow: hidden;
	padding-right: 2rem;
	position: relative;
	text-overflow: ellipsis;
	@include media("screen", "<375px") {
		max-width: rem-calc(300);
	}
	@include media("screen", ">=phone") {
		max-width: rem-calc(160);
	}
	@include media("screen", ">=widescreen") {
		max-width: rem-calc(240);
	}
	@include media("screen", ">=1440px") {
		max-width: rem-calc(360);
	}

	[class*="icon-"] {
		font-size: rem-calc(9);
		position: absolute;
		right: rem-calc(16);
		@if $theme-linde {
			top: rem-calc(12);
		} @else {
			top: rem-calc(15);
		}
		// margin-left: 0.5rem;
	}

	&.btn-sm {
		padding-right: 1.625rem !important;

		[class*="icon-"] {
			right: 0.75rem;
			top: rem-calc(11);
		}
	}

	&.btn-lg {
		[class*="icon-"] {
			top: rem-calc(20);
		}
	}
}

main .dropdown-menu {
	max-height: 18rem;
	overflow-x: hidden;
	@include media("screen", ">=375px") {
		max-height: 24rem;
	}
	@include media("screen", ">=desktop") {
		max-height: 30rem;
	}
}

.group {
	// @include media("screen", "<phone") {
	@include media("screen", "<tablet") {
		// @if $theme-nwb {
		// flex-direction: column;
		flex-wrap: wrap;
		justify-content: flex-end;
		// }
		position: relative;
		&:before {
			left: 0;
			position: absolute;
			top: rem-calc(24);
		}

		& > div {
			justify-content: flex-end;
		}
		// }
		// @include media("screen", "<tablet") {
		&:before {
			content: "";
			@if $theme-sis {
				background-color: $brand-color-lighter;
			} @else {
				background-color: $gray-400;
			}
			height: 0.0625rem;
			width: calc(100% - 7rem);
			// z-index: -1;
			z-index: 0;
		}

		.dropdown,
		.btn-group {
			background-color: $white;
			border: 0.5rem solid $white;
			border-right: 0;
		}
	}
	@include media("screen", ">=phone", "<tablet") {
		align-items: center;
		justify-content: flex-end;
	}

	.dropdown + .dropdown {
		margin-left: 0.5rem;
	}

	.btn-group {
		b {
			opacity: 0.75;
		}
	}
}

[aria-labelledby="ddownSort"] {
	min-width: 10rem;
}
