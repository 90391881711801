// vertical align el inside parent with fixed height/min-height
// usage
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
	white-space: nowrap;
	text-align: center;

	&:#{$va-pseudo} {
		content: "";
		display: inline-block;
		vertical-align: $va-direction;
		width: 0;
		min-height: $va-height;
	}

	> * {
		white-space: normal;
		display: inline-block;
		vertical-align: $va-direction;
		max-width: 99%;
	}
}

// vertical align a pair of child el inside parent
// usage
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//
@mixin v-align-pair(
	$child-name1,
	$child-name2,
	$valign: middle,
	$width1: auto,
	$width2: auto
) {
	display: table;
	.#{$child-name1} {
		display: table-cell;
		vertical-align: $valign;
		width: $width1;
	}
	.#{$child-name2} {
		display: table-cell;
		vertical-align: $valign;
		width: $width2;
	}
}

// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @author Hugo Giraudel
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height ($width) - Element’s `height`
///
/// @example scss - Usage
///   .foo {
///     @include size(10em);
///   }
///
///   .bar {
///     @include size(100%, 10em);
///   }
///
/// @example css - CSS output
///   .foo {
///     width: 10em;
///     height: 10em;
///   }
///
///   .bar {
///     width: 100%;
///     height: 10em;
///   }
///
@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value: on) {
	@if $value == on {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	} @else {
		-webkit-font-smoothing: subpixel-antialiased;
		-moz-osx-font-smoothing: auto;
	}
}

/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
	overflow: hidden;
	text-indent: 101%;
	white-space: nowrap;
}

/// Creates a visual triangle.
/// Mixin takes ($size, $color, $direction)
/// The $size argument can take one or two values—width height.
/// The $color argument can take one or two
/// values—foreground-color background-color.
///
/// @author http://bourbon.io/docs/#triangle
///
/// $direction:
/// up, down, left, right, up-right, up-left, down-right, down-left
/// @example scss - Usage
///   @include triangle(12px, gray, down);
///   @include triangle(12px 6px, gray blue, up-left);
///
@mixin triangle($size, $color, $direction) {
	$width: nth($size, 1);
	$height: nth($size, length($size));
	$foreground-color: nth($color, 1);
	$background-color: if(length($color) == 2, nth($color, 2), transparent);
	height: 0;
	width: 0;

	@if ($direction == up) or
		($direction == down) or
		($direction == right) or
		($direction == left)
	{
		$width: $width / 2;
		$height: if(length($size) > 1, $height, $height/2);

		@if $direction == up {
			border-bottom: $height solid $foreground-color;
			border-left: $width solid $background-color;
			border-right: $width solid $background-color;
		} @else if $direction == right {
			border-bottom: $width solid $background-color;
			border-left: $height solid $foreground-color;
			border-top: $width solid $background-color;
		} @else if $direction == down {
			border-left: $width solid $background-color;
			border-right: $width solid $background-color;
			border-top: $height solid $foreground-color;
		} @else if $direction == left {
			border-bottom: $width solid $background-color;
			border-right: $height solid $foreground-color;
			border-top: $width solid $background-color;
		}
	} @else if ($direction == up-right) or ($direction == up-left) {
		border-top: $height solid $foreground-color;

		@if $direction == up-right {
			border-left: $width solid $background-color;
		} @else if $direction == up-left {
			border-right: $width solid $background-color;
		}
	} @else if ($direction == down-right) or ($direction == down-left) {
		border-bottom: $height solid $foreground-color;

		@if $direction == down-right {
			border-left: $width solid $background-color;
		} @else if $direction == down-left {
			border-right: $width solid $background-color;
		}
	} @else if ($direction == inset-up) {
		border-color: $background-color $background-color $foreground-color;
		border-style: solid;
		border-width: $height $width;
	} @else if ($direction == inset-down) {
		border-color: $foreground-color $background-color $background-color;
		border-style: solid;
		border-width: $height $width;
	} @else if ($direction == inset-right) {
		border-color: $background-color
			$background-color
			$background-color
			$foreground-color;
		border-style: solid;
		border-width: $width $height;
	} @else if ($direction == inset-left) {
		border-color: $background-color
			$foreground-color
			$background-color
			$background-color;
		border-style: solid;
		border-width: $width $height;
	}
}

$animation-speed: 0.3s !default;
$easing-default: linear;

@mixin animate(
	$properties,
	$duration: $animation-speed,
	$easing: $easing-default
) {
	$list: ();
	@each $prop in $properties {
		$str: #{$prop} #{$duration} #{$easing};
		$list: join($list, #{$str}, comma);
	}
	transition: $list;
}

/// Mixin for styling form-placeholders
/// @example scss - Usage
///   @include placeholder {
///    color: #333;
///   }
///
///   input[type='text'],
///   input[type='tel'],
///   input[type='email'] {
///     @include placeholder {
///      color: red;
///     }
///   }
@mixin placeholder {
	&::-webkit-input-placeholder {
		@content;
	}
	&::-moz-placeholder {
		opacity: 1;
		@content;
	}
	&:-moz-placeholder {
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
	&.placeholder {
		@content;
	}
}

// Alignment
@mixin align($align: top) {
	display: inline-block;
	vertical-align: $align;
}

@mixin inline-reset {
	font-size: 0;
	line-height: 0;
	> * {
		font-size: $font-size-base;
		line-height: $line-height-base;
	}
}

@mixin clear-list {
	margin: 0;
	padding: 0;
	list-style: none;
}

@mixin clearfix {
	&:after {
		clear: both;
		display: block;
		content: "";
	}
}

@mixin box($size) {
	width: $size;
	height: $size;
	border-radius: $size;
}

// mixin for position
@mixin position {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

// size
@mixin size($width, $height: $width) {
	width: $width;
	height: $height;
}

// responsive image
@mixin responsive-image {
	display: block;
	width: 100%;
	height: auto;
}

// custom font mixin
@mixin fontface(
	$fontfamily,
	$filename,
	$fontweight: normal,
	$fontstyle: normal
) {
	font-family: $fontfamily;
	// src: url("#{$filename}.eot");
	src: 
		// url("#{$filename}.eot?#iefix") format("embedded-opentype"),
		url("#{$filename}.woff") format("woff"),
		url("#{$filename}.woff2") format("woff2"),
		url("#{$filename}.ttf") format("truetype"),
		// url("#{$filename}.svg#{$filename}") format("svg")
;
	font-weight: $fontweight;
	font-style: $fontstyle;
	font-display: optional;
}

// Generate semantic card container
// @mixin make-card-container() {
//   width: 100%;
//   padding-right: ($grid-gutter-width / 2);
//   padding-left: ($grid-gutter-width / 2);
//   margin-right: auto;
//   margin-left: auto;
// }

// // For each breakpoint, define the width of the container in a media query
// @mixin make-card-container-widths($max-widths: $container-max-widths, $breakpoints: $grid-breakpoints) {
//   @each $breakpoint, $container-max-width in $max-widths {
//     @include media-breakpoint-up($breakpoint, $breakpoints) {
//       width: $container-max-width;
//     }
//   }
// }

// Truncate for multiline
@mixin multiLineTrancate($lineHeight: 1.2rem, $lineCount: 1) {
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
}

// Truncate for multiline
@mixin multiLineEllipsis($lineHeight: 1.2rem, $lineCount: 1, $bgColor: white) {
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
	//   padding-bottom: $lineHeight;
	//   &:before {
	//     content: "[\2022\2022\2022]";
	//     background-color: $bgColor;
	//     color: $gray-500;
	//     font-weight: 300;
	//     // bottom: -#{$lineHeight};
	//     bottom: 0;
	//     left: 0;
	//     position: absolute;
	//     width: 100%;
	//     z-index: 1;
	//   }
	&::after {
		content: "";
		display: block;
		// bottom: $lineHeight;
		bottom: 0;
		right: 0;
		width: 100%;
		height: $lineHeight;
		background: $bgColor;
		background: linear-gradient(90deg, rgba($bgColor, 0) 0%, $bgColor 40%);
		position: absolute;
	}
}

// Truncate for multiline end
@mixin multiLineEllipsisEnd(
	$lineHeight: 1.2rem,
	$lineCount: 1,
	$bgColor: white
) {
	overflow: hidden;
	position: relative;
	line-height: $lineHeight;
	max-height: $lineHeight * $lineCount;
	&::before {
		// content: "[\2022\2022\2022]";
		content: "";
		font-weight: 300;
		bottom: 0;
		opacity: 0.25;
		position: absolute;
		right: calc(50% - 1rem);
		z-index: 1;
	}
	&::after {
		content: "";
		display: block;
		bottom: 0;
		right: 0;
		width: 100%;
		height: $lineHeight;
		background: $bgColor;
		background: linear-gradient(90deg, rgba($bgColor, 0) 0%, $bgColor 40%);
		position: absolute;
	}
}

@mixin scrolling {
	height: 100%;
	// margin-right: rem-calc(-10);
	// padding-right: 1.5rem;
	padding-right: 0.5rem;
	overflow-x: hidden;
	overflow-y: auto;
	// transition: $transition-base;
	// position: relative;
	// Fader
	// &::before,
	// &::after {
	// 	content: "";
	// 	position: absolute;
	// 	width: 100%;
	// }
	// // &::before {
	// // 	background: linear-gradient(0deg, rgba($white, 0) 0%, $white 50%);
	// // 	top: 0;
	// // 	height: 0.625rem;
	// // }
	// &::after {
	// 	background: linear-gradient(180deg, rgba($white, 0) 0%, $white 60%);
	// 	bottom: 0;
	// 	height: 3rem;
	// 	top: auto;
	// }
	// Scrollbar
	&::-webkit-scrollbar {
		background-color: $gray-100;
		width: rem-calc(3);
		height: 0.375rem;
	}
	// Scrollbar track
	&::-webkit-scrollbar-track {
		background-color: rgba($white, 0.1);
		border-radius: 0.5rem;
		// background-clip: content-box;
	}
	// Scrollbar thumb
	&::-webkit-scrollbar-thumb {
		@if $theme-kiehl {
			background-color: $gray-500;
		} @else {
			background-color: rgba($primary, 0.45);
		}
		border-radius: 0.5rem;
	}
	@include media("screen", ">=desktop") {
		&::-webkit-scrollbar {
			width: rem-calc(8);
		}

		&::-webkit-scrollbar-thumb {
			max-height: 3rem;
			// @if $theme-kiehl {
			// 	background-color: transparent;
			// } @else {
			// 	background-color: rgba($primary, 0);
			// }
			@if $theme-kiehl {
				background-color: $gray-500;
			} @else {
				background-color: rgba($primary, 0.25);
			}
			border-radius: 0.5rem;
		}

		// &:hover,
		// &:focus {
		// 	&::-webkit-scrollbar-thumb {
		// 		@if $theme-kiehl {
		// 			background-color: $gray-500;
		// 		} @else {
		// 			background-color: rgba($primary, 0.25);
		// 		}
		// 	}
		// }
	}
}
