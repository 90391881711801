.ad-container {
	margin-bottom: 2rem;

	a:not(.ad-desc-link) {
		display: block;
		border-bottom: 0 !important;
	}
}

.ad-desc {
	background-color: $gray-100;
	display: inline-block;
	font-size: rem-calc(14);
	font-weight: 600;
	margin-top: rem-calc(20);
	padding: 0.325rem 1rem;
	text-align: left;
}

.ad-desc-link {
	color: $gray-700 !important;
	display: inline-block;
	font-size: rem-calc(14);
	font-weight: 400 !important;
	margin-top: 0.5rem;
	&:hover,
	&:focus {
		color: $primary !important;
	}
}

.ad-image {
	height: auto;
	// margin-bottom: 1rem;
	width: 100%;
}
