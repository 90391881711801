.ribbon {
	&-new {
		position: relative;
		&::before {
			background: linear-gradient(
				to left bottom,
				$ribbon-new_bg 50%,
				transparent 50%
			);
			content: "";
			// color: $ribbon-new_color;
			// display: flex;
			// align-items: center;
			// justify-content: center;
			height: rem-calc(64);
			overflow: hidden;
			position: absolute;
			right: rem-calc(-1);
			top: rem-calc(-1);
			width: rem-calc(64);
		}
		&::after {
			box-shadow: $ribbon-new_box-shadow;
			content: "Neu";
			color: $ribbon-new_color;
			display: block;
			font-size: $ribbon-new_font-size;
			font-weight: $ribbon-new_font-weight;
			height: 1.25rem;
			text-transform: $ribbon-new_text-transform;
			line-height: 1;
			// padding: rem-calc(10);
			position: absolute;
			right: rem-calc(-1);
			top: rem-calc(-1);
			text-align: center;
			transform: rotate(45deg) translate(24px, -3px);
			width: 5.5rem;
		}
	}
}
