.filter-toggle {
	// display: flex;
	// @include media("screen", "<420px") {
	// 	flex-direction: column;
	// 	align-items: flex-end;
	// 	margin-top: 0.5rem;
	// }
	// @include media("screen", ">=420px") {
	// 	align-items: center;
	// }
	@include media("screen", "<tablet") {
		position: absolute;
		top: -2.75rem;
	}
	@include media("screen", ">=tablet") {
		margin-left: 0.5rem;
		order: 1;
	}
	@include media("screen", ">=desktop") {
		display: none;
	}
}

.filter-group-first {
	display: flex;
	justify-content: flex-end;
	@include media("screen", "<desktop") {
		position: relative;
	}
	@include media("screen", ">=desktop") {
		margin-left: auto;
		// max-width: 100%;
		width: 100%;
	}
}

.filter-group-last {
	@include media("screen", "<desktop") {
		background-color: $white;
		height: 100%;
		opacity: 0;
		overflow-y: auto;
		padding: 0.5rem 1rem;
		position: fixed !important;
		transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
		top: 0;
		right: -100%;
		width: 100%;
		z-index: 1020;

		.btn-group {
			width: 100%;
		}

		.dropdown {
			display: flex;
			margin-bottom: 1rem;
			width: 100%;
		}

		.dropdown-toggle {
			display: none;
		}

		.dropdown-menu {
			border: 0;
			// box-shadow: $box-shadow-medium;
			box-shadow: $box-shadow;
			display: block;
			position: relative;
			max-height: none;
			min-width: 100%;
			max-width: none;
			&::before {
				content: attr(data-name);
				font-size: 0.6875rem;
				font-weight: 600;
				text-transform: uppercase;
			}

			& > .dropdown-item {
				&:not(:first-child) {
					border-top: 0.0625rem dashed $gray-400;
				}
			}
		}

		.dropdown-item {
			padding-left: rem-calc(30);
			transition: all 0.3s ease-in-out;

			&::before {
				// @if $theme-kiehl {
				// 	color: $secondary;
				// } @else {
				// 	color: $info;
				// }
				font-family: "iconfont";
				font-size: 1.125rem;
				opacity: 0;
				position: absolute;
				top: rem-calc(7);
				left: 0;
				transition: opacity 0.3s ease-in-out;
			}

			&:hover,
			&:focus,
			&.active {
				background-color: $white;
				color: $black;
				font-weight: 600;
				&::before {
					content: $icon-check;
					opacity: 1;
				}
			}
		}

		// [aria-labelledby="ddownSort"],
		// [aria-labelledby="ddownTheme"] {
		// 	&::before {
		// 		font-size: 0.6875rem;
		// 		font-weight: 600;
		// 		text-transform: uppercase;
		// 	}
		// }

		// [aria-labelledby="ddownSort"] {
		// 	&::before {
		// 		content: "sortieren nach";
		// 	}
		// }

		// [aria-labelledby="ddownTheme"] {
		// 	&::before {
		// 		content: "sortieren nach Themen";
		// 	}
		// }
	}
	@include media("screen", ">=desktop") {
		display: flex;

		.dropdown {
			margin-left: 0.5rem;
		}
	}
	// @include media("screen", ">=tablet", "<desktop") {
	// 	justify-content: flex-end;
	// 	margin-top: 0.625rem;
	// 	width: 100%;
	// }

	.dropdown-item {
		&::before {
			@if $theme-kiehl {
				color: $secondary;
			} @else {
				color: $info;
			}
		}
	}

	.subtheme {
		margin-bottom: 0.625rem;
		padding-left: rem-calc(30);
		// @if $theme-linde {
		// 	background-color: $blue-lightest;
		// }
		// @include media("screen", "<desktop") {
		// 	border-top: 0.0625rem dashed $gray-400;
		// }
		@include media("screen", ">=desktop") {
			// border-bottom: 0.0625rem dashed $gray-400;
			// padding-left: 0;
			padding-left: 0.625rem;
		}

		.dropdown-item {
			// @if $theme-linde {
			// border-left: 1px solid $blue-light;
			// padding-left: 0;
			// }
			// @include media("screen", "<desktop") {
			// 	padding-left: rem-calc(20);
			// }
			// @include media("screen", ">=desktop") {
			border-left: 1px solid $gray-400;
			padding-left: rem-calc(24);
			// }
			&:hover,
			&:focus {
				background-color: transparent;
			}

			&:first-child {
				margin-top: 0;
			}

			&::before {
				// content: "–";
				content: "";
				opacity: 1;
				margin-left: -0.0625rem;
				margin-right: 0.5rem;
				// @include media("screen", "<desktop") {
				// 	top: 0.25rem;
				// }
				// @include media("screen", ">=desktop") {
				// 	content: "";
				background-color: $gray-400;
				height: 0.0625rem;
				left: 0;
				position: absolute;
				top: 50%;
				width: 1rem;
				// }
			}

			&:last-child {
				// @include media("screen", ">=desktop") {
				&::after {
					content: "";
					background-color: $white;
					height: 50%;
					width: 0.125rem;
					top: calc(50% + 0.0625rem);
					left: -0.0625rem;
					position: absolute;
				}
				// }
			}
		}
	}

	&.is-active {
		opacity: 1;
		right: 0;

		.sidebar-btn-close {
			opacity: 1;
		}
	}
}

.filter-group--controls {
	@include media("screen", "<desktop") {
		border-bottom: 0.0625rem solid $gray-400;
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;
		padding-bottom: 0.5rem;

		h3 {
			margin-bottom: 0;
		}
	}

	@include media("screen", ">=desktop") {
		display: none;
	}
}

.live-filter {
	border: {
		top: 0;
		left: 0;
		right: 0;
		bottom-color: $live-filter_border-bottom;
	}
	border-radius: 0;
	box-shadow: none;
	height: auto;
	margin-right: 1rem;
	padding: {
		top: 0.125rem;
		left: 0;
		right: 0;
		bottom: 0.125rem;
	}
	// min-width: calc(100vw - 2rem);
	@if $theme-kiehl {
		max-width: 10rem;
	} @else {
		max-width: 100%;
	}
	@include media("screen", ">=tablet") {
		max-width: 10rem;
	}
	@include media("screen", ">=desktop") {
		max-width: 12rem;
	}
	@include media("screen", ">=widescreen") {
		max-width: 15rem;
	}

	&::placeholder {
		color: $gray-500;
	}

	&:focus {
		background-color: transparent;
		border-bottom-color: $live-filter_border-bottom_focus;
		box-shadow: none;
		&::placeholder {
			color: $primary;
		}
		&.is-invalid {
			box-shadow: none;
		}
	}

	&.is-invalid {
		color: $danger;
		background-position: right !important;
		padding-right: 1.5rem !important;
	}
}
