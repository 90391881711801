@media print {
	* {
		-webkit-transition: none !important;
		transition: none !important;
	}

	body {
		font-size: rem-calc(17);
		line-height: rem-calc(26);
		padding-top: 0 !important;
		display: block !important;
		overflow: visible !important;
		// @-moz-document url-prefix() {
		// 	min-height: auto;
		// }

		// &:not(.livefeed.document) {
		margin-left: rem-calc(70);
		// }
	}

	a {
		color: $primary;
		text-decoration: none !important;
	}

	#header,
	.header,
	#footer,
	.footer,
	nav,
	[aria-label="breadcrumb"],
	.toolbar,
	.aside,
	aside,
	.sidebar-btn,
	.open-table,
	main > section,
	#debuggoutputWindow,
	.variations {
		display: none !important;
	}

	.affix,
	.btn,
	.sidebar,
	.back-bar,
	.tooltip,
	.function-bar,
	.magazine-toolbar,
	.footer-container,
	.smooth-scroll,
	.loader,
	.loader-spinner,
	.modal,
	.modal-backdrop,
	.doc-toolbar,
	.ad-container,
	.imgContainerPreview,
	.header-bridge,
	span[data-tooltip] {
		background: none;
		border: 0;
		display: none !important;
		height: 0;
		margin: 0;
		opacity: 0;
		width: 0;
		z-index: 0 !important;
	}

	// @-moz-document url-prefix() {
	.offcanvas-enable.row {
		display: block !important;
		// overflow: visible !important;
	}
	// }

	// .livefeed.document {
	// 	.marginal-left {
	// 		margin-left: rem-calc(70);
	// 	}
	// }

	#dokumentinhalt,
	#dokumentinhalt #dokumentinhaltcontent {
		img {
			max-width: 100%;
		}

		a[data-bs-toggle="lightbox"] {
			img {
				width: 100%;
			}
		}

		p {
			position: relative;
		}

		.marginal-wrapper,
		.einleitung .content-box .marginal-wrapper {
			// margin-left: -90px;
			margin-left: -50px; // 28.04.2022
		}

		.content-box,
		li {
			.marginal-wrapper {
				// margin-left: -110px;
				margin-left: -70px; // 28.04.2022
			}

			// L2
			li {
				.marginal-wrapper {
					margin-left: -110px; // 04.05.2022
				}

				// L3
				li {
					.marginal-wrapper {
						margin-left: -128px; // 04.05.2022
					}

					// L4
					li {
						&::before {
							margin-top: -2px;
						}
						.marginal-wrapper {
							margin-left: -146px; // 04.05.2022
						}

						// L5
						li {
							.marginal-wrapper {
								margin-left: -164px; // 04.05.2022
							}
						}
					}
				}
			}
		}

		.no-link {
			display: none;
		}
		// & * {
		// 	margin-left: 0 !important;
		// }

		.content-box {
			border: 0.0625rem solid $gray-400 !important;
			&.aussage {
				border-top: 4px solid $gray-400 !important;
			}
		}
	}

	// Only Desktop Safari
	@supports (font: -apple-system-body) and
		(not (-webkit-touch-callout: default)) {
		.marginal {
			color: $gray-700 !important;
		}

		.post-type--badge {
			color: $gray-700 !important;
		}

		#dokumentinhalt,
		#dokumentinhalt #dokumentinhaltcontent {
			.img span {
				color: $gray-700 !important;
			}
		}
		.arrow {
			color: darken($cyan, 8%) !important;
		}
	}

	section {
		display: block;

		ul:not(.accordion):not(.footnote-list) {
			list-style-type: square;
			margin-bottom: 0;
			padding-left: 0;

			li {
				position: relative;
				padding-left: rem-calc(20);
				margin-bottom: rem-calc(16);
				&:before {
					content: "▪";
					color: $blue-light;
					font-size: rem-calc(28);
					display: inline-block;
					position: absolute;
					top: -1px;
					left: 0;
				}
			}

			ul {
				padding-top: rem-calc(16);
				margin-bottom: 0;

				li {
					&:before {
						color: #a7a7a7;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	.cms-document {
		ol {
			padding-left: 0;

			li {
				margin-bottom: rem-calc(16);
				&:before {
					color: $blue-light;
					font-weight: 600;
					width: rem-calc(30);
				}
			}

			ol {
				padding: rem-calc(16) 0 0 rem-calc(30);
				margin-bottom: 0;

				li {
					&:before {
						color: #a7a7a7;
						width: rem-calc(30);
					}
				}
			}
		}

		ol {
			&[type="a"],
			&[type="i"] {
				list-style-position: inside;
			}
		}

		ol:not([type="a"]):not([type="i"]) {
			counter-reset: li;
			list-style: none;

			li {
				&:before {
					counter-increment: li;
					content: counters(li, ".") ". ";
					display: inline-block;
					width: rem-calc(30);
				}
			}

			ol {
				padding-left: rem-calc(30);
				li {
					&:before {
						width: rem-calc(40);
					}
				}

				ol {
					padding-left: rem-calc(40);
					li {
						&:before {
							width: rem-calc(52);
						}
					}
				}
			}
		}
	}

	.highlightbox {
		background: #f8f9fa;
		border: 1px solid #d6d6d6;
		padding: rem-calc(30) rem-calc(38);
		margin-bottom: rem-calc(50);

		.img-holder {
			a {
				display: block;
			}
		}

		ul {
			margin-bottom: 0;

			li {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	.separator-block {
		border-top: 1px solid #a7a7a7;
		border-bottom: 1px solid #a7a7a7;
		padding-top: rem-calc(41);
		padding-bottom: rem-calc(27);
		margin-bottom: rem-calc(45);
	}

	.footnote {
		p {
			margin-bottom: rem-calc(50);
		}
	}

	.footnote-list {
		border-top: 1px solid #eeeeee;
		margin-bottom: rem-calc(38);
		padding: rem-calc(20) rem-calc(13) 0;

		a {
			margin-right: rem-calc(6);
			line-height: rem-calc(17);
		}
	}

	.footnote-item {
		display: flex;
		align-items: flex-start;
		margin-bottom: rem-calc(11);
	}

	.footnote-description {
		font-size: rem-calc(14);
	}

	// .img-mask {
	// 	overflow: auto;

	// 	&::after {
	// 		display: none;
	// 	}
	// 	img {
	// 		width: auto;
	// 	}
	// }

	// .news-post::after {
	// 	content: "";
	// 	clear: both;
	// 	display: table;
	// }

	article {
		min-width: 100%;
	}

	article,
	.news-detail {
		// h1,
		// h2,
		// h3,
		// h4,
		// h5,
		// h6 {
		// 	font-family: Helvetica, Arial, sans-serif;
		// }

		// h1,
		// h2 {
		// 	font-size: rem-calc(28);
		// 	line-height: rem-calc(40);
		// 	word-wrap: break-word;
		// }

		// .last-posts h3 {
		// 	font-size: rem-calc(22);
		// 	line-height: rem-calc(30);
		// }

		// h4 {
		// 	font-size: rem-calc(20);
		// 	line-height: rem-calc(30);
		// }

		// h5,
		// h6 {
		// 	font-size: rem-calc(18);
		// 	line-height: rem-calc(30);
		// }

		.author {
			margin-bottom: rem-calc(20);
		}

		.img.left,
		.floated-image {
			float: left;
			margin-bottom: rem-calc(10);
			margin-right: rem-calc(30);
			max-width: rem-calc(273);
		}

		.introduction {
			display: table;
			margin: rem-calc(80) 0;
			vertical-align: middle;

			img {
				margin-left: 1.25rem;
				width: 20rem;
			}
		}
	}

	.image-size-w33 {
		display: block;
		width: rem-calc(273);

		img {
			max-width: 100%;
		}
	}
	.image-size-w50 {
		display: block;
		width: rem-calc(546);

		img {
			max-width: 100%;
		}
	}
	.image-size-w100 {
		display: block;
		margin-left: auto;
		margin-right: auto;
		text-align: center;
		max-width: 20rem;

		img {
			max-width: 100%;
		}
	}

	// .headline {
	// 	margin-left: rem-calc(70);
	// }
	.magazine {
		display: block !important;
		// margin-left: rem-calc(70);
	}
	.magazine-article-outer {
		display: block !important;
	}
	.magazine-article--toggler {
		display: none;
	}
	.magazine-sidebar img {
		display: block !important;
		float: left;
		margin-bottom: 2rem;
		margin-right: 2rem;
		width: rem-calc(200);
	}

	#dokumentinhalt,
	#dokumentinhalt #dokumentinhaltcontent {
		.loesung-dialog {
			box-shadow: none;
			border: 0;
			display: block !important;
			min-height: 100%;
			height: 100%;
			max-height: none;
			opacity: 1;
			overflow: visible;
			// margin-top: 4rem 0 0 0 !important;
			margin: {
				top: 4rem;
				bottom: 0;
				right: 0;
			}
			padding: 0;
			position: relative !important;
			transform: none;
			width: 100%;
			max-width: none;

			.modal-dialog {
				max-width: none;
				margin-bottom: 0;
			}

			.modal-content {
				border: 0;
				border-radius: 0;
				box-shadow: none;
				padding: 0;
				width: 100%;
			}

			.modal-header {
				background: none transparent;
			}

			.modal-title {
				@extend .h3;
				// font-weight: 700;
			}

			.modal-body {
				padding: 0;
			}

			.close,
			.btn__close,
			.dialog-footer,
			.modal-footer,
			.aufgabefall {
				display: none;
			}

			.table-layout {
				display: block;
			}

			.table-layout-col {
				display: block;
				max-height: none;
				padding: 0;
				&:first-child {
					display: none;
				}
			}
		}

		.literature-link {
			a {
				display: none;
			}

			.literatur {
				display: block !important;
			}
		}
	}

	#dokumentinhalt {
		// FF issue
		.table,
		#dokumentinhaltcontent .table {
			display: block;
			width: calc(100% - 80px);
		}

		*:not(.author-info) > a:visited,
		a:not(.no-link):visited {
			color: #01a2b9; /* NWB not-visited link color */
		}
	}

	.selection-hide-print {
		display: none !important;
	}

	.selection-preserve-ancestor {
		display: block !important;
		box-shadow: none !important;
		border: none !important;
		margin: 0 !important;
		padding: 0 !important;
	}

	.new-page {
		page-break-before: always;
	}
}
