.affix,
.affix-bottom {
	transition: all 0.3s ease-in-out;
}

.affix-bottom {
	top: auto !important;
	@if $theme-kiehl {
		@include media("screen", ">=desktop") {
			bottom: 14rem !important;
		}
	} @else {
		@include media("screen", ">=desktop") {
			bottom: 6rem !important;
		}
	}
}
