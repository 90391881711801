.doc-title-top {
	@if $enable-theme-options {
		background-color: $secondary;
		border-radius: $border-radius;
	} @else {
		background-color: $gray-500;
	}
	color: $white;
	display: inline-block;
	font-size: rem-calc(11);
	font-weight: 600;
	margin-bottom: rem-calc(20);
	opacity: 1;
	padding: 0.25rem 0.75rem;
	text-transform: uppercase;
	transition: $transition-base;
	z-index: 0;
	@include media("screen", ">=phone") {
		margin-top: rem-calc(-13);
		position: absolute;
	}
}
