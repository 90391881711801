// LIST
.list-horizontal {
	@include media("screen", "<phone") {
		column-count: 2;
	}
	@include media("screen", ">=phone") {
		column-count: 3;
	}
	@include media("screen", ">=desktop") {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
	}

	li {
		padding-top: 0.5rem;
		padding-bottom: 0.5rem;
		@include media("screen", ">=tablet") {
			margin: 0 0.65rem;
		}
	}
}
