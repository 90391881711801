section {
	&:not(.mx-featured) {
		@if $theme-sis {
			// margin-top: rem-calc(30);

			&[class*="bg-"] {
				@include media("screen", "<widescreen") {
					padding: rem-calc(30) 0;
				}
				@include media("screen", ">=widescreen") {
					padding: rem-calc(60) 0;
				}
			}
		} @else {
			@include media("screen", "<desktop") {
				margin: 30px 0;
			}
			@include media("screen", ">=desktop", "<widescreen") {
				margin: 40px 0;
			}
			@include media("screen", ">=widescreen") {
				margin: 80px 0;
			}

			&[class*="bg-"] {
				@include media("screen", "<desktop") {
					padding: 30px 0;
				}
				@include media("screen", ">=desktop", "<widescreen") {
					padding: 40px 0;
				}
				@include media("screen", ">=widescreen") {
					padding: 80px 0;
				}
			}
		}

		&.bg-light {
			.headline-top {
				&::after {
					@include triangle(1.25rem, $light, down);
				}
			}
		}
	}

	&.mx-featured {
		// margin-top: rem-calc(-30);
		margin-top: rem-calc(45);
		padding-bottom: rem-calc(15);
		// width: calc(100% + 1.875rem);
		// width: 100%;
		// @include media("screen", "<phone") {
		// 	margin-bottom: rem-calc(-30);
		// }
		@include media("screen", ">=phone") {
			padding-bottom: rem-calc(15);
		}
		// @include media("screen", "<desktop") {
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);

		& > h2,
		& > h3 {
			margin-left: rem-calc(15);
			margin-right: rem-calc(15);
		}
		// }
		@include media("screen", ">=tablet") {
			// margin-top: rem-calc(-15);
			margin-bottom: rem-calc(45);
		}
		@include media("screen", ">=desktop") {
			// margin-top: rem-calc(-30);
			margin-bottom: 0;

			&.bg-gray-light {
				margin-left: rem-calc(-15);
				margin-right: rem-calc(-15);
				padding-left: rem-calc(15);
				padding-right: rem-calc(15);
			}
		}

		.headline-featured {
			// background-color: #dbdddf;
			border-bottom: 0;
			margin-bottom: 0;
			padding-top: rem-calc(15);
			// padding-left: rem-calc(15);
			// padding-right: rem-calc(15);
			&::before {
				content: none;
			}
			&::after {
				content: none;
				// border-top-color: #dbdddf;
			}
		}

		.owl-dots {
			@include media("screen", "<tablet") {
				margin-top: 1rem;
			}
			@include media("screen", ">=tablet") {
				margin-top: -1rem;
			}
		}

		// & + section .list {
		// 	@include media("screen", ">=tablet") {
		// 		margin-top: rem-calc(-15);
		// 	}
		// 	@include media("screen", ">=desktop") {
		// 		margin-top: rem-calc(-30);
		// 	}
		// }
	}
}

// COMMON STYLES
// .nautilog-login-target,
// .nautilog-description,
// .nautilog-login,
// .nautilog-info
// .section-light {
// 	overflow: hidden;
// 	position: relative;
// 	padding: 60px 0;
// 	&::before,
// 	&::after {
// 		content: "";
// 		left: 0;
// 		position: absolute;
// 	}
// 	&::before {
// 		margin-top: -60px;
// 	}
// 	&::after {
// 		margin-bottom: -60px;
// 	}
// }

// .section-content {
// 	@include media("screen", "<tablet") {
// 		padding: 30px;
// 	}
// 	@include media("screen", ">=tablet") {
// 		padding: 30px 50px;
// 	}
// 	@include media("screen", ">=desktop") {
// 		padding: 40px 100px;
// 	}
// }

// .nautilog-headline {
//     font-weight: 400;
//     line-height: 1.4;
//     margin-bottom: 60px;
//     &:not(h1) {
//         font-size: rem-calc(20px);
//         max-width: 730px;
//         @include media('screen', '<phone') {
//             font-size: rem-calc(16px);
//         }
//     }
// }

// .nautilog-note {
//     @include size(20px);
//     background-color: $brand-color;
//     border-radius: 100%;
//     color: $black;
//     cursor: pointer;
//     display: inline-flex;
//     font-family: Georgia, 'Times New Roman', Times, serif;
//     font-size: 16px;
//     font-style: italic;
//     font-weight: 700;
//     align-items: center;
//     justify-content: center;
//     transition: all .3s ease-in-out;
//     &::before {
//         content: 'i';
//     }
//     &:hover,
//     &:focus {
//         background-color: $black;
//         color: $white;
//     }
// }

// .nautilog-btn-group {
//     display: flex;
//     @include media('screen', '>=phone') {
//         align-items: center;
//     }
// }

// NAUTILOG DESCRIPTION
// .nautilog-description {
// 	&:not(.coming-soon) {
// 		margin-top: 20px;
// 		padding-bottom: 142px;
// 		@include media("screen", ">=phone") {
// 			padding-bottom: 202px;
// 		}
// 		@include media("screen", ">=tablet") {
// 			margin-top: 40px;
// 		}
// 		@include media("screen", ">=desktop") {
// 			padding-bottom: 178px;
// 		}
// 		@include media("screen", ">=widescreen") {
// 			padding-bottom: 170px;
// 		}
// 		&::before {
// 			@include triangle(1110px 60px, $turkis-lightest, down-left);
// 		}
// 		&::after {
// 			@include triangle(1110px 60px, $turkis-lightest, up-left);
// 		}

// 		.nautilog-content {
// 			background-color: $turkis-lightest;
// 		}

// 		.nautilog-ribbon {
// 			max-width: 245px;
// 			@include media("screen", ">=phone") {
// 				max-width: 425px;
// 			}
// 			@include media("screen", ">=desktop") {
// 				right: 80px;
// 			}
// 		}
// 	}

// 	.nautilog-headline {
// 		@include media("screen", "<phone") {
// 			font-size: rem-calc(24px);
// 		}
// 	}

// 	.nautilog-ribbon {
// 		bottom: 0;
// 		position: absolute;
// 	}

// 	p {
// 		margin-bottom: 40px;
// 		&:last-child {
// 			margin-bottom: 0;
// 		}
// 	}
// }

// SECTION DIVIDER
// .section-divider {
// 	position: relative;
// }

// @each $color, $value in $theme-colors {
// 	.section-divider.bg-#{$color} {
// 		// background-color: darken($value, 8%);
// 		background-color: $value;
// 		border-left: 0.125rem solid $white;
// 		transform: skewX(-10deg) translateX(40%);
// 		// &::before {
// 		// 	background-color: darken($value, 8%);
// 		// 	transform: skewX(-10deg);
// 		// }
// 		&::after {
// 			background-color: inherit;
// 			content: "";
// 			height: 100%;
// 			position: absolute;
// 			width: 200%;
// 		}

// 		& + div {
// 			background-color: $value;
// 		}
// 	}
// }
// SECTION DIVIDER : END
