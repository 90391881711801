.sidebar-offcanvas {
	@include media("screen", "<desktop") {
		background-color: $white;
		height: $sidebar-offcanvas_height !important;
		left: 0;
		margin-left: -100%;
		overflow-y: auto;
		@if $theme-sis {
			padding: 0;
		} @else {
			padding-top: 1rem;
		}
		position: fixed;
		top: 0;
		transition: margin-left 0.3s ease-in-out;
		// transition: all 0.3s ease-in-out;
		z-index: 1020;
	}
	@include media("screen", ">=tablet", "<desktop") {
		box-shadow: $box-shadow-lg;
		width: 50%;
	}
	@include media("screen", ">=desktop") {
		transition: opacity 0.3s ease-in-out, margin-left 0.3s ease-in-out;
		@if $theme-sis {
			margin-top: 0;
		} @else {
			margin-top: rem-calc(6);
		}
	}

	.tree {
		margin-bottom: 1rem;
	}

	.scrolling {
		@include media("screen", "<desktop") {
			@if $theme-sis {
				height: calc(100vh - 3.5rem);
			} @else {
				height: calc(100vh - 6rem);
			}

			// .tree {
			// 	margin-bottom: 1rem;
			// }
		}
		// @if $theme-kiehl {
		// 	@include media("screen", ">=desktop") {
		// 		// height: calc(100vh - 19.625rem);
		// 		height: calc(100vh - 19.25rem);
		// 	}
		// 	@include media("screen", ">=widescreen") {
		// 		// height: calc(100vh - 19.625rem);
		// 		height: calc(100vh - 19.9125rem);
		// 	}
		// } @else {
		// 	@include media("screen", ">=desktop") {
		// 		height: calc(100vh - 21.5rem);
		// 	}
		// 	@include media("screen", ">=widescreen") {
		// 		// height: calc(100vh - 21.125rem);
		// 		height: calc(100vh - 22rem);
		// 	}
		// }
	}

	&.is-open {
		@include media("screen", "<desktop") {
			margin-left: 0 !important;
		}

		.sidebar-btn-close {
			z-index: 10;
			@include media("screen", "<desktop") {
				background-color: $white;
				border-radius: 50%;
				position: fixed;
				transition-delay: 0.3s;
				opacity: 1;
				visibility: visible;
				@if $theme-sis {
					display: flex;
					align-items: center;
					justify-content: center;
					height: 2rem;
					padding: 0;
					width: 2rem;

					.icon-close {
						color: $primary;
						font-size: 1rem !important;
					}
				}
			}
			@include media("screen", ">=tablet", "<desktop") {
				right: calc(50% + 0.5rem);
			}
		}
	}
}

// #main {
// 	@include media("screen", "<desktop") {
// 		margin-right: 0;
// 		// transition: margin-right 0.3s ease-in-out;
// 	}
// }

.offcanvas-enable {
	padding-top: 0.125rem;
	// overflow: hidden;
	position: relative;
}

body {
	// &.livefeed {
	&.document {
		// ??????????????????????
		// .sidebar-offcanvas {
		// 	overflow: hidden;
		// }
		// ??????????????????????

		// Document with enabled left sidebar
		&:not(.see-also-at) {
			.sidebar-offcanvas {
				@include media("screen", ">=desktop") {
					margin-left: -33.33333%;
					opacity: 0;
					visibility: hidden;
					// transition: all 0.3s ease-in-out;
				}

				&.is-open {
					@include media("screen", ">=desktop") {
						margin-left: 0;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			// #main {
			// 	@include media("screen", ">=desktop") {
			// 		transition: margin-right 0.3s ease-in-out;
			// 	}
			// }

			.aside.end {
				@include media("screen", ">=desktop") {
					// opacity: 1;
					// margin-right: 0;
					transition: opacity 0.3s ease-in-out,
						margin-right 0.3s ease-in-out;

					&.is-open {
						opacity: 0;
						margin-right: -33.33333%;
						visibility: hidden;
					}

					// .sidebar-btn-close {
					// 	display: none;
					// }
				}
			}
		}
		// Document with enabled left sidebar : END

		// Document with enabled right sidebar
		&.see-also-at {
			.sidebar-offcanvas {
				@include media("screen", ">=desktop") {
					margin-left: 0;

					&.is-open {
						@include media("screen", ">=desktop") {
							margin-left: -33.33333%;
							opacity: 0;
							visibility: hidden;
						}
					}

					// .sidebar-btn-close {
					// 	display: none;
					// }
				}
			}

			// #main {
			// 	@include media("screen", ">=desktop") {
			// 		transition: margin-left 0.3s ease-in-out;
			// 	}
			// }
			.aside.end {
				@include media("screen", ">=desktop") {
					margin-right: -33.33333%;
					opacity: 0;
					visibility: hidden;
					transition: opacity 0.3s ease-in-out,
						margin-right 0.3s ease-in-out,
						visibility 0.3s ease-in-out;

					&.is-open {
						margin-right: 0;
						opacity: 1;
						visibility: visible;
					}

					.sidebar-btn-close {
						position: absolute;
						right: rem-calc(35);
						top: 0.625rem;
						z-index: 1;
					}
				}
			}
		}
		// Document with enabled right sidebar : END
	}
}
