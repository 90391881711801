.reformradar {
	display: inline-flex;
	align-items: center;
	margin-top: 2rem;
	@include media(">=desktop") {
		background-color: $gray-200;
		padding: rem-calc(15);
		width: rem-calc(430);
	}

	& > i,
	&-label {
		color: $gray-700;
	}

	&-label {
		@include media("screen", "<phone") {
			display: none;
		}
	}
}
