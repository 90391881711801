.document {
	// h3,
	// .h3 {
	// 	margin-top: $h3-font-size * 1.25;
	// }

	// h4,
	// .h4 {
	// 	margin-top: $h4-font-size * 1.25;
	// }

	#featured-layout {
		@include media("screen", ">=desktop") {
			padding-top: 5rem;
		}
	}

	small + .h3 {
		margin-top: 1.125rem;
	}
}

.image-size-w33 {
	height: auto;
	width: 33%;
}
.image-size-w50 {
	height: auto;
	width: 50%;
}
.image-size-full {
	height: auto;
	width: 100%;
}
[class*="image-size-"] {
	& > img {
		width: 100%;
	}
}
