// Animations

@keyframes shadow-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba($shadow-pulse-color, 0.4);
	}
	100% {
		box-shadow: 0 0 0 2.25rem rgba($shadow-pulse-color, 0);
	}
}

@keyframes shadow-pulse-dark {
	0% {
		box-shadow: 0 0 0 0 rgba($shadow-pulse-dark-color, 0.1);
	}
	100% {
		box-shadow: 0 0 0 2.25rem rgba($shadow-pulse-dark-color, 0);
	}
}

@keyframes shadow-ani {
	0% {
		box-shadow: 0 0 0 0px rgba($shadow-ani-color, 0.2);
	}
	100% {
		box-shadow: $shadow-medium;
	}
}

@keyframes animation-blink {
	0% {
		opacity: 0.1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes pulse {
	from {
		transform: scale3d(1, 1, 1);
	}

	50% {
		transform: scale3d(1.15, 1.15, 1.15);
	}

	to {
		transform: scale3d(1, 1, 1);
	}
}

@keyframes slideDown {
	0% {
		opacity: 0;
		top: -100%;
	}
	100% {
		opacity: 1;
		top: 0;
	}
}

@keyframes slideUp {
	0% {
		top: 0;
	}
	100% {
		top: -100%;
	}
}

@keyframes height-ani {
	0% {
		height: var(--heightMax);
	}
	100% {
		height: var(--heightMin);
	}
}

// Classes

.ani-pulse {
	animation-name: pulse;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
}

.ani-blink {
	animation-name: animation-blink;
	animation-duration: 0.5s;
}

.ani-shadow-pulse {
	animation-name: shadow-pulse-dark, pulse;
	animation-duration: 0.8s;
}
