.change-history {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: {
		top: 0.75rem;
		bottom: 0.5rem;
	}
	// @include media("screen", "<phone") {
	// 	padding-right: 1.5rem;
	// }
	// @include media("screen", ">=phone", "<desktop") {
	// 	padding-right: 2rem;
	// }
	@include media("screen", ">=desktop") {
		padding: {
			// top: 0.75rem;
			bottom: 0.75rem;
		}
	}

	&-wrapper {
		display: flex;
		margin-right: 0.5rem;

		// & + div > {
		// 	.btn-group {
		// 		@include media("screen", "<558px") {
		// 			margin-top: 0.25rem;
		// 		}
		// 	}
		// 	.dropdown {
		// 		@include media("screen", "<408px") {
		// 			margin-top: 0.5rem;
		// 		}
		// 	}
		// }
	}
}

.catalog-compare {
	margin: {
		top: 0.25rem;
		bottom: 0.5rem;
	}
	@include media("screen", ">=phone") {
		margin-left: auto;
	}
	@include media("screen", ">=phone", "<tablet") {
		margin-top: -2.125rem;
	}
	@include media("screen", ">=tablet") {
		margin-top: 0.625rem;
	}
	@if $theme-sis {
		@include media("screen", "<phone") {
			margin-right: 1.5rem;
		}
	} @else {
		@include media("screen", "<desktop") {
			margin-right: 1.5rem;
		}
	}
}
