.event {
	background-color: rgba($yellow, 0.25);
	border: 0.0625rem solid darken(rgba($yellow, 0.5), 4%);
	margin-top: 2rem;
	margin-bottom: 2rem;
	@include media("screen", "<phone") {
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
	}
	@include media("screen", ">=phone") {
		border-radius: $border-radius;
	}
	// padding: rem-calc(20) rem-calc(20) rem-calc(30);
	padding: rem-calc(24);
	// position: relative;
	// &::after {
	// 	content: "";
	// 	height: rem-calc(30);
	// 	position: absolute;
	// 	width: 100%;
	// }
	// &::after {
	// 	top: 100%;
	// 	background: linear-gradient(
	// 		to right bottom,
	// 		rgba($yellow, 0.25) 50%,
	// 		transparent 50%
	// 	);
	// 	left: 0;
	// }

	&-icon {
		font-size: rem-calc(24);
		margin-right: rem-calc(15);
	}

	&-title {
		font-size: $h4-font-size;
		font-weight: 600;
		margin-bottom: rem-calc(10);
	}

	&-content {
		p {
			margin-bottom: 0;
		}
	}
}
