.no-result {
	@if $theme-sis {
		background-color: rgba($cyan, 0.1);
		border: 0.0625rem solid rgba($cyan, 0.25);
	} @else {
		background-color: rgba($info, 0.1);
		border: 0.0625rem solid rgba($info, 0.25);
	}
	padding: 1.5rem;
	@include media("screen", "<phone") {
		text-align: center;
		margin-left: rem-calc(-15);
		margin-right: rem-calc(-15);
	}
	@include media("screen", ">=phone") {
		border-radius: $border-radius;
	}

	&--head {
		background-color: rgba($white, 0.75);
		width: rem-calc(86);
		@include media("screen", "<phone") {
			margin-bottom: 1rem;
		}

		[class*="icon-"] {
			@if $theme-sis {
				color: rgba($cyan, 0.4);
			} @else {
				color: rgba($info, 0.4);
			}
		}
	}

	&--content {
		@include media("screen", ">=phone") {
			width: calc(100% - 5.375rem - 1.5rem);
		}

		a {
			font-weight: 600;
		}
	}
}
