.magazine {
	padding: 0;
	position: relative;
	@include media("screen", ">=tablet") {
		align-items: center;
	}
	@include media("screen", ">=desktop") {
		min-height: rem-calc(250);
	}

	&-item {
		@include media("screen", "<phone") {
			// &:nth-of-type(2n) {
			&:nth-child(2n of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-100% - 30px);
				}
			}
		}
		@include media("screen", ">=phone") {
			// &:nth-of-type(4n + 2) {
			&:nth-child(4n + 2 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-100% - 30px);
				}
			}
			// &:nth-of-type(4n + 3) {
			&:nth-child(4n + 3 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-200% - 60px);
				}
			}
			// &:nth-of-type(4n + 4) {
			&:nth-child(4n + 4 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-300% - 90px);
				}
			}
		}
		@include media("screen", ">=desktop") {
			// &:nth-of-type(6n + 1) {
			&:nth-child(6n + 1 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: 0;
				}
			}
			// &:nth-of-type(6n + 2) {
			&:nth-child(6n + 2 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-100% - 30px);
				}
			}
			// &:nth-of-type(6n + 3) {
			&:nth-child(6n + 3 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-200% - 60px);
				}
			}
			// &:nth-of-type(6n + 4) {
			&:nth-child(6n + 4 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-300% - 90px);
				}
			}
			// &:nth-of-type(6n + 5) {
			&:nth-child(6n + 5 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-400% - 120px);
				}
			}
			// &:nth-of-type(6n + 6) {
			&:nth-child(6n + 6 of :not(.is-hidden)) {
				.magazine-card {
					margin-left: calc(-500% - 150px);
				}
			}
		}
	}

	&-link {
		color: $gray-900;
		display: block;
		padding-bottom: rem-calc(15);
		position: relative;
		max-width: 100%;
		@include media("screen", ">=480px") {
			padding-bottom: rem-calc(4);
		}
		@include media("screen", ">=widescreen") {
			padding-bottom: rem-calc(30);
		}
		&::before,
		&::after {
			content: "";
			bottom: rem-calc(-24);
			left: 50%;
			position: absolute;
			transform: translateX(-50%);
			transition: bottom 0.2s ease-in-out;
		}

		&[aria-expanded="true"] {
			&::before,
			&::after {
				bottom: 0;
			}
			&::before {
				@include triangle(24px, $gray-300, up);
			}
			&::after {
				@include triangle(24px, $gray-200, up);
				margin-bottom: -1px;
				z-index: 11;
			}
		}

		&.collapsed {
			.magazine-cover {
				&::before,
				&::after {
					opacity: 0;
				}
			}
		}

		&:hover,
		&[aria-expanded="true"] {
			.magazine-cover {
				@if $theme-linde {
					box-shadow: $box-shadow;
				}
				&::before {
					opacity: 0.8;
				}
				&::after {
					opacity: 1;
				}
			}
		}
	}

	&-cover {
		// box-shadow: $box-shadow-sm;
		@if $theme-linde {
			box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
		}
		height: auto;
		overflow: hidden;
		position: relative;
		transition: $transition-base;
		width: 100%;
		@include media("screen", "<phone") {
			// max-height: calc(#{rem-calc(72)} + 40vw);
			max-height: 59vw;
		}
		@include media("screen", ">=phone") {
			// max-height: calc(#{rem-calc(52)} + 19vw);
			max-height: 28vw;
			max-width: 100%;
		}
		@include media("screen", ">=tablet") {
			// max-height: calc(#{rem-calc(52)} + 23vw);
			max-height: 29.5vw;
		}
		@include media("screen", ">=desktop") {
			max-height: rem-calc(183);
		}
		@include media("screen", ">=widescreen") {
			max-height: rem-calc(226);
		}
		@include media("screen", ">=x-widescreen") {
			max-height: rem-calc(247);
		}

		&::before,
		&::after {
			@include animate(all, 0.3s);
			opacity: 0;
			position: absolute;
		}

		&::before {
			@include size(100%);
			content: "";
			background-color: $brand-color;
			left: 0;
			top: 0;
		}

		&::after {
			content: "\e947";
			background-color: $white;
			border-radius: 50%;
			color: $gray-500;
			font-family: "iconfont";
			font-size: rem-calc(32);
			display: flex;
			align-items: center;
			justify-content: center;
			height: rem-calc(64);
			width: rem-calc(64);
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
		}

		& > img {
			height: auto;
			// height: 100%;
			width: 100%;
			@include media("screen", "<phone") {
				@include size(100%);
			}
			@include media("screen", ">=phone") {
				height: 100%;
				max-width: 100%;
			}
			// @include media("screen", ">=desktop") {
			// 	max-height: rem-calc(183);
			// }
			// @include media("screen", ">=widescreen") {
			// 	max-height: rem-calc(225);
			// }
			// @include media("screen", ">=x-widescreen") {
			// 	max-height: rem-calc(246);
			// }
		}
	}

	&-content {
		display: inherit;
		flex-direction: column;
	}

	&-text {
		@include media("screen", ">=desktop", "<widescreen") {
			max-height: rem-calc(100);
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&-title {
		font-size: rem-calc(13);
		height: rem-calc(48);
		line-height: 1.2;
		margin-top: 1rem;
		text-align: center;
		@include media("screen", ">=phone", "<widescreen") {
			height: rem-calc(60);
		}
		@include media("screen", ">=widescreen") {
			font-size: rem-calc(16);
		}
	}

	// KIOSK COLLAPSIBLE COVER
	&-card {
		position: relative;
		z-index: 10;
		@include media("screen", "<desktop") {
			width: calc(100vw - 30px);
		}
		@include media("screen", ">=desktop") {
			width: 930px;
		}
		@include media("screen", ">=widescreen") {
			width: 1110px;
		}
		@include media("screen", ">=x-widescreen") {
			width: 1198px;
		}

		&-body {
			background-color: $gray-200;
			border: 1px solid $gray-300;
			border-radius: rem-calc(10);
			padding: rem-calc(60) rem-calc(20) rem-calc(20) rem-calc(20);
			@include media("screen", ">=phone") {
				padding: 1rem 1.25rem;
			}

			&--title {
				display: none;
				@include media("screen", ">=phone") {
					display: block;
					font-size: 1rem;
					max-width: 70%;
				}
				@include media("screen", "<tablet") {
					font-weight: 600;
				}
				@include media("screen", ">=tablet") {
					font-size: 1.25rem;
					max-width: 65%;
				}
				@include media("screen", ">=desktop") {
					max-width: 50%;
				}
				@include media("screen", ">=x-widescreen") {
					font-size: 1.5rem;
				}
			}

			.btn-group {
				position: absolute;
				top: 0.8125rem;
				z-index: 20;
				@include media("screen", "<phone") {
					right: 50%;
					transform: translateX(50%);
				}
				@include media("screen", ">=phone") {
					right: rem-calc(60);
					top: 0.85rem;
				}
				@include media("screen", ">=tablet") {
					right: rem-calc(139);
				}
				@include media("screen", ">=desktop") {
					right: rem-calc(325);
				}
				@include media("screen", ">=widescreen") {
					right: 31.5%;
				}
			}

			.dropdown-menu {
				overflow-x: hidden;
				overflow-y: auto;
				min-width: 7rem;
			}

			.owl-stage {
				max-height: rem-calc(336);
			}

			@if $theme-linde {
				.dropdown-toggle {
					border-width: rem-calc(1);
				}
			}
		}

		&-container {
			display: flex;
			align-items: center;
			margin-left: -15px;
			margin-right: -15px;
			width: calc(100% + 35px);
		}

		&-close {
			background-color: $white !important;
			border: 0;
			border-radius: rem-calc(18);
			color: $gray-500;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			font-family: "iconfont";
			font-size: rem-calc(12);
			height: rem-calc(36);
			width: rem-calc(36);
			outline: none;
			outline-style: none;
			position: absolute;
			right: rem-calc(10);
			top: rem-calc(10);
			z-index: 10;
			&::before {
				@include animate(color, 0.3s);
			}
			&:hover::before {
				color: $gray-800;
			}
		}

		&--cover {
			margin: 0 rem-calc(10);
			text-align: center;
			@include media("screen", "<375px") {
				min-width: 112px;
			}
			@include media("screen", "<phone") {
				max-width: 172px;
				&:not(.last-cover) {
					transform: translateX(5px);
				}
			}
			@include media("screen", ">=phone") {
				&:not(.actual-cover) {
					transform: scale(0.95) translateX(5px);
				}
			}
			@include media("screen", ">=tablet") {
				width: 150px;
			}
			@include media("screen", ">=desktop") {
				width: 132px;
			}

			&.actual-cover {
				@if $theme-linde {
				} @else {
					margin: rem-calc(15) rem-calc(10);
					@include media("screen", ">=tablet") {
						margin: rem-calc(15);
					}
				}

				a {
					@if $theme-linde {
						position: relative;
						&::before {
							background: linear-gradient(
								to left bottom,
								$ribbon-new_bg 50%,
								transparent 50%
							);
							content: "";
							// color: $ribbon-new_color;
							// display: flex;
							// align-items: center;
							// justify-content: center;
							height: rem-calc(64);
							overflow: hidden;
							position: absolute;
							right: rem-calc(-2);
							top: rem-calc(-2);
							width: rem-calc(64);
						}
						&::after {
							box-shadow: $ribbon-new_box-shadow;
							content: "Neu";
							color: $ribbon-new_color;
							display: block;
							font-size: $ribbon-new_font-size;
							font-weight: $ribbon-new_font-weight;
							height: 1.25rem;
							text-transform: $ribbon-new_text-transform;
							line-height: 1;
							// padding: rem-calc(10);
							position: absolute;
							right: rem-calc(-2);
							top: rem-calc(-2);
							text-align: center;
							transform: rotate(45deg) translate(24px, -3px);
							width: 5.5rem;
						}
					} @else {
						// margin: rem-calc(15) rem-calc(10);
						position: relative;
						@include media("screen", "<375px") {
							transform: scale(1.025) translateX(5px);
						}
						@include media("screen", ">=375px", "<phone") {
							transform: scale(1.05) translateX(5px);
						}
						// @include media("screen", ">=tablet") {
						// 	margin: rem-calc(15);
						// }
						&::before {
							content: $magazine-cover-flag;
							background-color: $red;
							color: $white;
							display: block;
							font-size: rem-calc(12);
							font-weight: 600;
							height: rem-calc($magazine-cover-flag_height);
							left: rem-calc(-5);
							width: rem-calc($magazine-cover-flag_width);
							line-height: rem-calc(24);
							position: absolute;
							top: rem-calc(-4);
							text-align: center;
						}
						&::after {
							@include media("screen", ">=375px") {
								$magazine-cover-flag_distance: $magazine-cover-flag_width -
									5 !default;
								@include triangle(12px 24px, $red, down-left);
								content: "";
								left: rem-calc($magazine-cover-flag_distance);
								position: absolute;
								top: rem-calc(-4);
							}
						}
					}
				}

				img {
					box-shadow: $shadow-large;
					margin-top: rem-calc(10);
					margin-bottom: rem-calc(10);
				}

				.magazine-card--title {
					color: $black;
					font-size: rem-calc(15);
					margin-top: rem-calc(5);
					margin-bottom: 0;
				}
			}

			a {
				@include size(100%);
				display: block;
			}

			img {
				display: block;
				height: auto;
				margin-top: 0.625rem;
				margin-bottom: 0.75rem;
				@include media("screen", ">=phone") {
					margin-top: 0.75rem;
					margin-bottom: 1rem;
				}
				max-width: 100%;
			}
		}

		&--title {
			color: $gray-900;
			display: inline-table;
			font-size: rem-calc(14);
			line-height: 1.25;
		}
	}

	&.not-owned {
		// opacity: 0.45;

		.magazine-cover,
		.magazine-title {
			opacity: 0.45;
		}
	}

	&-carousel {
		.owl-stage-outer {
			// height: rem-calc(253);
			max-height: 22rem;
			min-width: 100%;
		}

		.owl-item {
			opacity: 0;
			transition: opacity 0.3s ease-in-out;

			&.active {
				opacity: 1;
			}
		}
	}
}
