.teasers-top {
	.owl-stage-outer {
		@include media("screen", ">=tablet") {
			padding-bottom: 2.5rem;
		}
	}

	.owl-stage {
		display: flex;
	}

	h3 {
		@extend .post-title;
	}
}

.teaser-top {
	background-color: $light;
	color: $teaser-top_color;
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: rem-calc(15);
	padding: rem-calc(30) rem-calc(20) rem-calc(10) rem-calc(20);
	&:hover,
	&:focus {
		color: $black;

		h3 {
			color: $teaser-top_color-hover;
		}
	}

	&-header {
		display: inherit;
		justify-content: center;
		padding-bottom: rem-calc(30);
		position: relative;

		.icon {
			background-color: $teaser-top-icon_bg;
			border-radius: 50%;
			color: $teaser-top-icon_color;
			display: inherit;
			align-items: center;
			justify-content: center;
			font-size: rem-calc(42);
			height: rem-calc(80);
			width: rem-calc(80);
			margin: 0;
		}
	}

	&-badge {
		background: linear-gradient(
			to right bottom,
			$teaser-top-badge_bg 50%,
			transparent 50%
		);
		color: $teaser-top-badge_color;
		display: flex;
		align-items: center;
		justify-content: center;
		height: rem-calc(128);
		left: rem-calc(-20);
		overflow: hidden;
		position: absolute;
		top: rem-calc(-30);
		width: rem-calc(128);

		&-inner {
			font-size: rem-calc(12);
			line-height: 1;
			padding: rem-calc(15);
			text-align: center;
			transform: rotate(-45deg) translateY(-50%);
		}

		span {
			display: block;
			font-weight: 700;
		}
	}

	&-subtitle {
		color: $gray-700;
		font-size: rem-calc(11);
		font-weight: 600;
		margin-bottom: rem-calc(15);
		text-transform: uppercase;
	}

	.list-styled {
		margin-bottom: 0;
		padding: 0;
	}

	.text-link {
		margin-top: auto;
	}
}
