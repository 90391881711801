.search {
	display: flex;
	align-items: center;
	margin: {
		bottom: 0;
	}
	position: relative;
	z-index: 1100;
	@include media("screen", "<desktop") {
		position: fixed;
		top: -7.75rem; //-7.25rem;
		width: 100%;

		&.is-active {
			background-color: $gray-100;
			top: 0 !important;
		}
	}

	@if $theme-linde {
		@include media("screen", ">=desktop") {
			width: 33%;
		}
		@include media("screen", ">=widescreen") {
			width: 40%;
		}
	} @else {
		@include media("screen", ">=desktop") {
			width: 40%;
		}
	}

	.form-control:not(textarea) {
		@if $theme-kiehl & $theme-linde {
			font-size: rem-calc(16);
		} @else {
			font-size: rem-calc(14);
		}
		height: 3rem;
		// padding-right: rem-calc(58);
		padding-right: rem-calc(72);
		&::placeholder {
			@if $theme-kiehl & $theme-linde {
				font-size: rem-calc(16);
			} @else {
				font-size: rem-calc(14);
			}
		}
	}

	.btn {
		color: $primary;
		position: absolute;
		&:focus {
			box-shadow: none;
		}
		&:active {
			--bs-btn-active-border-color: transparent;
		}
	}

	.btn-search {
		// display: flex;
		font-size: 1.5rem;
		right: 1rem;
		// @if $theme-nwb {
		// 	bottom: 1.25rem;
		// 	@include media("screen", ">=desktop") {
		// 		bottom: 0.25rem;
		// 	}
		// }
		@include media("screen", ">=desktop") {
			// top: rem-calc(5);
			right: 0;
		}

		// .icon-paper-plane {
		// 	font-size: 1rem;
		// 	position: relative;
		// 	transform: rotate(45deg);
		// }
	}

	.btn-close {
		font-size: 0.75rem;
		right: 0;
		top: 0.25rem;

		@if $theme-sis {
			display: none !important;
		}
	}

	input[type="search"]::-webkit-search-cancel-button {
		cursor: pointer;
		position: relative;
		right: -0.875rem;
	}

	// Nur wegen des Firefoxs
	// input[type="search"] {
	// 	position: relative;
	// 	appearance: none;

	// 	&::-webkit-search-cancel-button {
	// 		-webkit-appearance: none;
	// 		// -moz-appearance: search-cancel-button;
	// 	}

	// 	&:focus {
	// 		& + .bootstrap-autocomplete + .search-cancel-button {
	// 			cursor: pointer;
	// 			opacity: 1;
	// 		}
	// 	}
	// }

	// .search-cancel-button {
	// 	color: $primary;
	// 	display: inline-flex;
	// 	align-items: center;
	// 	justify-content: center;
	// 	height: 100%;
	// 	width: 1.5rem;
	// 	opacity: 0;
	// 	position: absolute;
	// 	top: 50%;
	// 	transform: translateY(-50%);
	// 	right: 3.75rem;
	// 	&::before {
	// 		content: "✕";
	// 	}
	// }
	// Nur wegen des Firefoxs : END
}

.btn-search {
	display: flex;

	.icon-paper-plane {
		font-size: 1rem;
		position: relative;
		transform: rotate(45deg);
	}
}

body:not(.home) {
	.search {
		@include media("screen", "<desktop") {
			background-color: $white;
			box-shadow: $box-shadow;
			left: 0;
			padding: 3rem 1rem 1rem; //2rem 1rem 1rem;
			transition: top 0.3s ease-in-out;
		}
	}
}

.home {
	&:not(.is-top) {
		.search {
			left: 50%;
			position: absolute;
			// @if $theme-nwb {
			// 	top: 4.5rem;
			// } @else if $theme-kiehl {
			// 	top: 4.5rem;
			// } @else {
			// 	top: 6rem;
			// }
			top: $search_top;
			transform: translateX(-50%);
			transition: none;
			z-index: 10;
			@include media("screen", "<phone") {
				width: calc(100% - 2rem);
			}
			@include media("screen", ">=phone") {
				width: 80%;
			}
			@include media("screen", ">=tablet") {
				width: 70%;
			}
			@include media("screen", ">=widescreen") {
				width: 50%;
			}

			@if $header-claim {
				@include media("screen", "<485px") {
					top: 12.75rem;
					// top: $search_top + 6.75rem;
				}
				@include media("screen", ">=485px") {
					top: 9rem;
					// top: $search_top + 3rem;
				}
				@include media("screen", ">=desktop") {
					top: 11rem;
					// top: $search_top_desktop + 3.5rem;
				}
			} @else {
				@include media("screen", ">=desktop") {
					// top: 7.5rem;
					top: $search_top_desktop;
				}
			}

			.btn-search {
				right: 0;
			}

			.btn-close {
				display: none;
			}
		}

		.header-right {
			.btn-search {
				display: none;
			}
		}

		.searchbar {
			margin-top: 1rem;
		}
	}

	@if $theme-nwb {
		&.logedout:not(.is-top) {
			.search {
				@include media("screen", "<485px") {
					top: 13.75rem !important;
				}
				@include media("screen", ">=485px") {
					top: 10rem !important;
				}
				@include media("screen", ">=desktop") {
					top: 12rem !important;
				}
			}
		}
	}

	&.is-top {
		.search {
			@include media("screen", "<desktop") {
				background-color: $white;
				box-shadow: $box-shadow;
				left: 0;
				padding: 2.5rem 1rem 1rem;

				&.is-active {
					transition: top 0.3s ease-in-out;
				}
			}
		}
	}
}

// Autocomplete
.bootstrap-autocomplete {
	max-width: 100%;

	.dropdown-item {
		display: flex;
		align-items: center;
		justify-content: space-between;

		[class*="icon-"] {
			color: $primary;
		}

		// .pl-4 {
		.ps-4 {
			margin-left: 0.125rem;
		}

		.icon-remove {
			&:hover,
			&:focus {
				color: $danger;
			}
		}
	}
}
