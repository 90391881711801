$user-profile-img_size: rem-calc(32) !default;
$user-profile-img_size_lg: rem-calc(48) !default;

.user-profile-img {
	height: auto;
	max-width: 100%;
}

// Mobile
.navbar-toggler {
	@if $theme-linde {
		@include media("screen", "<411px") {
			padding-right: 0;
		}
	} @else {
		@include media("screen", "<desktop") {
			padding-right: 0;
		}
	}

	// .toggler-desc {
	// 	@include media("screen", "<desktop") {
	// 		display: flex;
	// 		justify-content: center;
	// 		height: $user-profile-img_size;
	// 		max-width: rem-calc(120);
	// 	}
	// }
}

// Desktop
// .user-menu {
// 	.btn-toggle {
// .user-profile {
// 	@include media("screen", ">=desktop") {
// 		display: flex;
// 		justify-content: center;
// 		margin-top: -0.25rem;
// 		height: $user-profile-img_size_lg;
// 		max-width: rem-calc(200);
// 	}
// }
// 	}
// }

.user-profile {
	@include media("screen", "<desktop") {
		display: flex;
		justify-content: center;
		height: $user-profile-img_size;
		max-width: rem-calc(120);
	}
	@include media("screen", ">=desktop") {
		display: flex;
		justify-content: center;
		margin-top: -0.25rem;
		height: $user-profile-img_size_lg;
		max-width: rem-calc(200);
	}
}
