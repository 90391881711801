$post-title_color: $gray-900 !default;
$post-title_color_hover: $primary !default;
$post-title_font-size: $h3-font-size !default;
$post-title_font-size_desktop: $h5-font-size !default;
$post-title_font-weight: null !default;

$post-head_color: $gray-700 !default;
$post-head-unread_bg-color: $cyan !default;

// Post Hit
$post-hit-dms_color: $info !default;
$post-hit-dms_font-size: 0.75rem !default;
$post-hit-dms_text-transform: uppercase !default;

$post-hit-link_color: $primary !default;

$post-hit_type_color: $gray-500 !default;
$post-hit_type_border: 0.0625rem solid $gray-500 !default;
$post-hit_type_border-radius: $border-radius !default;
$post-hit_type_bg: null !default;
$post-hit_type_font-size: 0.75rem !default;
$post-hit_type_font-weight: 400 !default;
$post-hit_type_text-transform: uppercase !default;

// Post View
$post-view_bg: lighten($blue-lightest, 4%) !default;
$post-view_divider_bg: darken($post-view_bg, 8%) !default;
$post-view_border-radius: $border-radius !default;
$post-view_padding: 0.5rem 0.75rem 0.5rem 1.25rem !default;
$post-view_title_color: rgba($blue, 0.6) !default;
$post-view_title_font-weight: 500 !default;
$post-view_icon_color: rgba($blue, 0.3) !default;
$post-view_icon_color-active: $primary !default;
$post-view_icon_size: 1.5rem !default;
