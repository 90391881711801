.user-menu {
	// margin-left: rem-calc(5);
	@if $theme-linde {
		@include media("screen", ">=desktop") {
			margin-left: 2rem;
			margin-right: 0;
			padding-top: 0; //rem-calc(5);
		}
		// @include media("screen", ">=widescreen") {
		// 	margin-left: 3rem;
		// }
	}

	&-title {
		color: $offcanvas-link_color;
		font-size: $h3-font-size;
		font-weight: $headings-font-weight;
		margin-bottom: $headings-margin-bottom;
		@include media("screen", ">=desktop") {
			color: $headings-color;
		}
	}

	.border-bottom-radius {
		@if $theme-linde {
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		} @else {
			border-bottom-left-radius: $border-radius-lg;
			border-bottom-right-radius: $border-radius-lg;
		}
	}

	.navbar-nav {
		@include media("screen", "<desktop") {
			width: 100%;
		}
	}

	.btn-toggle,
	.navbar-toggler {
		border: $btn-toggle-border;
		border-color: transparent;
		// color: $btn-toggle-color;
		display: flex;
		align-items: center;
		@if $theme-linde {
			// align-items: flex-start;
			// font-size: rem-calc(12);
			font-weight: 300;
		} @else {
			font-size: rem-calc(12);
		}
		line-height: 1.25;

		@if $theme-linde {
			--bs-btn-color: var(--bs-primary);
			padding: rem-calc(4) rem-calc(10);
			// } @else if $theme-nwb {
			// 	padding: rem-calc(10) 0 rem-calc(10) rem-calc(10);
			// } @else if $theme-kiehl {
			// 	padding: rem-calc(10) 0 rem-calc(10) rem-calc(10);
		} @else {
			padding: rem-calc(10) rem-calc(10) rem-calc(10) 0;
		}

		&:hover {
			@if $theme-linde {
				// color: $blue-middle;
				border-color: transparent;
			} @else if $theme-sis {
				color: $brand-color-light;
			} @else {
				// border: $btn-toggle-border;
				// border-radius: $btn-toggle-border-radius;

				i {
					@if not($theme-kiehl) {
						color: $header-btn_icon_color;
					}
				}

				// .user-menu--name,
				// .user-menu--product {
				// 	color: $blue-middle;
				// }
			}
		}
		&:focus {
			box-shadow: none;

			// @if not($theme-linde) {
			// 	border: $btn-toggle-border;
			// 	border-radius: $btn-toggle-border-radius;
			// }
		}
		&:active,
		// &.active,
		&.show {
			@if $theme-linde {
				// color: $blue-middle;

				// .user-menu--name,
				// .user-menu--product {
				// 	color: $blue-middle;
				// }

				i {
					color: $header-btn_icon_color;
				}
			}

			border-color: transparent;
		}

		i {
			@if $theme-linde {
				color: $header-btn_icon_color;
			}
			display: block;
			margin: 0;
			@include media("screen", ">=desktop") {
				display: inline-block;
				vertical-align: middle;
			}
		}

		// .icon-user {
		// 	background-color: $btn-toggle_icon_bg;
		// 	border-radius: 50%;
		// 	color: $btn-toggle_icon_color;
		// 	font-size: 1rem;
		// 	padding: rem-calc(5);
		// }

		// .far,
		.fal,
		.fas {
			font-size: rem-calc(24);
			@include media("screen", ">=desktop") {
				font-size: rem-calc(30);
			}
		}

		.icon-user {
			border-radius: 50%;
			font-size: 1rem;
			@if $theme-nwb & $theme-kiehl {
				color: $btn-toggle_icon_color;
				padding: rem-calc(7);
				@include media("screen", ">=desktop") {
					font-size: rem-calc(20);
				}

				// @at-root body:not(.logedout) & {
				// 	color: $white;
				// }
			} @else {
				background-color: $btn-toggle_icon_bg;
				color: $btn-toggle_icon_color;
				padding: rem-calc(5);
			}

			@at-root body:not(.logedout) & {
				color: $white;
			}

			@if $theme-nwb {
				border: 0.0625rem solid $gray-900;
				height: rem-calc(32);
				width: rem-calc(32);
				@include media("screen", ">=desktop") {
					height: rem-calc(36);
					width: rem-calc(36);
				}
				@at-root body:not(.logedout) & {
					background-color: $gray-900;
				}
			}
			@if $theme-kiehl {
				border: 0.0625rem solid $primary;
				@at-root body:not(.logedout) & {
					background-color: $primary;
				}
			}
			@if $theme-sis {
				border: 0.0625rem solid $primary;
				@at-root body:not(.logedout) & {
					background-color: $primary;
				}
				@at-root body.logedout & {
					color: $primary;
				}
			}
		}

		// 	// .far,
		// 	.fal,
		// 	.fas {
		// 		font-size: rem-calc(33);
		// 	}

		@if $theme-nwb {
			// position: relative;
			// z-index: 1010;

			&:not(.show) .icon-user {
				@at-root body:not(.logedout) & {
					color: $yellow;
				}
			}
		}

		@if not($theme-linde) {
			position: relative;
			z-index: 1010;

			@include media("screen", ">=desktop") {
				border-radius: 0.625rem 0.625rem 0 0;
				border-style: solid;
				border-width: 0.0625rem 0.0625rem 0 0.0625rem;
				margin-left: 0.5rem;
				padding: {
					left: 1rem;
					right: 1rem;
				}
				transition: none !important;

				&.show {
					border-color: $gray-400;
					background-color: $white;
				}
			}
		}

		&.show {
			background-color: $btn-toggle-active-bg;
			color: $btn-toggle-active-color;
		}
	}

	// @if $theme-linde {
	// 	.btn-toggle {
	// 		align-items: flex-start;
	// 	}
	// }

	.dropdown-menu {
		box-shadow: $box-shadow;
		margin-left: rem-calc(1);
		padding: 0;
		@include media("screen", "<desktop") {
			background-color: transparent;
			border: 0;
			border-radius: 0;
			box-shadow: none;
			display: block;
		}
		@include media("screen", ">=desktop") {
			width: rem-calc(400);
		}
		@if $theme-linde {
			top: calc(100% + 0.625rem);
			@include media("screen", ">=desktop") {
				&::before {
					@include triangle(22px, $gray-300, up);
					content: "";
					position: absolute;
					left: auto;
					right: rem-calc(39);
					top: rem-calc(-12);
				}
				&::after {
					@include triangle(20px, $white, up);
					content: "";
					position: absolute;
					left: auto;
					right: rem-calc(40);
					top: rem-calc(-10);
				}
			}
		} @else {
			top: calc(100% - 0.188rem); // 100% - 3px

			@include media("screen", ">=desktop") {
				--bs-dropdown-border-radius: 0.625rem 0 0.625rem 0.625rem;
			}
		}
	}

	.dropdown-item {
		padding: rem-calc(20) 0;
		position: relative;
		white-space: normal;
		@include media("screen", ">=desktop") {
			padding-left: rem-calc(20);
			padding-right: rem-calc(20);
		}

		&:last-child {
			@if $theme-linde {
				border-bottom-left-radius: $border-radius;
				border-bottom-right-radius: $border-radius;
			} @else {
				border-bottom-left-radius: $border-radius-lg;
				border-bottom-right-radius: $border-radius-lg;
			}
		}

		&:not(:last-child) {
			border-bottom: $nav-link_border-bottom;

			@include media("screen", ">=desktop") {
				border-bottom-color: $gray-200;
			}
		}

		[class*="icon-"],
		.far,
		.fal {
			display: inline-flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			width: 100%;
		}

		[class*="icon-"] {
			font-size: rem-calc(20);
		}

		.far,
		.fal {
			font-size: rem-calc(24);
		}

		&--icon {
			@include animate(background-color, 0.25s);
			background-color: $nav-link-icon_bg;
			border-radius: 50%;
			color: $nav-link-icon_color;
			height: rem-calc(48);
			top: rem-calc(18);
			width: rem-calc(48);

			@include media("screen", ">=desktop") {
				background-color: $dropdown-item-icon-bg;
				border: $dropdown-item-icon-border;
				color: $dropdown-item-icon-color;
			}
		}

		&--title,
		&--subtitle {
			padding-left: rem-calc(66);
		}

		&--title {
			// color: $nav-link_color;
			transition: color 0.15s ease-in-out;
			@include media("screen", "<desktop") {
				color: $offcanvas-link_color;
			}
		}

		&--subtitle {
			color: $offcanvas-desc_color;
			transition: color 0.15s ease-in-out;

			@include media("screen", ">=desktop") {
				color: $nav-link-desc_color;
			}
		}

		&:hover,
		&:focus {
			@include media("screen", "<desktop") {
				background-color: transparent;
			}
			@include media("screen", ">=desktop") {
				.dropdown-item--icon {
					background-color: $nav-link-icon_bg-hover;
				}

				.dropdown-item--subtitle {
					color: $nav-link-desc_color-hover;
				}
			}

			.drop-list-holder {
				visibility: visible;
				height: 100%;
			}

			.drop-list {
				transform: translate(0);
				opacity: 1;
			}
		}
	}

	& + .navbar {
		// height: rem-calc(38);

		.navbar-toggler {
			// font-size: 1rem; //$navbar-toggler-font-size
			&:focus {
				outline: none;
			}

			i {
				// @if $theme-kiehl {
				// 	@include media("screen", "<desktop") {
				// 		color: $secondary !important;
				// 	}
				// } @else if $theme-linde {
				// } @else {
				// 	color: $gray-900;
				// }

				@if $theme-kiehl {
					color: $secondary;
				} @else if not($theme-linde) {
					color: $gray-900;
				}
			}
		}
	}

	@if $theme-kiehl {
		@include media("screen", "<desktop") {
			.btn-primary {
				border: 0.0625rem solid $raster2;
			}

			.link {
				color: $raster3;

				&:focus {
					color: $raster4;
				}
			}
		}
	}
}

.user-menu-btn {
	display: flex;
	flex-direction: column;
	text-align: left;
	margin: 0 rem-calc(7);
	// @if $theme-linde {
	// 	margin-top: -0.25rem;
	// }
}

.user-menu--name {
	color: $btn-toggle-color;
	@if $theme-linde {
		font-size: rem-calc(14);
	}
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	// max-width: 9rem;
	@if $theme-sis {
		max-width: 8rem;
	} @else {
		max-width: 9rem;
	}
}

.user-menu--product {
	color: $btn-toggle_product_color;
	@if $theme-linde {
		font-weight: 500;
		// font-size: rem-calc(12);
		// text-transform: uppercase;
	} @else {
		font-weight: 700;
	}
	display: inline-flex;
	align-items: center;
	justify-content: space-between;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	// max-width: 9rem;
	@if $theme-sis {
		max-width: 8rem;
	} @else {
		max-width: 9rem;
	}

	[class*="icon-"] {
		font-size: rem-calc(10);
	}

	.far {
		font-size: rem-calc(14);
	}
}

.bg-login {
	border-top: $nav-link_border-bottom;

	@include media("screen", "<desktop") {
		margin-top: 1.5rem;
		width: 85%;
	}
	@include media("screen", ">=desktop") {
		background-color: $light;
		border-top: 0;
	}
}

// .offcanvas-wrap {
// 	order: 2;

// 	.fal {
// 		font-size: rem-calc(28);
// 	}
// }
