.sidebar-btn {
	background-color: $sidebar-btn_bg;
	cursor: pointer;
	position: fixed;
	width: rem-calc(32);
	z-index: 1011;
	// @include media("screen", "<phone") {
	@if $theme-linde {
		// @include media("screen", "<phone") {
		@include media("screen", "<phone") {
			top: $sidebar-btn_top-sm;
		}
		@include media("screen", ">=phone", "<desktop") {
			top: 8.3125rem;
		}
		// } @else if $theme-sis {
		// 	@include media("screen", "<desktop") {
		// 		top: $sidebar-btn_top-sm;
		// 	}
	} @else {
		@include media("screen", "<desktop") {
			top: $sidebar-btn_top-sm;
		}
	}
	// @include media("screen", ">=phone", "<desktop") {
	// 	top: $sidebar-btn_top-sm + 0.75rem;
	// }
	@include media("screen", "<desktop") {
		border-radius: 0.25rem 0 0 0.25rem;
		height: rem-calc(32);
		right: 0;
	}
	@include media("screen", ">=desktop") {
		border-radius: 0 0.25rem 0.25rem 0;
		height: rem-calc(192);
		left: 0;
		top: $sidebar-btn_top-desktop;
	}
	@include media("screen", ">=1600px") {
		border-radius: 0.5rem 0 0 0.5rem;
		left: auto;
		margin-left: rem-calc(-80);
		top: $sidebar-btn_top-desktop - 0.5rem;
		width: rem-calc(42);
	}

	&-close {
		cursor: pointer;
		@include media("screen", "<desktop") {
			opacity: 0;
			padding: rem-calc(10);
			right: rem-calc(5);
		}
		@include media("screen", ">=desktop") {
			display: none;
		}
	}

	&--title {
		color: $sidebar-btn-icon_color;
		display: flex;
		align-items: center;
		@if $theme-kiehl {
			font-size: rem-calc(14);
		} @else {
			font-size: rem-calc(12);
			font-weight: 600;
			@include media("screen", ">=1600px") {
				font-size: rem-calc(13);
			}
		}
		left: 0;
		letter-spacing: rem-calc(-0.5);
		position: absolute;
		transform: rotate(-90deg);
		white-space: nowrap;
		width: 100%;
		@include media("screen", "<desktop") {
			@if $theme-sis {
				top: 64%;
			} @else {
				top: 48%;
			}
		}
		@include media("screen", ">=desktop") {
			top: 75%;
		}
	}

	[class*="icon-"] {
		// color: $sidebar-btn-icon_color;
		// transition: $transition-base;
		transform: rotate(90deg);
	}
}

body:not(.document) {
	.sidebar-btn,
	.sidebar-btn-close {
		@include media("screen", ">=desktop") {
			display: none;
		}
	}
}
