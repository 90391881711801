.breadcrumb-wrapper {
	position: sticky;
	top: $header-holder_height_mobile;
	@include media("screen", ">=desktop") {
		top: $header-holder_height - 0.0625rem;
	}
}

[aria-label="breadcrumb"] {
	background-color: $white;
	box-shadow: 0 1rem 1rem -1rem rgba($black, 0.16);
	position: sticky;
	top: $header-holder_height_mobile;
	transition: box-shadow 0.3s ease-in-out;
	z-index: 1010;
	@include media("screen", ">=desktop") {
		top: $header-holder_height - 0.0625rem;
	}

	@at-root body.home & {
		box-shadow: none;
		position: relative;
		top: 0;
		z-index: 10;
	}
}

.breadcrumb {
	align-items: center;

	// [class*="-icon"] {
	&-root-icon {
		@extend .fa-database;
		color: $gray-700;
		font-family: "Font Awesome 5 Pro";
		font-weight: 400;
		font-size: 1rem;
	}
}

.breadcrumb-item {
	@include media("screen", "<phone") {
		font-size: rem-calc(13);
	}

	a {
		display: inline-flex;
		align-items: center;
		color: $gray-700;
		&:hover {
			color: $primary;
		}
	}

	& + .breadcrumb-item {
		@include media("screen", "<phone") {
			padding-left: 0.25rem;
		}
		&:before {
			font-family: "iconfont";
			@include media("screen", "<phone") {
				font-size: rem-calc(11);
				padding-right: 0.25rem;
			}
		}
	}

	&.active {
		a {
			cursor: default;
			color: $primary;
			font-weight: 600;
		}
	}
}
