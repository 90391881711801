@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?4b0iyd') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?4b0iyd') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?4b0iyd##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-academy-alt {
  &:before {
    content: $icon-academy-alt; 
  }
}
.icon-admin {
  &:before {
    content: $icon-admin; 
  }
}
.icon-admin-alt {
  &:before {
    content: $icon-admin-alt; 
  }
}
.icon-ai {
  &:before {
    content: $icon-ai; 
  }
}
.icon-ai-nwb {
  &:before {
    content: $icon-ai-nwb; 
  }
}
.icon-ai-search {
  &:before {
    content: $icon-ai-search; 
  }
}
.icon-ai-search-nwb {
  &:before {
    content: $icon-ai-search-nwb; 
  }
}
.icon-alarm-clock {
  &:before {
    content: $icon-alarm-clock; 
  }
}
.icon-appeal {
  &:before {
    content: $icon-appeal; 
  }
}
.icon-arrow-big-down {
  &:before {
    content: $icon-arrow-big-down; 
  }
}
.icon-arrow-big-left {
  &:before {
    content: $icon-arrow-big-left; 
  }
}
.icon-arrow-big-right {
  &:before {
    content: $icon-arrow-big-right; 
  }
}
.icon-arrow-big-up {
  &:before {
    content: $icon-arrow-big-up; 
  }
}
.icon-arrow-down {
  &:before {
    content: $icon-arrow-down; 
  }
}
.icon-arrow-left {
  &:before {
    content: $icon-arrow-left; 
  }
}
.icon-arrow-right {
  &:before {
    content: $icon-arrow-right; 
  }
}
.icon-arrow-switch {
  &:before {
    content: $icon-arrow-switch; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-at-symbol {
  &:before {
    content: $icon-at-symbol; 
  }
}
.icon-at-symbol-bold {
  &:before {
    content: $icon-at-symbol-bold; 
  }
}
.icon-audio-video {
  &:before {
    content: $icon-audio-video; 
  }
}
.icon-audio-video-alt {
  &:before {
    content: $icon-audio-video-alt; 
  }
}
.icon-backward {
  &:before {
    content: $icon-backward; 
  }
}
.icon-balance-scale {
  &:before {
    content: $icon-balance-scale; 
  }
}
.icon-beaker {
  &:before {
    content: $icon-beaker; 
  }
}
.icon-bell {
  &:before {
    content: $icon-bell; 
  }
}
.icon-bell-slash {
  &:before {
    content: $icon-bell-slash; 
  }
}
.icon-bicycle {
  &:before {
    content: $icon-bicycle; 
  }
}
.icon-book {
  &:before {
    content: $icon-book; 
  }
}
.icon-book-comments {
  &:before {
    content: $icon-book-comments; 
  }
}
.icon-book-cover {
  &:before {
    content: $icon-book-cover; 
  }
}
.icon-book-ivz {
  &:before {
    content: $icon-book-ivz; 
  }
}
.icon-book-k {
  &:before {
    content: $icon-book-k; 
  }
}
.icon-book-opened {
  &:before {
    content: $icon-book-opened; 
  }
}
.icon-book-opened-alt {
  &:before {
    content: $icon-book-opened-alt; 
  }
}
.icon-bookmark {
  &:before {
    content: $icon-bookmark; 
  }
}
.icon-bookmark-add {
  &:before {
    content: $icon-bookmark-add; 
  }
}
.icon-bookmark-alt {
  &:before {
    content: $icon-bookmark-alt; 
  }
}
.icon-briefcase {
  &:before {
    content: $icon-briefcase; 
  }
}
.icon-bus {
  &:before {
    content: $icon-bus; 
  }
}
.icon-calculator {
  &:before {
    content: $icon-calculator; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-calendar-alt {
  &:before {
    content: $icon-calendar-alt; 
  }
}
.icon-calendar-empty {
  &:before {
    content: $icon-calendar-empty; 
  }
}
.icon-card-bp {
  &:before {
    content: $icon-card-bp; 
  }
}
.icon-caret-down {
  &:before {
    content: $icon-caret-down; 
  }
}
.icon-caret-down-o {
  &:before {
    content: $icon-caret-down-o; 
  }
}
.icon-caret-left {
  &:before {
    content: $icon-caret-left; 
  }
}
.icon-caret-left-o {
  &:before {
    content: $icon-caret-left-o; 
  }
}
.icon-caret-right {
  &:before {
    content: $icon-caret-right; 
  }
}
.icon-caret-right-o {
  &:before {
    content: $icon-caret-right-o; 
  }
}
.icon-caret-up {
  &:before {
    content: $icon-caret-up; 
  }
}
.icon-caret-up-o {
  &:before {
    content: $icon-caret-up-o; 
  }
}
.icon-cart {
  &:before {
    content: $icon-cart; 
  }
}
.icon-chart {
  &:before {
    content: $icon-chart; 
  }
}
.icon-chat {
  &:before {
    content: $icon-chat; 
  }
}
.icon-chat-add {
  &:before {
    content: $icon-chat-add; 
  }
}
.icon-check {
  &:before {
    content: $icon-check; 
  }
}
.icon-check-o {
  &:before {
    content: $icon-check-o; 
  }
}
.icon-check-s {
  &:before {
    content: $icon-check-s; 
  }
}
.icon-check-solid-o {
  &:before {
    content: $icon-check-solid-o; 
  }
}
.icon-checklist {
  &:before {
    content: $icon-checklist; 
  }
}
.icon-checklist-conventional {
  &:before {
    content: $icon-checklist-conventional; 
  }
}
.icon-checklist-server {
  &:before {
    content: $icon-checklist-server; 
  }
}
.icon-chevron-down {
  &:before {
    content: $icon-chevron-down; 
  }
}
.icon-chevron-left {
  &:before {
    content: $icon-chevron-left; 
  }
}
.icon-chevron-right {
  &:before {
    content: $icon-chevron-right; 
  }
}
.icon-chevron-up {
  &:before {
    content: $icon-chevron-up; 
  }
}
.icon-climate-neutral {
  &:before {
    content: $icon-climate-neutral; 
  }
}
.icon-clip {
  &:before {
    content: $icon-clip; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-comment {
  &:before {
    content: $icon-comment; 
  }
}
.icon-comment-alt {
  &:before {
    content: $icon-comment-alt; 
  }
}
.icon-comments {
  &:before {
    content: $icon-comments; 
  }
}
.icon-comments-m {
  &:before {
    content: $icon-comments-m; 
  }
}
.icon-comments-o {
  &:before {
    content: $icon-comments-o; 
  }
}
.icon-community {
  &:before {
    content: $icon-community; 
  }
}
.icon-compare-vz {
  &:before {
    content: $icon-compare-vz; 
  }
}
.icon-compass {
  &:before {
    content: $icon-compass; 
  }
}
.icon-contract {
  &:before {
    content: $icon-contract; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-database {
  &:before {
    content: $icon-database; 
  }
}
.icon-database-alt {
  &:before {
    content: $icon-database-alt; 
  }
}
.icon-database-help {
  &:before {
    content: $icon-database-help; 
  }
}
.icon-desktop {
  &:before {
    content: $icon-desktop; 
  }
}
.icon-diamond {
  &:before {
    content: $icon-diamond; 
  }
}
.icon-diary {
  &:before {
    content: $icon-diary; 
  }
}
.icon-doc-empty {
  &:before {
    content: $icon-doc-empty; 
  }
}
.icon-doc-management {
  &:before {
    content: $icon-doc-management; 
  }
}
.icon-doc-move-alt {
  &:before {
    content: $icon-doc-move-alt; 
  }
}
.icon-doc-search {
  &:before {
    content: $icon-doc-search; 
  }
}
.icon-doc-search-alt {
  &:before {
    content: $icon-doc-search-alt; 
  }
}
.icon-doc-types {
  &:before {
    content: $icon-doc-types; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-ebicycle {
  &:before {
    content: $icon-ebicycle; 
  }
}
.icon-ebicycle-alt {
  &:before {
    content: $icon-ebicycle-alt; 
  }
}
.icon-ebicycle-o {
  &:before {
    content: $icon-ebicycle-o; 
  }
}
.icon-ecar {
  &:before {
    content: $icon-ecar; 
  }
}
.icon-ecar-alt {
  &:before {
    content: $icon-ecar-alt; 
  }
}
.icon-ecar-o {
  &:before {
    content: $icon-ecar-o; 
  }
}
.icon-editions-distribution {
  &:before {
    content: $icon-editions-distribution; 
  }
}
.icon-education {
  &:before {
    content: $icon-education; 
  }
}
.icon-ellipsis-h {
  &:before {
    content: $icon-ellipsis-h; 
  }
}
.icon-ellipsis-v {
  &:before {
    content: $icon-ellipsis-v; 
  }
}
.icon-excel {
  &:before {
    content: $icon-excel; 
  }
}
.icon-exe {
  &:before {
    content: $icon-exe; 
  }
}
.icon-eye {
  &:before {
    content: $icon-eye; 
  }
}
.icon-face-id {
  &:before {
    content: $icon-face-id; 
  }
}
.icon-facebook {
  &:before {
    content: $icon-facebook; 
  }
}
.icon-facebook-o {
  &:before {
    content: $icon-facebook-o; 
  }
}
.icon-family {
  &:before {
    content: $icon-family; 
  }
}
.icon-family-alt {
  &:before {
    content: $icon-family-alt; 
  }
}
.icon-favs {
  &:before {
    content: $icon-favs; 
  }
}
.icon-feather {
  &:before {
    content: $icon-feather; 
  }
}
.icon-file-excel {
  &:before {
    content: $icon-file-excel; 
  }
}
.icon-file-exe-o {
  &:before {
    content: $icon-file-exe-o; 
  }
}
.icon-file-html {
  &:before {
    content: $icon-file-html; 
  }
}
.icon-file-pdf {
  &:before {
    content: $icon-file-pdf; 
  }
}
.icon-file-powerpoint {
  &:before {
    content: $icon-file-powerpoint; 
  }
}
.icon-file-rss {
  &:before {
    content: $icon-file-rss; 
  }
}
.icon-file-word {
  &:before {
    content: $icon-file-word; 
  }
}
.icon-file-zip-o {
  &:before {
    content: $icon-file-zip-o; 
  }
}
.icon-fill {
  &:before {
    content: $icon-fill; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-folder {
  &:before {
    content: $icon-folder; 
  }
}
.icon-folder-add {
  &:before {
    content: $icon-folder-add; 
  }
}
.icon-folder-open {
  &:before {
    content: $icon-folder-open; 
  }
}
.icon-folder-user {
  &:before {
    content: $icon-folder-user; 
  }
}
.icon-folder-user-o {
  &:before {
    content: $icon-folder-user-o; 
  }
}
.icon-form {
  &:before {
    content: $icon-form; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-gavel {
  &:before {
    content: $icon-gavel; 
  }
}
.icon-grid-lg-o {
  &:before {
    content: $icon-grid-lg-o; 
  }
}
.icon-grid-o {
  &:before {
    content: $icon-grid-o; 
  }
}
.icon-guide {
  &:before {
    content: $icon-guide; 
  }
}
.icon-hand {
  &:before {
    content: $icon-hand; 
  }
}
.icon-headset {
  &:before {
    content: $icon-headset; 
  }
}
.icon-health {
  &:before {
    content: $icon-health; 
  }
}
.icon-heart {
  &:before {
    content: $icon-heart; 
  }
}
.icon-home {
  &:before {
    content: $icon-home; 
  }
}
.icon-home-alt {
  &:before {
    content: $icon-home-alt; 
  }
}
.icon-image {
  &:before {
    content: $icon-image; 
  }
}
.icon-images {
  &:before {
    content: $icon-images; 
  }
}
.icon-index {
  &:before {
    content: $icon-index; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-info-alt {
  &:before {
    content: $icon-info-alt; 
  }
}
.icon-info-alt-solid {
  &:before {
    content: $icon-info-alt-solid; 
  }
}
.icon-info-o {
  &:before {
    content: $icon-info-o; 
  }
}
.icon-info-solid {
  &:before {
    content: $icon-info-solid; 
  }
}
.icon-info-solid-o {
  &:before {
    content: $icon-info-solid-o; 
  }
}
.icon-infocenter {
  &:before {
    content: $icon-infocenter; 
  }
}
.icon-infocenter-o {
  &:before {
    content: $icon-infocenter-o; 
  }
}
.icon-instagram {
  &:before {
    content: $icon-instagram; 
  }
}
.icon-invation {
  &:before {
    content: $icon-invation; 
  }
}
.icon-invoice {
  &:before {
    content: $icon-invoice; 
  }
}
.icon-job {
  &:before {
    content: $icon-job; 
  }
}
.icon-jobs {
  &:before {
    content: $icon-jobs; 
  }
}
.icon-landmark {
  &:before {
    content: $icon-landmark; 
  }
}
.icon-laptop {
  &:before {
    content: $icon-laptop; 
  }
}
.icon-laufzettel {
  &:before {
    content: $icon-laufzettel; 
  }
}
.icon-laws {
  &:before {
    content: $icon-laws; 
  }
}
.icon-leasing-laptop {
  &:before {
    content: $icon-leasing-laptop; 
  }
}
.icon-leasing-smartphone {
  &:before {
    content: $icon-leasing-smartphone; 
  }
}
.icon-license {
  &:before {
    content: $icon-license; 
  }
}
.icon-lightbulb {
  &:before {
    content: $icon-lightbulb; 
  }
}
.icon-lightbulb-glow {
  &:before {
    content: $icon-lightbulb-glow; 
  }
}
.icon-lightning {
  &:before {
    content: $icon-lightning; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-link-add {
  &:before {
    content: $icon-link-add; 
  }
}
.icon-linkedin {
  &:before {
    content: $icon-linkedin; 
  }
}
.icon-linkedin-o {
  &:before {
    content: $icon-linkedin-o; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-list-o {
  &:before {
    content: $icon-list-o; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-locked {
  &:before {
    content: $icon-locked; 
  }
}
.icon-login {
  &:before {
    content: $icon-login; 
  }
}
.icon-logout {
  &:before {
    content: $icon-logout; 
  }
}
.icon-magazine {
  &:before {
    content: $icon-magazine; 
  }
}
.icon-magazine-alt {
  &:before {
    content: $icon-magazine-alt; 
  }
}
.icon-magazine-k {
  &:before {
    content: $icon-magazine-k; 
  }
}
.icon-magazine-opened {
  &:before {
    content: $icon-magazine-opened; 
  }
}
.icon-mail {
  &:before {
    content: $icon-mail; 
  }
}
.icon-mail-open {
  &:before {
    content: $icon-mail-open; 
  }
}
.icon-map-pin {
  &:before {
    content: $icon-map-pin; 
  }
}
.icon-marked {
  &:before {
    content: $icon-marked; 
  }
}
.icon-marker {
  &:before {
    content: $icon-marker; 
  }
}
.icon-medal {
  &:before {
    content: $icon-medal; 
  }
}
.icon-medal-o {
  &:before {
    content: $icon-medal-o; 
  }
}
.icon-megaphone {
  &:before {
    content: $icon-megaphone; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-message {
  &:before {
    content: $icon-message; 
  }
}
.icon-microphone {
  &:before {
    content: $icon-microphone; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-minus-o {
  &:before {
    content: $icon-minus-o; 
  }
}
.icon-module-focus {
  &:before {
    content: $icon-module-focus; 
  }
}
.icon-money {
  &:before {
    content: $icon-money; 
  }
}
.icon-moneybox {
  &:before {
    content: $icon-moneybox; 
  }
}
.icon-multimedia {
  &:before {
    content: $icon-multimedia; 
  }
}
.icon-multimedia-o {
  &:before {
    content: $icon-multimedia-o; 
  }
}
.icon-multimedia-solid {
  &:before {
    content: $icon-multimedia-solid; 
  }
}
.icon-multiple-filter {
  &:before {
    content: $icon-multiple-filter; 
  }
}
.icon-muscle {
  &:before {
    content: $icon-muscle; 
  }
}
.icon-muscle-alt {
  &:before {
    content: $icon-muscle-alt; 
  }
}
.icon-news {
  &:before {
    content: $icon-news; 
  }
}
.icon-online-learning {
  &:before {
    content: $icon-online-learning; 
  }
}
.icon-online-shop {
  &:before {
    content: $icon-online-shop; 
  }
}
.icon-online-training {
  &:before {
    content: $icon-online-training; 
  }
}
.icon-order-alphabetical {
  &:before {
    content: $icon-order-alphabetical; 
  }
}
.icon-order-numeric {
  &:before {
    content: $icon-order-numeric; 
  }
}
.icon-order-thematic {
  &:before {
    content: $icon-order-thematic; 
  }
}
.icon-page-break {
  &:before {
    content: $icon-page-break; 
  }
}
.icon-paper-plane {
  &:before {
    content: $icon-paper-plane; 
  }
}
.icon-paragraph {
  &:before {
    content: $icon-paragraph; 
  }
}
.icon-paragraph-normal {
  &:before {
    content: $icon-paragraph-normal; 
  }
}
.icon-paragraph-solid {
  &:before {
    content: $icon-paragraph-solid; 
  }
}
.icon-party {
  &:before {
    content: $icon-party; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-pdf {
  &:before {
    content: $icon-pdf; 
  }
}
.icon-pencil {
  &:before {
    content: $icon-pencil; 
  }
}
.icon-people {
  &:before {
    content: $icon-people; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-pin {
  &:before {
    content: $icon-pin; 
  }
}
.icon-pin-solid {
  &:before {
    content: $icon-pin-solid; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-plus-o {
  &:before {
    content: $icon-plus-o; 
  }
}
.icon-pointer {
  &:before {
    content: $icon-pointer; 
  }
}
.icon-powerpoint {
  &:before {
    content: $icon-powerpoint; 
  }
}
.icon-presentation {
  &:before {
    content: $icon-presentation; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-print-add {
  &:before {
    content: $icon-print-add; 
  }
}
.icon-print-list {
  &:before {
    content: $icon-print-list; 
  }
}
.icon-print-remove {
  &:before {
    content: $icon-print-remove; 
  }
}
.icon-print-solid {
  &:before {
    content: $icon-print-solid; 
  }
}
.icon-puzzle {
  &:before {
    content: $icon-puzzle; 
  }
}
.icon-puzzles {
  &:before {
    content: $icon-puzzles; 
  }
}
.icon-quick-start {
  &:before {
    content: $icon-quick-start; 
  }
}
.icon-quick-start-alt {
  &:before {
    content: $icon-quick-start-alt; 
  }
}
.icon-quote-alt {
  &:before {
    content: $icon-quote-alt; 
  }
}
.icon-quote-o {
  &:before {
    content: $icon-quote-o; 
  }
}
.icon-radar {
  &:before {
    content: $icon-radar; 
  }
}
.icon-read-mode {
  &:before {
    content: $icon-read-mode; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-reg {
  &:before {
    content: $icon-reg; 
  }
}
.icon-regulations {
  &:before {
    content: $icon-regulations; 
  }
}
.icon-regulations-alt {
  &:before {
    content: $icon-regulations-alt; 
  }
}
.icon-remove {
  &:before {
    content: $icon-remove; 
  }
}
.icon-repley {
  &:before {
    content: $icon-repley; 
  }
}
.icon-rocket {
  &:before {
    content: $icon-rocket; 
  }
}
.icon-rss-o {
  &:before {
    content: $icon-rss-o; 
  }
}
.icon-save-o {
  &:before {
    content: $icon-save-o; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-seminar {
  &:before {
    content: $icon-seminar; 
  }
}
.icon-seminar-alt {
  &:before {
    content: $icon-seminar-alt; 
  }
}
.icon-send {
  &:before {
    content: $icon-send; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-share-alt {
  &:before {
    content: $icon-share-alt; 
  }
}
.icon-share-o {
  &:before {
    content: $icon-share-o; 
  }
}
.icon-shopping-cart {
  &:before {
    content: $icon-shopping-cart; 
  }
}
.icon-smartphone {
  &:before {
    content: $icon-smartphone; 
  }
}
.icon-sponsorship {
  &:before {
    content: $icon-sponsorship; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-star-o {
  &:before {
    content: $icon-star-o; 
  }
}
.icon-star-search {
  &:before {
    content: $icon-star-search; 
  }
}
.icon-star-search-o {
  &:before {
    content: $icon-star-search-o; 
  }
}
.icon-stb-scout {
  &:before {
    content: $icon-stb-scout; 
  }
}
.icon-sustainability {
  &:before {
    content: $icon-sustainability; 
  }
}
.icon-table {
  &:before {
    content: $icon-table; 
  }
}
.icon-table-alt {
  &:before {
    content: $icon-table-alt; 
  }
}
.icon-tax-policies {
  &:before {
    content: $icon-tax-policies; 
  }
}
.icon-theme {
  &:before {
    content: $icon-theme; 
  }
}
.icon-themes {
  &:before {
    content: $icon-themes; 
  }
}
.icon-themes-alt {
  &:before {
    content: $icon-themes-alt; 
  }
}
.icon-thumb {
  &:before {
    content: $icon-thumb; 
  }
}
.icon-times-o {
  &:before {
    content: $icon-times-o; 
  }
}
.icon-times-solid-o {
  &:before {
    content: $icon-times-solid-o; 
  }
}
.icon-tools {
  &:before {
    content: $icon-tools; 
  }
}
.icon-topics {
  &:before {
    content: $icon-topics; 
  }
}
.icon-touch-id {
  &:before {
    content: $icon-touch-id; 
  }
}
.icon-touch-id-o {
  &:before {
    content: $icon-touch-id-o; 
  }
}
.icon-train {
  &:before {
    content: $icon-train; 
  }
}
.icon-trash {
  &:before {
    content: $icon-trash; 
  }
}
.icon-trash-full {
  &:before {
    content: $icon-trash-full; 
  }
}
.icon-twitter {
  &:before {
    content: $icon-twitter; 
  }
}
.icon-twitter-o {
  &:before {
    content: $icon-twitter-o; 
  }
}
.icon-twitter-x {
  &:before {
    content: $icon-twitter-x; 
  }
}
.icon-type-rss {
  &:before {
    content: $icon-type-rss; 
  }
}
.icon-unlink {
  &:before {
    content: $icon-unlink; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-user-add {
  &:before {
    content: $icon-user-add; 
  }
}
.icon-user-alt {
  &:before {
    content: $icon-user-alt; 
  }
}
.icon-user-headset {
  &:before {
    content: $icon-user-headset; 
  }
}
.icon-user-m {
  &:before {
    content: $icon-user-m; 
  }
}
.icon-user-remove {
  &:before {
    content: $icon-user-remove; 
  }
}
.icon-user-w {
  &:before {
    content: $icon-user-w; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-vimeo {
  &:before {
    content: $icon-vimeo; 
  }
}
.icon-volume {
  &:before {
    content: $icon-volume; 
  }
}
.icon-volume-down {
  &:before {
    content: $icon-volume-down; 
  }
}
.icon-volume-mute {
  &:before {
    content: $icon-volume-mute; 
  }
}
.icon-volume-up {
  &:before {
    content: $icon-volume-up; 
  }
}
.icon-warning-o {
  &:before {
    content: $icon-warning-o; 
  }
}
.icon-warning-outline {
  &:before {
    content: $icon-warning-outline; 
  }
}
.icon-warning-solid {
  &:before {
    content: $icon-warning-solid; 
  }
}
.icon-warning-solid-alt {
  &:before {
    content: $icon-warning-solid-alt; 
  }
}
.icon-warning-solid-o {
  &:before {
    content: $icon-warning-solid-o; 
  }
}
.icon-webcam {
  &:before {
    content: $icon-webcam; 
  }
}
.icon-whatsapp {
  &:before {
    content: $icon-whatsapp; 
  }
}
.icon-word {
  &:before {
    content: $icon-word; 
  }
}
.icon-work-helpers {
  &:before {
    content: $icon-work-helpers; 
  }
}
.icon-xing {
  &:before {
    content: $icon-xing; 
  }
}
.icon-youtube {
  &:before {
    content: $icon-youtube; 
  }
}
.icon-zip {
  &:before {
    content: $icon-zip; 
  }
}

