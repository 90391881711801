.table {
	thead {
		th {
			font-size: rem-calc(12);
			text-transform: uppercase;
		}
	}
}

// Tempolösung ?
body:not(.document) .table {
	margin-bottom: 1rem;

	th,
	td {
		padding: 0.75rem;
		vertical-align: top;
	}
}

.table-striped {
	tbody {
		tr {
			&:nth-of-type(2n) {
				background-color: $table-accent-bg; //#f8f9fa;
			}
		}
	}
}

.thead-light {
	th {
		background-color: $table-head-bg; //$gray-200; //#eee;
		border-color: $gray-300; //#dcdcdc;
		border-bottom: 2px solid $gray-300; //#dcdcdc;
		// padding: 0.75rem 0.5rem;
		vertical-align: bottom;
	}
}

.table-hover {
	tbody tr {
		transition: $transition-base;
		&:hover {
			background-color: $table-hover-bg;
		}
	}

	td {
		a {
			color: $table-hover_link-color;
			&:hover,
			&:focus {
				color: $table-hover_link-color-hover;
			}
		}
	}
}
// Tempolösung ? : END

.schnelleantwort-detail-open {
	overflow-x: auto;
	@include media("screen", "<phone") {
		margin-left: rem-calc(-40);
	}
}

.schnelleAntwortDetailTable {
	th {
		font-size: rem-calc(13);
	}
}
