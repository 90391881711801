.btn-ui-group {
	position: fixed;
	bottom: 1rem;
	left: auto;
	right: auto;
	width: calc(100% - 1.875rem);
	z-index: 1011;
	@include media("screen", ">=desktop") {
		// top: 8.5rem;
		// bottom: calc(100% - 8.5rem);
		width: 930px;
		@if $theme-linde {
			top: 3.75rem;
			bottom: calc(100% - 3.75rem);
		} @else {
			top: 8.5rem;
			bottom: calc(100% - 8.5rem);
		}
	}
	@include media("screen", ">=widescreen") {
		width: 1110px;
	}
	@include media("screen", ">=1330px") {
		width: 1198px;
	}

	&.is-top {
		@include media("screen", "<desktop") {
			.btn-mag,
			.btn-feedback {
				margin-right: -0.5rem;
			}
		}
		@include media("screen", ">=desktop") {
			@if $theme-linde {
				top: 9.25rem;
				bottom: calc(100% - 9.25rem);
			} @else {
				top: 14rem;
				bottom: calc(100% - 14rem);
			}
		}
	}

	.btn-ui {
		box-shadow: $box-shadow;
		bottom: 0;
		position: absolute;
		right: 0;
	}

	.btn-mag {
		@include media("screen", "<desktop") {
			margin-right: 3.25rem;
		}
		@include media("screen", ">=desktop") {
			margin-right: -2rem;
		}
		@include media("screen", ">=1366px") {
			margin-right: -4.5rem;
		}
		@include media("screen", ">=1440px") {
			margin-right: -6rem;
		}
	}

	.btn-feedback {
		@include media("screen", "<desktop") {
			margin-right: 3.25rem;
		}
		@include media("screen", ">=desktop") {
			margin-right: -2rem;
		}
		@include media("screen", ">=1366px") {
			margin-right: -4.5rem;
		}
		@include media("screen", ">=1440px") {
			margin-right: -6rem;
		}
	}
}
