.list-styled {
    padding: rem-calc(15) 0;

    li {
        display: block;
        margin-bottom: rem-calc(15);
        padding-left: rem-calc(20);
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 10px;
            left: 0;
            width: 5px;
            height: 5px;
            background-color: $list-styled_bg-color;
        }
    }
}
