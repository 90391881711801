$btn-ui_bg: $brand-color !default;
$btn-ui_bg-hover: $brand-color !default;
$btn-ui_border-radius: null !default;
$btn-ui_height: 2.625rem !default; // 42px
$btn-ui_width: 2.625rem !default; // 42px
// $btn-ui_width-hover: 10rem !default; // 160px
$btn-ui_width-hover: 9rem !default;

$btn-ui_dialog_color: $primary !default;
$btn-ui_dialog_color-hover: $gray-900 !default;
$btn-ui_dialog_text-transform: uppercase !default;

$btn-ui_icon_color: $primary !default;
$btn-ui_icon_color-hover: $brand-color !default;
$btn-ui_icon_bg-hover: $primary !default;

// Button to top
$btn-toTop_bg: $blue-middle !default;
$btn-toTop_color: $white !default;
$btn-toTop_border-color: $blue-middle !default;
